/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from "../js/helper/util";

export default {
    BASE_URL: isDevelop() ? "http://localhost:3000/api/" : "/api/",
    BASE_IMAGE_URL: isDevelop() ? "http://localhost:3000/images/" : "/images/",
    BASE_FILE_URL: isDevelop() ? "http://localhost:3000/" : "/",
    STORAGE_KEY: "4WfG5jv3",
}