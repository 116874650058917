import React from "react";
import {Button, Dropdown, Form, Grid, Message, Modal, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";


import DatePicker from "react-datepicker/es";
import {checkEdit, checkRole} from "../helper/rolehelper";
import userStore from "../stores/userStore";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import LiquidGasRow from "../components/LiquidGasRow";
import NumberFormat from "react-number-format";


const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const TYPE_ERROR = "Falsche Angabe : Art der Tanklieferung";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";


@observer
export default class LiquidGasPage extends React.Component {

    @observable
    liquidgases = [];
    @observable
    editLiquidgases = {
        liquidgasType: 1,
        date: new Date(),
        amountliter: "",
        calorificvalue: "",
        amountkWh: "",
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addliquidgasModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;

    @observable edit = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),
            currentSelectedValMonth: new Date().getMonth().toString(),

            open: false,
            deleteopen: false,
            delete: true,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/liquidgas")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/liquidgas");
        console.log(user)
    }

    componentDidMount() {
        this.editLiquidgases.liquidgasType = 1;
        this.fetchLiquidGases();
        this.getLocations();
    }

    fetchLiquidGases() {
        fetch(config.BASE_URL + "liquidgases", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.liquidgases = json
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    addLiquidGas(editLiquidgas, type) {

        fetch(config.BASE_URL + "liquidgases", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                liquidgasType: type,
                date: editLiquidgas.date,
                amountliter: editLiquidgas.amountliter,
                calorificvalue: editLiquidgas.calorificvalue,
                amountkWh: editLiquidgas.amountkWh,
                costs: editLiquidgas.costs,
                proof: editLiquidgas.proof,
                comment: editLiquidgas.comment,
                locationId: editLiquidgas.locationId
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchLiquidGases();
            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );

    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingLiquidGases = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status);
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }


    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;

            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth
        }, () => {
        });

    }

    getAllFromLiquidGasLocation(location, Liquidgases) {
        let LiquidgasLocal = [];
        for (let j = 0; j < this.liquidgases.length; j++) {
            if (this.liquidgases[j].locationId === location) {
                LiquidgasLocal.push(Liquidgases[j]);
            }
        }
        return LiquidgasLocal;

    }

    ////add - edit
    checkInputValues(locationId) {
        //Check if vehicle has Liquidgas and check if amounts are right
        if (this.editLiquidgases.length !== 0) {

            //Check if editliquidgas has a valid type (1, 2 or 3)
            let type = (this.editLiquidgases.liquidgasType);
            if (type !== 1 ) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amountliter = this.editLiquidgases.amountliter;
            let costs = this.editLiquidgases.costs;
            let proof = this.editLiquidgases.proof;
            if (amountliter === "" || costs === "" || amountliter === 0 || costs === "" || costs === 0 || proof === "") {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }

            this.initiateAddLiquidGas(locationId);
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddLiquidGas(locationId) {
        this.editLiquidgases.locationId = locationId;
        this.addLiquidGas(this.editLiquidgases, (this.editLiquidgases.liquidgasType));

        this.closeInput()
    }


    handleChange(date) {
        if (date === null) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.editLiquidgases.date = date;
        }
    }

    closeInput() {
        this.addliquidgasModalOpen = false;
        this.isEditModal = false;
        this.state.open = false;
        this.deleteModalOpen = false;
        this.editLiquidgases = {
            liquidgasType: 1,
            date: new Date(),
            amountliter: "",
            calorificvalue: "",
            amountkWh: "",
            costs: "",
            proof: "",
            comment: ""
        };

        this.error = false;
    }

    deleteLiquidGas(LiquidgasesId) {
        fetch(config.BASE_URL + "liquidgases/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: LiquidgasesId})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.loading = false;


                this.fetchLiquidGases();
            } else {
                console.log(response.status);
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    editLiquidGas(editLiquidGas, type) {
        console.log(editLiquidGas);
        console.log(type);
        fetch(config.BASE_URL + "liquidgases/", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                liquidgasType: type,
                date: editLiquidGas.date,
                amountliter: editLiquidGas.amountliter,
                calorificvalue: editLiquidGas.calorificvalue,
                amountkWh: editLiquidGas.amountkWh,
                costs: editLiquidGas.costs,
                locationId: editLiquidGas.locationId,
                proof: editLiquidGas.proof,
                comment: editLiquidGas.comment,
                id: editLiquidGas.id
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchLiquidGases();
            } else {
                console.log(response.status)
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );

    }

    handleInputChange(type, event, data) {
        this.editLiquidgases.calorificvalue = 6.57;

        this.editLiquidgases.amountkWh = this.editLiquidgases.amountliter * this.editLiquidgases.calorificvalue;
        if (type === 'liquidgasType') {
            this.editLiquidgases.liquidgasType = data.value;
        } else if (type === 'date') {
            this.editLiquidgases.date = data.value;
        } else if (type === 'costs') {
            if (data.value === "") {
                this.editLiquidgases.costs = 0;
            } else
                this.editLiquidgases.costs = data.value;
        } else if (type === 'amountliter') {
            if (data.value === "") {
                this.editLiquidgases.amountliter = 0;
            } else {
                this.editLiquidgases.amountliter = data.value;
            }
        }  else if (type === 'proof') {
            this.editLiquidgases.proof = data.value;
        } else if (type === 'comment') {
            this.editLiquidgases.comment = data.value;
        }
    }

    ////add edit

    getLiquidGasName(type) {
        if (type === 1) {
            return "Tanklieferung";
        } else if (type === 2) {
            return "Tankkartenabrechnungen";
        } else {
            return "FremdTankungen";
        }
    }

    downloadExcelTable() {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        fetch(config.BASE_URL + "liquidgases/excel/" + currentSelectedValMonth + "/" + currentSelectedValYear, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty('link')) {
                        window.open(config.BASE_FILE_URL + json.link);
                    }
                });
            } else {
                console.log("error");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    render() {
        const yearOptions = [];
        let yearArray = [];
        for (let i = 0; i < this.liquidgases.length; i++) {
            let deliveryDate = new Date(this.liquidgases[i].date * 1000);
            let deliveryYear = deliveryDate.getFullYear();
            if (!yearArray.includes(deliveryYear))
                yearArray.push(deliveryYear);
        }

        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }
        const IdOptions = [
            {key: 4, text: 'Tanklieferung', value: 1},
            {key: 5, text: 'Tankkarte', value: 2},
            {key: 6, text: 'Fremdtankung', value: 3}
        ];
        const calorificvalue = 6.57;

        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let tableElements = [];
        for (let i = 0; i < locations.length; i++) {

            let kwhSum = 0;
            let amountliterSum = 0;
            let costSum = 0;

            let locationLiquidGases = this.getAllFromLiquidGasLocation(locations[i].id, this.liquidgases)
            let showingLocationLiquidGases = [];
            for (let j = 0; j < locationLiquidGases.length; j++) {

                let liquidgasesDate = locationLiquidGases[j].date;
                let liquidgasesDateYear = new Date(liquidgasesDate * 1000).getFullYear();
                let liquidgasesDateMonth = (new Date(liquidgasesDate * 1000).getMonth());

                if ((this.state.currentSelectedValYear === liquidgasesDateYear
                    && liquidgasesDateMonth.toString() === this.state.currentSelectedValMonth)
                    || this.state.currentSelectedValYear === liquidgasesDateYear && this.state.currentSelectedValMonth === "12") {

                    showingLocationLiquidGases.push(<LiquidGasRow key={i + "_" + j}
                                                                  liquidgas={locationLiquidGases[j]}
                                                                  locationId={locations[i].id}
                                                                  deleteLiquidGas={this.deleteLiquidGas.bind(this)}
                                                                  editLiquidGas={this.editLiquidGas.bind(this)}
                    />);

                    kwhSum += locationLiquidGases[j].amountkWh;
                    if (locationLiquidGases[j].liquidgasType === 1) {
                        costSum += locationLiquidGases[j].costs;
                    } else if (locationLiquidGases[j].liquidgasType === 0) {
                        console.log("costs = 0");
                    }
                    amountliterSum += locationLiquidGases[j].amountliter;
                }
            }

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingLiquidGases ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingLiquidGases = !this.locations[i].showingLiquidGases}/>
                    </Table.Cell>
                    {this.edit ?
                        <Table.Cell>
                            <Icon name='plus' color="orange"
                                  onClick={() => this.setState({open: true, editLocationId: locations[i].id})}/>
                        </Table.Cell>
                        :
                        null
                    }
                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(amountliterSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(kwhSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                </Table.Row>
            );

            if (locations[i].showingLiquidGases) {
                tableElements.push(showingLocationLiquidGases);
            }
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Flüssig-Gas</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>

                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={11}>
                                        <Button value="0"
                                                className={this.state.currentSelectedValMonth === "0" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jan</Button>
                                        <Button value="1"
                                                className={this.state.currentSelectedValMonth === "1" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Feb</Button>
                                        <Button value="2"
                                                className={this.state.currentSelectedValMonth === "2" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mär</Button>
                                        <Button value="3"
                                                className={this.state.currentSelectedValMonth === "3" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Apr</Button>
                                        <Button value="4"
                                                className={this.state.currentSelectedValMonth === "4" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mai</Button>
                                        <Button value="5"
                                                className={this.state.currentSelectedValMonth === "5" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jun</Button>
                                        <Button value="6"
                                                className={this.state.currentSelectedValMonth === "6" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jul</Button>
                                        <Button value="7"
                                                className={this.state.currentSelectedValMonth === "7" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Aug</Button>
                                        <Button value="8"
                                                className={this.state.currentSelectedValMonth === "8" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Sep</Button>
                                        <Button value="9"
                                                className={this.state.currentSelectedValMonth === "9" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Okt</Button>
                                        <Button value="10"
                                                className={this.state.currentSelectedValMonth === "10" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Nov</Button>
                                        <Button value="11"
                                                className={this.state.currentSelectedValMonth === "11" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Dez</Button>
                                        <Button value="12"
                                                className={this.state.currentSelectedValMonth === "12" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Gesamt</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            icon
                            labelPosition='right'
                            positive
                            onClick={() => this.downloadExcelTable()}>
                            Exportieren
                            <Icon name={"external alternate"}/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    {this.edit ? <Table.HeaderCell>Hinzufügen</Table.HeaderCell> : null}
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>Datum</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Menge [l]</Table.HeaderCell>
                                    <Table.HeaderCell>Heizwert</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Menge [kWh]</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Kosten [netto]</Table.HeaderCell>
                                    <Table.HeaderCell>Bemerkung</Table.HeaderCell>
                                    <Table.HeaderCell>Nachweis</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Modal centered={false} className={"modal-top"} open={this.state.open} size="small">
                        <Modal.Header>{this.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                        <Modal.Content>
                            <Form error={this.error}>
                                <Form.Group widths='equal'>


                                    <Form.Field>
                                        <label>Datum</label>

                                        <DatePicker
                                            selected={this.editLiquidgases.date}
                                            onChange={this.handleChange.bind(this)}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Menge [l]</label>
                                        <NumberFormat value={parseFloat(this.editLiquidgases.amountliter)}
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                      decimalScale={2}
                                                      onValueChange={(values, e) => {
                                                          this.handleInputChange('amountliter', null, {value: values.floatValue})
                                                      }}
                                                      allowNegative={false}
                                                      suffix=" l"/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Heizwert</label>
                                        {calorificvalue}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>kWh</label>
                                        {Math.round(this.editLiquidgases.amountliter * calorificvalue * 100) / 100}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Kosten [€]</label>
                                        <NumberFormat value={parseFloat(this.editLiquidgases.costs)}
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                      decimalScale={2}
                                                      onValueChange={(values, e) => {
                                                          this.handleInputChange('costs', null,{value:values.floatValue})
                                                      }}
                                                      allowNegative={false}
                                                      suffix=" €" />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nachweis</label>
                                        <Form.Input value={this.editLiquidgases.proof}
                                                    type='proof'
                                                    onChange={this.handleInputChange.bind(this, 'proof')}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bemerkung</label>
                                        <Form.Input value={this.editLiquidgases.comment}
                                                    type='comment'
                                                    onChange={this.handleInputChange.bind(this, 'comment')}/>
                                    </Form.Field>

                                </Form.Group>
                                <Message
                                    error
                                    header='Fehler'
                                    content={this.errorMessage}
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                            <Button
                                positive
                                icon='checkmark'
                                onClick={() => this.checkInputValues(this.state.editLocationId)}
                            />
                        </Modal.Actions>
                    </Modal>
                </Grid.Row>

            </Grid>
        );
    }
}