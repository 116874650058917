import React from "react";
import {Icon, Table} from "semantic-ui-react";
import DieselFuellingRow from "./DieselFuellingRow";
import AddEditDieselFuellingModal from "./AddEditDieselFuellingModal";
import userStore from "../stores/userStore";
import ChangeReferenceStandModal from "./ChangeReferenceStandModal";
import DeleteFuellingModal from "./DeleteFuellingModal";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {checkEdit, checkRole} from "../helper/rolehelper";

@observer
export default class DieselSumRow extends React.Component {

    @observable edit = false;

    constructor(props) {
        super(props);
        this.state = {
            openFuellingsstate: false
        };

        let user = userStore.userRolesFromServer;
        this.edit = checkEdit(user, "/diesel");
    }

    openEdit() {
        this.setState({
            ...this.state,
            addModalOpen: true
        });
    }


    openDelete() {
        this.setState({
            ...this.state,
            deleteModalOpen: true
        });
    }


    toggleFuellings() {
        let {openFuellingsstate} = this.state;
        openFuellingsstate = !openFuellingsstate;

        this.setState({
            ...this.state,
            openFuellingsstate: openFuellingsstate
        });
    }


    getClassNameForAVG(avgConsumption) {
        if (avgConsumption >= this.props.vehicle.expect_consumption * 1.5) {
            return "high-average"
        } else if (avgConsumption >= this.props.vehicle.expect_consumption * 1.3) {
            return "mid-average"
        }
        return "";
    }

    render() {

        let rows = [];
        let fuellingRows = [];
        let kwhSum = 0;
        let avgConsumption = 0;
        let literSum = 0;
        let referenceStand = 0;

        for (let i = 0; i < this.props.vehicle.fuellings.length; i++) {
            let date = new Date(this.props.vehicle.fuellings[i].date * 1000);

            let referenceDiff = 0;
            if (i === this.props.vehicle.fuellings.length - 1) {
                referenceDiff = this.props.vehicle.fuellings[i].amountreference - this.props.vehicle.reference_stand;
            } else {
                referenceDiff = this.props.vehicle.fuellings[i].amountreference - this.props.vehicle.fuellings[i + 1].amountreference;
            }

            //Check if user can edit fuelling
            let editModal = "";
            let deleteModal = "";
            let twoDaysAgo = new Date();
            twoDaysAgo.setHours(twoDaysAgo.getHours() - 48);

            if (this.edit && date.getTime() >= twoDaysAgo.getTime() || userStore.isAdmin) {
                editModal = <AddEditDieselFuellingModal locations={this.props.locations} vehicle={this.props.vehicle}
                                                        fuellingIndex={i}/>;
                deleteModal = <DeleteFuellingModal vehicle={this.props.vehicle} fuellingIndex={i}/>;
            }



            //Month 12 == Year Overview
                if (this.props.month === "12") {
                    if (date.getFullYear() === this.props.year) {
                                fuellingRows.push(<DieselFuellingRow fuelling={this.props.vehicle.fuellings[i]}
                                                                     key={i + 1}
                                                                     vehicle={this.props.vehicle}
                                                                     locations={this.props.locations}
                                                                     variableVehicleVisible={this.props.variableVehicleVisible}
                                                                     reference={this.props.vehicle.reference}
                                                                     expect_consumption={this.props.vehicle.expect_consumption}
                                                                     referenceDiff={referenceDiff}
                                                                     editModal={editModal}
                                                                     deleteModal={deleteModal}/>);

                        let amountltr = this.props.vehicle.fuellings[i].amountltr;
                        literSum += amountltr;
                        kwhSum += amountltr * 9.96;
                        avgConsumption += (amountltr / referenceDiff);
                    }
                    if (date.getFullYear() <= this.props.year) {
                        if (referenceStand < this.props.vehicle.fuellings[i].amountreference) {
                            referenceStand = this.props.vehicle.fuellings[i].amountreference;
                        }
                    }
                } else {
                    if (date.getFullYear() === this.props.year && date.getMonth().toString() === this.props.month) {

                        fuellingRows.push(<DieselFuellingRow fuelling={this.props.vehicle.fuellings[i]} key={i + 1}
                                                             reference={this.props.vehicle.reference}
                                                             vehicle={this.props.vehicle}
                                                             variableVehicleVisible={this.props.variableVehicleVisible}
                                                             locations={this.props.locations}
                                                             expect_consumption={this.props.vehicle.expect_consumption}
                                                             referenceDiff={referenceDiff}
                                                             editModal={editModal}
                                                             deleteModal={deleteModal}/>);

                        let amountltr = this.props.vehicle.fuellings[i].amountltr;
                        literSum += amountltr;
                        kwhSum += amountltr * 9.96;
                        avgConsumption += (amountltr / referenceDiff);

                    }
                    if (date.getFullYear() <= this.props.year && date.getMonth() <= this.props.month) {
                        if (referenceStand < this.props.vehicle.fuellings[i].amountreference) {
                            referenceStand = this.props.vehicle.fuellings[i].amountreference;
                        }
                    }
                }
        }

        //Check if vehicle has given stand and take it
        if (this.props.vehicle.monthly_amounts.length !== 0) {
            let amounts = this.props.vehicle.monthly_amounts;

            for (let i = 0; i < amounts.length; i++) {
                if (amounts[i].year === this.props.year &&
                    (amounts[i].month <= this.props.month ||
                        (this.props.month === "12" && amounts[i].month.toString() === "12"))) {
                    if (referenceStand < amounts[i].amount)
                        referenceStand = amounts[i].amount;
                }

            }
        }

        //If vehicle has no fuellings the reference stand is the initial one of the vihicle
        if (this.props.vehicle.fuellings.length === 0 || referenceStand === 0) {
            referenceStand += this.props.vehicle.reference_stand;
        }

        if (fuellingRows.length > 0)
            avgConsumption /= fuellingRows.length;


        rows.push(<Table.Row active={this.props.vehicle.active === 0} key={0}>
            <Table.Cell>
                <Icon name={this.state.openFuellingsstate ? 'triangle up' : 'triangle down'}
                      onClick={this.toggleFuellings.bind(this)}/>
            </Table.Cell>
            {this.edit ?
                <Table.Cell>
                    <AddEditDieselFuellingModal locations={this.props.locations} vehicle={this.props.vehicle}/>
                </Table.Cell>
                :
                null
            }
            <Table.Cell>
                {this.props.vehicle.costcentre}
            </Table.Cell>
            <Table.Cell>
                {this.props.vehicle.designation}
            </Table.Cell>
            <Table.Cell>
                {this.props.vehicle.type}
            </Table.Cell>
            <Table.Cell>
                {this.props.vehicle.location}
            </Table.Cell>
            {this.edit ?
                <Table.Cell>
                    <ChangeReferenceStandModal amount={referenceStand} vehicle={this.props.vehicle}
                                               month={this.props.month} year={this.props.year}/>
                </Table.Cell>
                :
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.props.vehicle.reference)}
                </Table.Cell>
            }
            <Table.Cell>
                {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(kwhSum.toFixed(2))}
            </Table.Cell>
            <Table.Cell>
                {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(literSum.toFixed(2))}
            </Table.Cell>
            <Table.Cell className={this.getClassNameForAVG(avgConsumption)}>
                {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(avgConsumption.toFixed(2))} L/{this.props.vehicle.reference}
            </Table.Cell>
        </Table.Row>);

        if (this.state.openFuellingsstate) {
            rows.push(fuellingRows)
        }


        return (
            rows
        );
    }
}