import config from "../../config/main.config"
import userStore from "../stores/userStore"

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function isAdmin() {
    if (getTokenFromLocalStorage() !== null && typeof getTokenFromLocalStorage() !== "undefined") {
        return true;
    } else if (userStore.user !== null) {
        return true;
    }

    return false;
}

export function getTokenFromLocalStorage() {
    let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

    if (object === null) {
        return null;
    }

    let oneDayAfterTokenDate = new Date(object.timestamp);
    oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

    if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
        return object.token;
    } else {
        removeTokenFromStorage();
        return null;
    }
}

export function getRolesFromLocalStorage() {
    let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

    if (object === null) {
        return null;
    }

    let oneDayAfterTokenDate = new Date(object.timestamp);
    oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

    if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
        return object.roles;
    } else {
        removeTokenFromStorage();
        return null;
    }
}

export function removeTokenFromStorage() {
    if (typeof window.localStorage !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
    }
}

export function setTokenLocalStorage(token) {
    if (typeof window.localStorage !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function setUserLocalStorage(user) {
    if (typeof window.localStorage !== "undefined") {
        let object = {
            "token": user.token,
            "roles": user.roles,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function getDateWithoutTime(date) {
    //let checkdate = date;
    let d = date;
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let sMonth = month < 10 ? "0" + month : ""+ month;

    let day = d.getDate();
    let sDay = day < 10 ? "0" + day : ""+ day;


    return parseInt( "" + year + sMonth + sDay);
}