import React from "react";
import {Dropdown, Form, Grid, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";

@observer
export default class PlausibilityPage extends React.Component {

    @observable
    fuellingdeliveries = [];

    @observable
    dieselfuellings = [];

    @observable
    vehicles = [];

    @observable
    fuellingdelivery = {
        fuellingdeliveryType: "",
        date: new Date(),
        amountliter: "",
        calorificvalue: "",
        amountkWh: "",
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    dieselfuelling = {
        tmpEditValDesignation: "",
        tmpEditValAmountltr: 0,
        tmpEditValReference: "",
        tmpEditValAmountreference: 0,
        tmpVehicleId: 0,
        tempEditIndex: 0
    };

    @observable
    locations = [];

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedValYear: new Date().getFullYear(),
            currentSelectedValMonth: new Date().getMonth().toString(),

        };
    }

    componentWillMount() {
        fetch(config.BASE_URL + "fuellingdeliveries", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fuellingdeliveries = json;
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
        this.getLocations();
        this.getDieselFuellings();
        this.getVehicles();
    }

    getDieselFuellings() {
        fetch(config.BASE_URL + "dieselfuellings", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.dieselfuellings = json;
                });

            } else {
                console.log(response.status)
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fuellingdeliveries.locationId = json;
                    this.locations = json;
                });

            } else {
                console.log(response.status)
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    getVehicles() {
        fetch(config.BASE_URL + "vehicles/withFuelling", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.vehicles = json;
                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;

            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth
        }, () => {
        });

    }

    getAllKwHFromFuellingDeliveries(locationId, year, fuellingDeliveries) {
        let sum = 0;
        for (let i = 0; i < fuellingDeliveries.length; i++) {
            let deliveryDate = new Date(fuellingDeliveries[i].date * 1000);

            if (fuellingDeliveries[i].locationId === locationId && deliveryDate.getFullYear() === year) {
                //if type is 1 == Tanklieferung KWH-values must be added, if 2 == Tankkarte and if 3 == Fremdtankungen, the KWH-values must be subtracted from the Tankkarte and Fremdtankungen
                if (fuellingDeliveries[i].fuellingdeliveryType === 1) {
                    sum += fuellingDeliveries[i].amountkWh;
                } else {
                    sum -= fuellingDeliveries[i].amountkWh;
                }
            }
        }
        return sum;
    }

    getAllKwHFromDieselFuellings(locationId, year, dieselFuellings) {
        let sum = 0;
        for (let i = 0; i < dieselFuellings.length; i++) {
            let deliveryDate = new Date(dieselFuellings[i].date * 1000);
            for (let j = 0; j < this.vehicles.length; j++) {
                if (this.vehicles[j].variable === 1) {
                    if (dieselFuellings[i].vehicleId === this.vehicles[j].id) {
                        if (dieselFuellings[i].location === locationId && deliveryDate.getFullYear() === year) {
                            const calorificvalue = 9.96;
                            sum += (dieselFuellings[i].amountltr * calorificvalue);
                        }
                    }
                } else if (this.vehicles[j].variable === 0) {
                    if (dieselFuellings[i].vehicleId === this.vehicles[j].id) {
                        if (this.vehicles[j].locationId === locationId && deliveryDate.getFullYear() === year) {
                            const calorificvalue = 9.96;
                            sum += (dieselFuellings[i].amountltr * calorificvalue);
                        }
                    }
                }
            }
        }
        return sum;
    }

    calculateDeviataionPercentage(sumKwHFuelling, sumKwHDieselFuelling) {
        let percentage = (((sumKwHFuelling - sumKwHDieselFuelling) / sumKwHFuelling).toFixed(2) * 100).toFixed(2);
        if (isNaN(percentage) === true || sumKwHFuelling === 0) {
            percentage.toString();
            percentage = "/";
        }
        return percentage;
    }

    addPercentageSignFunction(deviationPercentage) {
        let finaldeviationPercentage;
        if (deviationPercentage !== "/") {
            finaldeviationPercentage = deviationPercentage + '%';
        } else {
            finaldeviationPercentage = "/";
        }

        return finaldeviationPercentage;
    }

    getClassNameForPercentage(finaldeviationPercentage) {
        if (finaldeviationPercentage >= 50) {
            return "high-average"
        } else if (finaldeviationPercentage >= 20) {
            return "mid-average"
        }
        return "";
    }


    render() {
        let fuellingdeliveries = this.fuellingdeliveries;
        const yearOptions = [];
        let yearArray = [];
        for (let i = 0; i < this.fuellingdeliveries.length; i++) {
            let deliveryDate = new Date(fuellingdeliveries[i].date * 1000);
            let deliveryYear = deliveryDate.getFullYear();
            if (!yearArray.includes(deliveryYear))
                yearArray.push(deliveryYear);
        }

        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }

        let locations = this.locations;

        let tableElements = [];

        for (let i = 0; i < locations.length; i++) {
            let sumKwHFuelling = this.getAllKwHFromFuellingDeliveries(locations[i].id, this.state.currentSelectedValYear, this.fuellingdeliveries);
            let sumKwHDieselFuelling = this.getAllKwHFromDieselFuellings(locations[i].id, this.state.currentSelectedValYear, this.dieselfuellings);
            let deviationPercentage = this.calculateDeviataionPercentage(sumKwHFuelling, sumKwHDieselFuelling);
            let finaldeviationPercentage = this.addPercentageSignFunction(deviationPercentage);

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(sumKwHDieselFuelling.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(sumKwHFuelling.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell className={this.getClassNameForPercentage(deviationPercentage)}>
                        {finaldeviationPercentage}
                    </Table.Cell>
                </Table.Row>
            );
        }
        for (let i = 0; i < this.fuellingdeliveries.length; i++) {
            let fuellingdeliveriesDate = this.fuellingdeliveries[i].date;
            let fuellingdeliveriesDateYear = new Date(fuellingdeliveriesDate * 1000).getFullYear();
            let fuellingdeliveriesDateMonth = (new Date(fuellingdeliveriesDate * 1000).getMonth());
            let temp = 0;
            if ((this.state.currentSelectedValYear === fuellingdeliveriesDateYear
                && fuellingdeliveriesDateMonth.toString() === this.state.currentSelectedValMonth)) {
                for (let j = 0; j < this.locations.length; j++) {
                    if (this.fuellingdeliveries[i].locationId === j) {
                        temp += this.fuellingdeliveries[i].amountkWh;
                    }
                }
            }

        }
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Plausibilität</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'}>Einsatzort</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'} colSpan={2}>Verbrauch in
                                        kWh/a</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>nach FE [kWh]</Table.HeaderCell>
                                    <Table.HeaderCell>Menge [kWh]</Table.HeaderCell>
                                    <Table.HeaderCell>Abweichung von Rechnung %</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
