import React from "react";
import {Dropdown, Form, Message, Modal, Table} from "semantic-ui-react";
import userStore from "../stores/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {checkEdit, checkRole} from "../helper/rolehelper";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";

const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const TYPE_ERROR = "Falsche Angabe : Art der Tanklieferung";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";

@observer
export default class FuellingDeliveryRow extends React.Component {

    @observable
    editFuellingdelivery = {
        fuellingdeliveryType: "",
        date: new Date(),
        amountliter: 0,
        calorificvalue: 0,
        amountkWh: 0,
        costs: 0,
        proof: "",
        locationId: 0,
        id: 0,
        comment: ""
    };
    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    addfuellingdeliveryModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable edit = false;

    componentDidMount() {
    }


    constructor(props) {
        super(props);

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/fuellingdelivery")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/fuellingdelivery");

        this.state = {
            open: false,
            deleteopen: false,
            delete: true,
            edit: true
        }
    }

    getFuellingdeliveryName(type) {
        if (type === 1) {
            return "Tanklieferung";
        } else if (type === 2) {
            return "Tankkartenabrechnungen";
        } else if (type === 3) {
            return "FremdTankungen";
        } else {
            //todo:error
        }
    }

    //functions for edit
    checkInputValues() {
        //Check if vehicle has fuelling and check if amounts are right
        if (this.editFuellingdelivery.length !== 0) {

            //Check if editHeatingoil has a valid type (1, 2 or 3)
            let type = (this.editFuellingdelivery.fuellingdeliveryType);
            if (type !== 1 && type !== 2 && type !== 3) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))
            let locationId = this.editFuellingdelivery.locationId;

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amountliter = this.editFuellingdelivery.amountliter;
            let costs = this.editFuellingdelivery.costs;
            let proof = this.editFuellingdelivery.proof;
            if (amountliter === "" || costs === "" || amountliter === 0 || costs === "" || costs === 0 || proof === "") {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }
            this.setEdit();
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    setEdit() {

        this.props.editFuellingDelivery(this.editFuellingdelivery, (this.editFuellingdelivery.fuellingdeliveryType));

        this.closeInput()
    }


    openEditFuellingDeliveryModal() {
        this.editFuellingdelivery.fuellingdeliveryType = this.props.fuellingdelivery.fuellingdeliveryType;
        this.editFuellingdelivery.date = new Date(this.props.fuellingdelivery.date * 1000);
        this.editFuellingdelivery.amountliter = this.props.fuellingdelivery.amountliter;
        this.editFuellingdelivery.calorificvalue = this.props.fuellingdelivery.calorificvalue;
        this.editFuellingdelivery.amountkWh = this.props.fuellingdelivery.amountkWh;
        this.editFuellingdelivery.costs = this.props.fuellingdelivery.costs;
        this.editFuellingdelivery.proof = this.props.fuellingdelivery.proof;
        this.editFuellingdelivery.comment = this.props.fuellingdelivery.comment;
        this.editFuellingdelivery.locationId = this.props.locationId;
        this.editFuellingdelivery.id = this.props.fuellingdelivery.id;
    }

    handleChange(date) {
        if (date === null) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.editFuellingdelivery.date = date;
        }
    }


    closeInput() {

        this.isEditModal = false;
        this.addfuellingdeliveryModalOpen = false;
        this.state.open = false;
        this.deleteModalOpen = false;
        this.editFuellingdelivery = {
            fuellingdeliveryType: this.props.fuellingdelivery.fuellingdeliveryType,
            date: new Date(this.props.fuellingdelivery.date * 1000),
            amountliter: this.props.fuellingdelivery.amountliter,
            calorificvalue: this.props.fuellingdelivery.calorificvalue,
            amountkWh: this.props.fuellingdelivery.amountkWh,
            costs: this.props.fuellingdelivery.costs,
            proof: this.props.fuellingdelivery.proof,
            locationId: this.props.locationId,
            comment: this.props.fuellingdelivery.comment,
            id: this.props.fuellingdelivery.id
        };

        this.error = false;
    }

    //if fuellingdeliveryType === 3 -> ignore - sign atamountliter
    calculateamountkWh() {
        this.editFuellingdelivery.calorificvalue = 9.96;

        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.amountliter) === -1) {
                this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountkWh = (this.editFuellingdelivery.amountliter * (-1)) * this.editFuellingdelivery.calorificvalue * 100) / 100;
            } else {
                this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountliter * this.editFuellingdelivery.calorificvalue * 100) / 100;
            }
        } else {
            this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountliter * this.editFuellingdelivery.calorificvalue * 100) / 100;
        }
    }

    calculateCosts() {
        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.costs) === -1) {
                this.editFuellingdelivery.costs = this.editFuellingdelivery.costs * (-1);
            } else {
                this.editFuellingdelivery.costs = this.editFuellingdelivery.costs;
            }
        } else {
            this.editFuellingdelivery.costs = this.editFuellingdelivery.costs;
        }
    }

    calculateAmountliter() {
        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.amountliter) === -1) {
                this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter * (-1);
            } else {
                this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter;
            }
        } else {
            this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter;
        }
    }

    handleInputChange(type, event, data) {
        this.calculateamountkWh();
        this.calculateCosts();
        this.calculateAmountliter();
        if (type === 'fuellingdeliveryType') {
            this.editFuellingdelivery.fuellingdeliveryType = data.value;
            this.calculateamountkWh();
            this.calculateCosts();
            this.calculateAmountliter();
        } else if (type === 'date') {
            this.editFuellingdelivery.date = data.value;
        } else if (type === 'costs') {
            if (data.value === "") {
                this.editFuellingdelivery.costs = 0;
            } else {
                this.editFuellingdelivery.costs = data.value;
            }
            this.calculateCosts();

        } else if (type === 'amountliter') {
            if (data.value === "") {
                this.editFuellingdelivery.amountliter = 0;
            } else {
                this.editFuellingdelivery.amountliter = data.value;
            }
            this.calculateAmountliter();
            this.calculateamountkWh();
        } else if (type === 'proof') {
            this.editFuellingdelivery.proof = data.value;
        } else if (type === 'comment') {
            this.editFuellingdelivery.comment = data.value;
        }
        this.editFuellingdelivery.locationId = this.props.locationId;
    }

    //functions for edit


    //functions for delete


    openDeleteFuellingDeliveryModal(fuellingdeliveriesId) {
        this.deleteModalOpen = true;
        this.props.deleteFuellingDelivery(fuellingdeliveriesId);
        this.closeDeleteInput();
    }


    closeDeleteInput() {
        this.addfuellingdeliveryModalOpen = false;
        this.isEditModal = false;
        this.state.deleteopen = false;
        this.deleteModalOpen = false;
        this.editFuellingdelivery = {
            fuellingdeliveryType: "",
            date: new Date(),
            amountliter: "",
            calorificvalue: "",
            amountkWh: "",
            costs: "",
            proof: "",
            comment: ""
        };
        this.error = false;
    }

    //functions for delete

    render() {
        const IdOptions = [
            {key: 4, text: 'Tanklieferung', value: 1},
            {key: 5, text: 'Tankkarte', value: 2},
            {key: 6, text: 'Fremdtankung', value: 3}
        ];
        const calorificvalue = 9.96;
        return (

            <Table.Row className="color-sub-row">
                <Table.Cell/>
                {this.edit ?
                    <Table.Cell>
                        <Modal open={this.state.deleteopen} className={"modal-top"}
                               onClose={this.closeDeleteInput.bind(this)} centered={false}
                               trigger={this.delete ?
                                   <Icon name='delete' color="red"
                                         onClick={() => this.setState({deleteopen: true, delete: true})}/>
                                   :
                                   <Icon name='delete' color="red"
                                         onClick={() => this.setState({deleteopen: true, delete: true})}/>
                               }>
                            <Modal.Header>Löschen</Modal.Header>
                            <Modal.Content>
                                Sicher löschen?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeDeleteInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.openDeleteFuellingDeliveryModal.bind(this, this.props.fuellingdelivery.id)}

                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                {this.edit ?
                    <Table.Cell>
                        <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                               trigger={
                                   <Icon name='edit' color="green"
                                         onClick={() => {
                                             this.openEditFuellingDeliveryModal();
                                             this.setState({open: true, edit: true});
                                         }}/>
                               }>
                            <Modal.Header>{this.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                            <Modal.Content>
                                <Form error={this.error}>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Karte oder Lieferung</label>
                                            <Dropdown selection placeholder="Tanklieferung" direction='right'
                                                      color='black'
                                                      fluid
                                                      options={IdOptions}
                                                      value={this.editFuellingdelivery.fuellingdeliveryType}
                                                      onChange={this.handleInputChange.bind(this, 'fuellingdeliveryType')}/>
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Datum</label>

                                            <DatePicker
                                                selected={this.editFuellingdelivery.date}
                                                onChange={this.handleChange.bind(this)}
                                                dateFormat="dd.MM.yyyy"
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Menge [l]</label>
                                            <NumberFormat value={parseFloat(this.editFuellingdelivery.amountliter)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('amountliter', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={true}
                                                          suffix=" l"/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Heizwert</label>
                                            {calorificvalue}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>kWh</label>
                                            {this.editFuellingdelivery.amountkWh}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Kosten [€]</label>
                                            <NumberFormat value={parseFloat(this.editFuellingdelivery.costs)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('costs', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={true}
                                                          suffix=" €"/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nachweis</label>
                                            <Form.Input value={this.editFuellingdelivery.proof}
                                                        type='proof'
                                                        onChange={this.handleInputChange.bind(this, 'proof')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bemerkung</label>
                                            <Form.Input value={this.editFuellingdelivery.comment}
                                                        type='comment'
                                                        onChange={this.handleInputChange.bind(this, 'comment')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={() => this.checkInputValues()}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                <Table.Cell>
                    {this.getFuellingdeliveryName(this.props.fuellingdelivery.fuellingdeliveryType)}
                </Table.Cell>
                <Table.Cell>
                    {new Date(this.props.fuellingdelivery.date * 1000).toLocaleDateString()}
                </Table.Cell>
                {this.props.fuellingdelivery.fuellingdeliveryType === 3 ?
                    <Table.Cell>
                        -
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(this.props.fuellingdelivery.amountliter.toFixed(2))}
                    </Table.Cell>
                    :
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(this.props.fuellingdelivery.amountliter.toFixed(2))}
                    </Table.Cell>
                }
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.props.fuellingdelivery.calorificvalue)}
                </Table.Cell>
                {this.props.fuellingdelivery.fuellingdeliveryType === 3 ?
                    <Table.Cell>
                        -
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(this.props.fuellingdelivery.amountkWh.toFixed(2))}
                    </Table.Cell>
                    :
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(this.props.fuellingdelivery.amountkWh.toFixed(2))}
                    </Table.Cell>
                }
                {this.props.fuellingdelivery.fuellingdeliveryType === 3 ?
                    <Table.Cell>
                        -
                        {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(this.props.fuellingdelivery.costs.toFixed(2))}
                    </Table.Cell>
                    :
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(this.props.fuellingdelivery.costs.toFixed(2))}
                    </Table.Cell>
                }
                <Table.Cell>
                    {this.props.fuellingdelivery.comment}
                </Table.Cell>
                <Table.Cell>
                    {this.props.fuellingdelivery.proof}
                </Table.Cell>
            </Table.Row>
        );
    }
}