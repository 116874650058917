import React from "react";
import {Button, Dropdown, Form, Grid, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";

import {checkEdit, checkRole} from "../helper/rolehelper";
import userStore from "../stores/userStore";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import ProductionMonth from "../components/ProductionMonth";


const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const TYPE_ERROR = "Falsche Angabe : Art der Abrechnung";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";


@observer
export default class ProductionPage extends React.Component {

    @observable
    productions = [];
    @observable
    editProductions = {
        product: 1,
        productionType: 1,
        factorvalue: 3,
        transported: 0,
        date: new Date(),
        amount: 0,
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addproductionModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;
    @observable edit = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),
            currentSelectedValMonth: new Date().getMonth(),
            open: false,
            deleteopen: false,
            delete: true,
            edit: false,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/production")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/production");
    }

    componentDidMount() {
        this.editProductions.product = 1;
        this.fetchProductions();
        this.getLocations();
    }

    fetchProductions() {
        fetch(config.BASE_URL + "productions", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.productions = json
                });

            } else {
                // console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                // console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    addProduction(editProduction, locationId, type, date, amount, proof, comment, product, factorvalue, transported) {
        fetch(config.BASE_URL + "productions", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                productionType: type,
                product: product,
                factorvalue: factorvalue,
                transported: transported,
                date: date,
                amount: amount,
                proof: proof,
                comment: comment,
                locationId: locationId
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchProductions();
            } else {
                //console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );

    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                //  console.log(response.status);
                //  console.log(error);

            }
        }).catch(
            error => {
                //   console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }


    handleDateChange(type, event, data) {
        let {currentSelectedValYear} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }


        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
        }, () => {
        });

    }

    getAllFromProductionLocation(location, productions) {
        let productionLocal = [];
        for (let j = 0; j < this.productions.length; j++) {
            if (this.productions[j].locationId === location) {
                productionLocal.push(productions[j]);
            }
        }
        return productionLocal;

    }

    ////add - edit
    checkInputValues(locationId) {
        //Check if vehicle has production and check if amounts are right
        if (this.editProductions.length !== 0) {
            //Check if a valid type (1 or 2)
            let product = (this.editProductions.product);
            if (product !== 1 && product !== 2 && product !== 3) {
                this.error = true;
                //    console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }
            //Check if a valid type (0, 1 or 2)
            let type = (this.editProductions.productionType);
            if (type !== 0 && type !== 1 && type !== 2) {
                this.error = true;
                //   console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amount = this.editProductions.amount;
            let proof = this.editProductions.proof;
            let factorvalue = this.editProduction.factorvalue;
            let transported = this.editProduction.transported;
            if (amount === "" || amount === 0 || proof === "" || factorvalue === 0 || transported === "") {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                //   console.log("value error");
                //   console.log(this.error);
                return;
            }

            this.initiateAddProduction(locationId);
        } else {
            this.error = true;
            //  console.log(this.error);
        }
    }

    initiateAddProduction(locationId) {
        this.editProductions.locationId = locationId;
        this.addProduction(this.editProductions, (this.editProductions.product));

        this.closeInput()
    }


    handleChange(date) {
        if (date === null) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.editProductions.date = date;
        }
    }

    closeInput() {
        this.addproductionModalOpen = false;
        this.isEditModal = false;
        this.state.open = false;
        this.deleteModalOpen = false;
        this.editProductions = {
            product: 1,
            productionType: 1,
            factorvalue: 3,
            transported: 0,
            date: new Date(),
            amount: 0,
            proof: "",
            locationId: "",
            id: 0,
            comment: ""
        };

        this.error = false;
    }

    deleteProduction(productionId) {
        fetch(config.BASE_URL + "productions/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: productionId})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.loading = false;


                this.fetchProductions();
            } else {
              //  console.log(response.status);
              //  console.log(error);
            }
        }).catch(
            error => {
              //  console.log(error);
            }
        );
    }

    editProduction(editProduction, locationId, type, date, amount, proof, comment, product, factorvalue, transported, id) {
        fetch(config.BASE_URL + "productions/", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                productionType: type,
                product: product,
                factorvalue: factorvalue,
                transported: transported,
                date: date,
                amount: amount,
                locationId: locationId,
                proof: proof,
                comment: comment,
                id: id
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchProductions();
            } else {
              //  console.log(response.status)
              //  console.log(error);
            }
        }).catch(
            error => {
               // console.log(error);
            }
        );

    }

    ////add edit

    getMonthYearProductionFromLocation(month, year, allProductions) {
        let monthProduction = []
        for (let i = 0; i < allProductions.length; i++) {

            let curDate = new Date(allProductions[i].date * 1000);
            if (curDate.getMonth() === month && curDate.getFullYear() === year) {
                monthProduction.push(allProductions[i]);
                //return allProductions[i];
            }
        }
        return monthProduction;
    }

    getPreMonthYearProductionFromLocation(month, year, allProductions) {
        let monthProduction = []
        for (let i = 0; i < allProductions.length; i++) {
            let preMonth = month - 1
            let curDate = new Date(allProductions[i].date * 1000);
            if (typeof curDate !== "undefined" || typeof preMonth !== "undefined") {
                if (curDate.getMonth() === preMonth && curDate.getFullYear() === year) {
                    monthProduction.push(allProductions[i]);
                    //return allProductions[i];
                }
            }
        }
        return monthProduction;
    }

    downloadExcelTable() {
        let {currentSelectedValYear} = this.state;

        fetch(config.BASE_URL + "productions/excel/" + currentSelectedValYear, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty('link')) {
                        window.open(config.BASE_FILE_URL + json.link);
                    }
                });
            } else {
                console.log("error");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    render() {
        const yearOptions = [];
        let addYearOption = new Date().getFullYear();
        let i = 0;
        while (addYearOption >= 2016) {
            yearOptions.push({
                key: i,
                text: addYearOption.toString(),
                value: addYearOption
            });
            i++;
            addYearOption--;
        }

        const ProductionOptions = [
            {key: 4, text: 'Tonnen', value: 1},
            {key: 5, text: 'm³', value: 2}
        ];

        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let tableElements = [];
        for (let i = 0; i < locations.length; i++) {

            let amountSum = 0;

            let locationProductions = this.getAllFromProductionLocation(locations[i].id, this.productions)
            for (let j = 0; j < locationProductions.length; j++) {

                let productionDate = locationProductions[j].date;
                let productionDateYear = new Date(productionDate * 1000).getFullYear();

                if ((this.state.currentSelectedValYear === productionDateYear)) {

                    if (locationProductions[j].product === 1 || locationProductions[j].product === 2) {
                        amountSum += locationProductions[j].amount;
                    } else {
                        //   console.log(this.error);
                    }

                }
            }
            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingMonths ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingMonths = !this.locations[i].showingMonths}/>
                    </Table.Cell>

                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(amountSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                </Table.Row>
            );
            let locationMonthlyFigures = [];
            for (let j = 0; j < 12; j++) {
                //month starts by 0
                let monthProduction = this.getMonthYearProductionFromLocation(j, this.state.currentSelectedValYear, locationProductions);
                let preMonthProduction = this.getPreMonthYearProductionFromLocation(j, this.state.currentSelectedValYear, locationProductions);

                locationMonthlyFigures.push(<ProductionMonth key={i + "_" + j}
                                                             production={monthProduction}
                                                             preMonthProduction={preMonthProduction}
                                                             locationId={locations[i].id}
                                                             deleteProduction={this.deleteProduction.bind(this)}
                                                             editProductions={this.editProduction.bind(this)}
                                                             addProduction={this.addProduction.bind(this)}
                                                             locationProductions={locationProductions}
                                                             month={j}
                                                             year={this.state.currentSelectedValYear}
                />);
            }

            if (locations[i].showingMonths) {
                tableElements.push(locationMonthlyFigures);
            }

            for (let i = 0; i < this.productions.length; i++) {
                let productionDate = this.productions[i].date;
                let productionDateYear = new Date(productionDate * 1000).getFullYear();
                let temp = 0;
                if ((this.state.currentSelectedValYear === productionDateYear)) {
                    for (let j = 0; j < this.locations.length; j++) {
                        if (this.productions[i].locationId === j) {
                            temp += this.productions[i].amount;
                        }
                    }
                }
            }
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Produktionserfassung</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            icon
                            labelPosition='right'
                            positive
                            onClick={() => this.downloadExcelTable()}>
                            Exportieren
                            <Icon name={"external alternate"}/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>Monat</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Art der Mengenangabe [t/ m³]</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Menge [t/ m³]</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Bemerkung</Table.HeaderCell>
                                    <Table.HeaderCell>Nachweis</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}