import React from "react";
import {Dropdown, Form, Message, Modal, Table} from "semantic-ui-react";
import userStore from "../stores/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {checkEdit, checkRole} from "../helper/rolehelper";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NumberFormat from "react-number-format";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const DATE_ERROR = "Datum falsch gesetzt.";
const TYPE_ERROR = "Falsche Angabe : Tonnen oder m³ wurde nicht angegeben";
const TYPE_ERROR2 = "Falsche Angabe : Art des Produkts wurde nicht angegeben";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";

@observer
export default class ProductionMonthRow extends React.Component {

    @observable
    editproduction = {
        product: 1,
        productionType: 1,
        factorvalue: 1,
        transported: 0,
        date: new Date(),
        amount: 0,
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };
    @observable
    error = false;

    @observable
    productionId;

    @observable
    isEditModal = false;

    @observable
    addproductionModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable edit = true;


    constructor(props) {

        super(props);

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/production")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/production");

        this.state = {
            open: false,
            deleteopen: false,
            delete: true,
            edit: true
        }

        this.editable = false;
        if (typeof this.props.production.date === "undefined") {
            this.editproduction.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }
    }

    componentDidMount() {

        if (typeof this.props.production.date === "undefined") {
            this.editproduction.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }
    }


    getProductName(type) {
        if (type === 1) {
            return "Tonnen (Splitt/Kies)";
        } else if (type === 2) {
            return "m³ (Beton)";
        } else {
            //todo:error
        }
    }

    getTransportStatus(type) {
        if (type === 1) {
            return "transportiert";
        } else if (type === 0) {
            return "nicht transportiert";
        } else {
            //todo:error
        }
    }

    getProductionTypeName(type) {
        if (type === 1) {
            return "Splitt";
        } else if (type === 2) {
            return "Kies";
        } else if (type === 3) {
            return "gepumpt";
        } else if (type === 4) {
            return "produziert";
        } else {
            //todo:error
        }
    }


    //functions for edit
    checkInputValues(locationId, currentmonthmonth, id) {
        if (this.editproduction.length !== 0) {
            let currentmonth = currentmonthmonth;
            let currentYear = this.props.year;
            let monthDate = new Date().setMonth(currentmonth);
            let date = new Date(monthDate).setFullYear(currentYear)
            //Check if a valid type (1 or 2 or 3)
            let product = (this.editproduction.product);
            if (product !== 1 && product !== 2) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }
            //Check if a valid type (1, 2, 3 or 4)
            let type = (this.editproduction.productionType);

            if (type !== 1 && type !== 2 && type !== 3 && type !== 4) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR2;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amount = this.editproduction.amount;
            let proof = this.editproduction.proof;
            let comment = this.editproduction.comment;
            let id = this.editproduction.id;
            let factorvalue = this.editproduction.factorvalue;
            let transported = this.editproduction.transported;

        /*    if (amount === "" || amount === 0) {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            } else*/

                if (factorvalue === 0 && type === 1) {

                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }
            if (this.editable) {
                this.setEdit(locationId, type, date, amount, proof, comment, product, factorvalue, transported, id);
            } else {
                this.initiateAddProduction(locationId, type, date, amount, proof, comment, product, factorvalue, transported);
            }
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddProduction(locationId, type, date, amount, proof, comment, product, factorvalue, transported) {
        this.props.addProduction(this.editproduction, locationId, type, date, amount, proof, comment, product, factorvalue, transported);

        this.closeInput()
    }

    setEdit(locationId, type, date, amount, proof, comment, product, factorvalue, transported, id) {
        this.props.editProductions(this.editproduction, locationId, type, date, amount, proof, comment, product, factorvalue, transported, id);

        this.closeInput()
    }


    openEditProductionModal() {
        this.editproduction.product = this.props.production.product;
        this.editproduction.productionType = this.props.production.productionType;
        this.editproduction.factorvalue = this.props.production.factorvalue;
        this.editproduction.transported = this.props.production.transported;
        this.editproduction.date = new Date(this.props.production.date * 1000);
        this.editproduction.amount = this.props.production.amount;
        this.editproduction.proof = this.props.production.proof;
        this.editproduction.comment = this.props.production.comment;
        this.editproduction.locationId = this.props.locationId;
        this.editproduction.id = this.props.production.id;
    }

    handleChange(date) {
        if (this.isEditModal === false) {

            if (date.getMonth !== this.props.month) {
                this.editproduction.date = date;
            } else {
                this.setState({
                    ...this.state,
                    error: true

                });
                this.errorMessage = DATE_ERROR;
                return;
            }

        }
    }

    getProductionName(type) {
        if (type === 1) {
            return "Tonnen";
        } else if (type === 2) {
            return "m³";
        } else {
            //todo:error
        }
    }


    closeInput() {
        if (this.isEditModal === true) {
            this.isEditModal = true;
            this.addProductionModalOpen = false;
            this.deleteModalOpen = false;
            this.editproduction = {
                product: this.props.production.product,
                date: new Date(this.props.production.date * 1000),
                amount: this.props.production.amount,
                proof: this.props.production.proof,
                locationId: this.props.locationId,
                comment: this.props.production.comment,
                id: this.props.production.id,
                factorvalue: this.props.production.factorvalue,
                transported: this.props.production.transported,
                productionType: this.props.production.productionType
            };
        } else {
            this.addproductionModalOpen = false;
            this.isEditModal = true;
            this.deleteModalOpen = false;
            this.editProduction = {
                product: 1,
                date: new Date(),
                amount: 0,
                proof: "",
                comment: "",
                factorvalue: 1,
                transported: 0,
                productionType: 1
            };
        }
        this.setState({
            ...this.state,
            open: false
        })
        this.error = false;
    }

    setFactorValue() {
        if (this.editproduction.productionType === 1) {
            if (this.props.production.length === 0) {
                this.editproduction.factorvalue = 3;
            } else {
                this.editproduction.factorvalue = this.props.defaultSplittfactorvalue;
            }
        } else if (this.editproduction.productionType === 2) {
            this.editproduction.factorvalue = 0;
        } else if (this.editproduction.productionType === 3) {
            //TODO set default value
            this.editproduction.factorvalue = 0;
        } else if (this.editproduction.productionType === 4) {
            //TODO set default value
            this.editproduction.factorvalue = 0;
        }
    }

    isFactorDisabled() {
        return this.editproduction.productionType !== 1 || this.editproduction.product !== 1
    }

    handleInputChange(type, event, data) {
        if (type === 'product') {
            this.editproduction.product = data.value;
            this.setFactorValue();
        } else if (type === 'productionId') {
            this.productionId = data.value;
        } else if (type === 'productionType') {
            this.editproduction.productionType = data.value;
            this.setFactorValue();
        } else if (type === 'factorvalue') {
            this.editproduction.factorvalue = data.value;
        } else if (type === 'transported') {
            this.editproduction.transported = this.editproduction.transported === 1 ? 0 : 1;
        } else if (type === 'date') {
            this.editproduction.date = data.value;
        } else if (type === 'amount') {
            if (data.value === "") {
                this.editproduction.amount = 0;
            } else {
                this.editproduction.amount = data.value;
            }
        } else if (type === 'proof') {
            this.editproduction.proof = data.value;
        } else if (type === 'comment') {
            this.editproduction.comment = data.value;
        }
        this.editproduction.locationId = this.props.locationId;
    }

    //functions for edit


    render() {
        if (typeof this.props.production.date === "undefined") {
            this.editproduction.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }


        const IdOptions = [
            {key: 4, text: 'Tonnen (Splitt/Kies)', value: 1},
            {key: 5, text: 'm³ (Beton)', value: 2}
        ];
        const TonnenOptions = [
            {key: 4, text: 'Splitt', value: 1},
            {key: 5, text: 'Kies', value: 2}
        ];
        const MeterOptions = [
            {key: 4, text: 'gepumpt', value: 3},
            {key: 5, text: 'produziert', value: 4}
        ];

        return (

            <Table.Row className="color-sub-row">
                <Table.Cell/>
                {this.edit ?
                    <Table.Cell>
                        <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                               trigger={
                                   <Icon name='edit' color="green"
                                         onClick={() => {
                                             this.openEditProductionModal();
                                             this.setState({open: true, editable: true, isEditModal: true});
                                         }}/>
                               }>
                            <Modal.Header>{this.editable ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                            <Modal.Content>
                                <Form error={this.error}>
                                    <Form.Group widths='equal'>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Menge</label>
                                            <NumberFormat value={parseFloat(this.editproduction.amount)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('amount', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Tonnen oder m³</label>
                                            <Dropdown selection placeholder="Tonnen" direction='right'
                                                      color='black'
                                                      fluid
                                                      options={IdOptions}
                                                      value={this.editproduction.product}
                                                      onChange={this.handleInputChange.bind(this, 'product')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Faktor</label>
                                            <NumberFormat value={parseFloat(this.editproduction.factorvalue)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('factorvalue', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          disabled={this.isFactorDisabled()}/>
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        {this.editproduction.product === 1 ?
                                            <Form.Field>
                                                <label>Splitt/Kies</label>
                                                <Dropdown selection placeholder="Splitt" direction='right'
                                                          color='black'
                                                          fluid
                                                          options={TonnenOptions}
                                                          value={this.editproduction.productionType}
                                                          onChange={this.handleInputChange.bind(this, 'productionType')}/>
                                            </Form.Field>
                                            :
                                            <Form.Field>
                                                <label>gepumpt/produziert</label>
                                                <Dropdown selection placeholder="gepumpt" direction='right'
                                                          color='black'
                                                          fluid
                                                          options={MeterOptions}
                                                          value={this.editproduction.productionType}
                                                          onChange={this.handleInputChange.bind(this, 'productionType')}/>
                                            </Form.Field>}
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nachweis</label>
                                            <Form.Input value={this.editproduction.proof}
                                                        type='proof'
                                                        onChange={this.handleInputChange.bind(this, 'proof')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bemerkung</label>
                                            <Form.Input value={this.editproduction.comment}
                                                        type='comment'
                                                        onChange={this.handleInputChange.bind(this, 'comment')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Transportiert</label>
                                            <Checkbox defaultChecked={this.editproduction.transported === 1}
                                                      value={this.editproduction.transported}
                                                      onChange={this.handleInputChange.bind(this, 'transported')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={() => this.checkInputValues(this.props.locationId, this.props.month, this.props.production.id)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    {this.getProductName(this.props.production.product)}
                </Table.Cell>
                <Table.Cell>
                    {this.getProductionTypeName(this.props.production.productionType)}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.props.production.amount.toFixed(2))}
                </Table.Cell>
                <Table.Cell>
                    {this.getTransportStatus(this.props.production.transported)}
                </Table.Cell>
                <Table.Cell>
                    {this.props.production.comment}
                </Table.Cell>
                <Table.Cell>
                    {this.props.production.proof}
                </Table.Cell>
            </Table.Row>
        );
    }
}