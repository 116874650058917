import React from "react";
import config from "../../config/main.config";
import {Button, Dropdown, Form, Grid, Modal, Table} from "semantic-ui-react";
import {getTokenFromLocalStorage} from "../helper/util";
import TableHeader from "semantic-ui-react/dist/commonjs/collections/Table/TableHeader";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import {observer} from "mobx-react";
import {observable} from "mobx";
import userStore from "../stores/userStore";
import dieselStore from "../stores/dieselStore";
import NumberFormat from "react-number-format";

@observer
export default class VehiclePage extends React.Component {

    @observable privilegedUser = false;

    @observable edit = false;

    constructor(props) {
        super(props);
        this.state = {
            vehicles: [],
            reference: [],
            location: [],
            tmpEditValCostcentre: "",
            tmpEditValDesignation: "",
            tmpEditValType: "",
            tmpEditValLocation: "",
            tmpEditValReference: "",
            tmpEditValConsumption: "",
            tmpEditValReferenceStand: "",
            tmpEditValActive: 1,
            tmpEditValVariable: 0,
            tempEditIndex: 0,
            addModalOpen: false,
            isEditModal: false,
            canEditStand: true,
            deleteModalOpen: false
        };

        let user = userStore.userRolesFromServer;
        this.privilegedUser = user.toString().includes("admin");

        this.edit = user.toString().includes("admin");
    }


    componentDidMount() {
        this.getVehicle();
        this.getReference();
        this.getLocations();
        dieselStore.fetchDieselfuellings();
    }

    getVehicle() {
        fetch(config.BASE_URL + "vehicles/withFuelling", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({
                        vehicles: json
                    });
                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    getReference() {
        fetch(config.BASE_URL + "reference", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({
                        reference: json
                    });

                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({
                        location: json
                    });

                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    openEdit() {
        this.setState({
            ...this.state,
            addModalOpen: true
        });
    }

    handleInputChange(type, event, data) {
        let {
            tmpEditValCostcentre,
            tmpEditValDesignation,
            tmpEditValType,
            tmpEditValLocation,
            tmpEditValReference,
            tmpEditValActive,
            tmpEditValVariable,
            tmpEditValConsumption,
            tmpEditValReferenceStand
        } = this.state;

        if (type === 'costcentre') {
            //only numbers
            tmpEditValCostcentre = data.value.replace(/[^\d]/g, '');
        }
        if (type === 'designation') {
            tmpEditValDesignation = data.value;
        }
        if (type === 'type') {
            tmpEditValType = data.value;
        }
        if (type === 'location') {
            tmpEditValLocation = data.value;
        }
        if (type === 'reference') {
            tmpEditValReference = data.value;
        }
        if (type === 'active') {
            tmpEditValActive = tmpEditValActive === 1 ? 0 : 1;
        }
        if (type === 'variable') {
            tmpEditValVariable = tmpEditValVariable === 1 ? 0 : 1;
        }
        if (type === 'consumption') {
            if (data.value === "") {
                tmpEditValConsumption = 0;
            } else {
                tmpEditValConsumption = data.value;
            }
        }
        if (type === 'reference_stand') {
            if (data.value === "") {
                tmpEditValReferenceStand = 0;
            } else {
                tmpEditValReferenceStand = data.value;
            }
        }

        this.setState({
            ...this.state,
            tmpEditValCostcentre: tmpEditValCostcentre,
            tmpEditValDesignation: tmpEditValDesignation,
            tmpEditValType: tmpEditValType,
            tmpEditValLocation: tmpEditValLocation,
            tmpEditValReference: tmpEditValReference,
            tmpEditValActive: tmpEditValActive,
            tmpEditValVariable: tmpEditValVariable,
            tmpEditValConsumption: tmpEditValConsumption,
            tmpEditValReferenceStand: tmpEditValReferenceStand
        })
    }

    closeInput() {
        this.setState({
            ...this.state,
            tmpEditValCostcentre: "",
            tmpEditValDesignation: "",
            tmpEditValType: "",
            tmpEditValConsumption: "",
            tmpEditValReferenceStand: "",
            tmpEditValLocation: "",
            tmpEditValReference: "",
            addModalOpen: false,
            isEditModal: false,
            canEditStand: true,
            deleteModalOpen: false
        })
    }

    setEdit() {
        let {
            tmpEditValCostcentre,
            tmpEditValDesignation,
            tmpEditValType,
            tmpEditValLocation,
            tmpEditValActive,
            tmpEditValVariable,
            tmpEditValReference,
            tmpEditValConsumption,
            tmpEditValReferenceStand,
            reference,
            location,
            isEditModal,
            tempEditIndex,
            vehicles
        } = this.state;

        let refId = 0;
        for (let i = 0; i < reference.length; i++) {
            if (tmpEditValReference === reference[i].name) {
                refId = reference[i].id;
                break;
            }
        }

        let locId = 0;
        for (let i = 0; i < location.length; i++) {
            if (tmpEditValLocation === location[i].caompanyname) {
                locId = location[i].id;
                break;
            }
        }

        let vehicle = {
            costcentre: tmpEditValCostcentre,
            designation: tmpEditValDesignation,
            type: tmpEditValType,
            locationId: locId,
            referenceId: refId,
            active: tmpEditValActive,
            variable: tmpEditValVariable,
            expect_consumption: tmpEditValConsumption,
            reference_stand: tmpEditValReferenceStand
        };
        if (isEditModal) {
            vehicle.id = vehicles[tempEditIndex].id;
            if (this.checkEdit(vehicle)) {
                console.log(vehicle);
                this.editVehicle(vehicle);
                this.closeInput()
            }
        } else {
            this.createVehicle(vehicle);
            this.closeInput()
        }

    }

    checkEdit(vehicle) {

        for (let i = 0; i < dieselStore.dieselfuellings.length; i++) {
            if (vehicle.id === dieselStore.dieselfuellings[i].id) {
                let fuellings = dieselStore.dieselfuellings[i].fuellings;
                if (fuellings.length === 0) {
                    return true;
                }
                let lowestRefStand = null;
                for (let j = 0; j < fuellings.length; j++) {
                    if (lowestRefStand === null || fuellings[j].amountreference < lowestRefStand) {
                        lowestRefStand = fuellings[j].amountreference;
                    }
                }

                return vehicle.reference_stand < lowestRefStand;
            }
        }

        return false;
    }

    setActive(index) {
        let {vehicles} = this.state;
        vehicles[index].active = vehicles[index].active === 0 ? 1 : 0;
        this.changeActive(vehicles[index]);
        this.setState({
            ...this.state,
            vehicles: vehicles
        })
    }


    changeActive(vehicle) {
        fetch(config.BASE_URL + "vehicles/active", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id: vehicle.id,
                active: vehicle.active
            })
        })
    }

    createVehicle(vehicle) {
        fetch(config.BASE_URL + "vehicles/create", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(vehicle)
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    editVehicle(vehicle) {
        fetch(config.BASE_URL + "vehicles/", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(vehicle)
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    deleteVehicle() {
        let {vehicles, tempEditIndex} = this.state;

        fetch(config.BASE_URL + "vehicles/", {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: vehicles[tempEditIndex].id})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
        this.closeInput();
    }

    openEditVehicleModal(vehicle, index) {
        this.setState({
            ...this.state,
            tmpEditValCostcentre: vehicle.costcentre,
            tmpEditValDesignation: vehicle.designation,
            tmpEditValType: vehicle.type,
            tmpEditValLocation: vehicle.location,
            tmpEditValReference: vehicle.reference,
            tmpEditValConsumption: vehicle.expect_consumption,
            tmpEditValReferenceStand: vehicle.reference_stand,
            tmpEditValActive: vehicle.active,
            tmpEditValVariable: vehicle.variable,
            tempEditIndex: index,
            addModalOpen: true,
            isEditModal: true,
            deleteModalOpen: false,
            canEditStand: vehicle.fuellings.length === 0
        })
    }

    openDeleteVehicleModal(index) {
        this.setState({
            ...this.state,
            tempEditIndex: index,
            deleteModalOpen: true
        })
    }


    show = dimmer => () => this.setState({dimmer, open: true});

    render() {

        const {addModalOpen} = this.state;
        let reference = this.state.reference;
        let location = this.state.location;


        const locationOptions = [];
        for (let i = 0; i < location.length; i++) {
            locationOptions.push({
                key: i,
                text: location[i].caompanyname,
                value: location[i].caompanyname,
                id: location[i].id
            })
        }

        const referenceOptions = [];
        for (let i = 0; i < reference.length; i++) {
            referenceOptions.push({
                key: i,
                text: reference[i].name,
                value: reference[i].name,
                id: reference[i].id
            })
        }


        let tableElements = this.state.vehicles.map((vehicle, index) => (
            <Table.Row key={index} active={vehicle.active === 0}>
                <Table.Cell>
                    {vehicle.costcentre}
                </Table.Cell>
                <Table.Cell>
                    {vehicle.designation}
                </Table.Cell>
                <Table.Cell>
                    {vehicle.type}
                </Table.Cell>
                <Table.Cell>
                    {vehicle.location}
                </Table.Cell>
                <Table.Cell>
                    {vehicle.reference}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(vehicle.expect_consumption)}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(vehicle.reference_stand)}
                </Table.Cell>
                <Table.Cell>
                    <Checkbox checked={vehicle.active === 1} onChange={this.setActive.bind(this, index)}/>
                </Table.Cell>
                <Table.Cell>
                    <Button className="ui basic icon negative button"
                            onClick={this.openDeleteVehicleModal.bind(this, index)}>
                        <i aria-hidden="true"
                           className="remove icon"/>
                    </Button>
                    <Button className="ui basic icon positive button"
                            onClick={this.openEditVehicleModal.bind(this, vehicle, index)}>
                        <i aria-hidden="true"
                           className="edit icon"/>
                    </Button>
                </Table.Cell>
            </Table.Row>

        ));

        return (
            <Grid with={16}>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Fuhrpark</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button circular color='blue' icon='add' floated={"left"}
                                onClick={this.openEdit.bind(this)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'}>Kontostelle</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Bezeichnung</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Typ</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Werk</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Bezugsgröße</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Normalwert Verbrauch Ø</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Km/Bh Stand</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Aktiv</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Bearbeiten</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Modal open={addModalOpen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>{this.state.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Kontostelle</label>
                                            <Form.Input fluid value={this.state.tmpEditValCostcentre}
                                                        onChange={this.handleInputChange.bind(this, 'costcentre')}/>
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Normalwert Verbrauch Ø</label>
                                            <NumberFormat value={parseFloat(this.state.tmpEditValConsumption)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('consumption', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Bezeichnung</label>
                                            <Form.Input fluid value={this.state.tmpEditValDesignation}
                                                        onChange={this.handleInputChange.bind(this, 'designation')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Typ</label>
                                            <Form.Input fluid value={this.state.tmpEditValType}
                                                        onChange={this.handleInputChange.bind(this, 'type')}/>
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Ort</label>
                                            <Dropdown selection placeholder="Ort" direction='right' color='black'
                                                      options={locationOptions}
                                                      value={this.state.tmpEditValLocation}
                                                      onChange={this.handleInputChange.bind(this, 'location')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bezugsgröße</label>
                                            <Dropdown selection placeholder="Bezugsgröße" direction='right'
                                                      color='black'
                                                      options={referenceOptions}
                                                      value={this.state.tmpEditValReference}
                                                      onChange={this.handleInputChange.bind(this, 'reference')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field>
                                        <label>Km/Bh Stand</label>
                                        {this.state.canEditStand || this.state.isEditModal ?

                                            <Form.Field>
                                                <NumberFormat value={parseFloat(this.state.tmpEditValReferenceStand)}
                                                              thousandSeparator="."
                                                              decimalSeparator=","
                                                              decimalScale={2}
                                                              onValueChange={(values, e) => {
                                                                  this.handleInputChange('reference_stand', null, {value: values.floatValue})
                                                              }}
                                                              allowNegative={false}/>
                                            </Form.Field>
                                            :
                                            <p></p>
                                        }

                                    </Form.Field>
                                    <Form.Field>
                                        <label>Aktiv</label>
                                        <Checkbox defaultChecked={this.state.tmpEditValActive === 1}
                                                  value={this.state.tmpEditValActive}
                                                  onChange={this.handleInputChange.bind(this, 'active')}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Variabler Fahrzeugstandort</label>
                                        <Checkbox defaultChecked={this.state.tmpEditValVariable === 1}
                                                  value={this.state.tmpEditValVariable}
                                                  onChange={this.handleInputChange.bind(this, 'variable')}/>
                                    </Form.Field>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.setEdit.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                    <Grid.Column>
                        <Modal open={this.state.deleteModalOpen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>Löschen</Modal.Header>
                            <Modal.Content>
                                Sicher löschen?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.deleteVehicle.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        );
    }
}
