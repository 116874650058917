import React from "react";
import {Button, Dropdown, Form, Grid, Message, Modal, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";


import DatePicker from "react-datepicker/es";
import {checkEdit, checkRole} from "../helper/rolehelper";
import userStore from "../stores/userStore";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import FuellingDeliveryRow from "../components/FuellingDeliveryRow";
import NumberFormat from "react-number-format";


const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const TYPE_ERROR = "Falsche Angabe : Art der Tanklieferung";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";


@observer
export default class FuellingDeliveryPage extends React.Component {

    @observable
    fuellingdeliveries = [];
    @observable
    editFuellingdelivery = {
        fuellingdeliveryType: "",
        date: new Date(),
        amountliter: "",
        calorificvalue: "",
        amountkWh: "",
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addfuellingdeliveryModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),
            currentSelectedValMonth: new Date().getMonth().toString(),

            open: false,
            deleteopen: false,
            delete: true,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/fuellingdelivery")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/fuellingdelivery");
    }

    componentDidMount() {
        this.editFuellingdelivery.fuellingdeliveryType = 1;
        this.fetchFuellingDeliveries();
        this.getLocations();
    }

    fetchFuellingDeliveries() {
        fetch(config.BASE_URL + "fuellingdeliveries", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fuellingdeliveries = json
                });
            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    addFuellingDelivery(editFuellingdelivery, type) {
        fetch(config.BASE_URL + "fuellingdeliveries", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                fuellingdeliveryType: type,
                date: editFuellingdelivery.date,
                amountliter: editFuellingdelivery.amountliter,
                calorificvalue: editFuellingdelivery.calorificvalue,
                amountkWh: editFuellingdelivery.amountkWh,
                costs: editFuellingdelivery.costs,
                proof: editFuellingdelivery.proof,
                comment: editFuellingdelivery.comment,
                locationId: editFuellingdelivery.locationId
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchFuellingDeliveries();
            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );

    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingFuellings = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status);
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }


    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;

            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth
        }, () => {
        });

    }

    getAllFromFuellingDeliveryLocation(location, fuellingdeliveries) {
        let fuellingdeliveryLocal = [];
        for (let j = 0; j < this.fuellingdeliveries.length; j++) {
            if (this.fuellingdeliveries[j].locationId === location) {
                fuellingdeliveryLocal.push(fuellingdeliveries[j]);
            }
        }
        return fuellingdeliveryLocal;

    }

    ////add - edit
    checkInputValues(locationId) {
        //Check if vehicle has fuelling and check if amounts are right
        if (this.editFuellingdelivery.length !== 0) {

            //Check if fuelling has a valid type (1, 2 or 3)
            let type = (this.editFuellingdelivery.fuellingdeliveryType);
            if (type !== 1 && type !== 2 && type !== 3) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amountliter = this.editFuellingdelivery.amountliter;
            let costs = this.editFuellingdelivery.costs;
            let proof = this.editFuellingdelivery.proof;

            if (amountliter === "" || costs === "" || proof === "") {//|| amountliter === 0 || costs === "" || costs === 0
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }
            this.initiateAddFuellingDelivery(locationId);
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddFuellingDelivery(locationId) {
        this.editFuellingdelivery.locationId = locationId;
        this.addFuellingDelivery(this.editFuellingdelivery, (this.editFuellingdelivery.fuellingdeliveryType));

        this.closeInput()
    }


    handleChange(date) {
        if (date === null) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.editFuellingdelivery.date = date;
        }
    }

    closeInput() {
        this.addfuellingdeliveryModalOpen = false;
        this.isEditModal = false;
        this.state.open = false;
        this.deleteModalOpen = false;
        this.editFuellingdelivery = {
            fuellingdeliveryType: "",
            date: new Date(),
            amountliter: "",
            calorificvalue: "",
            amountkWh: "",
            costs: "",
            proof: "",
            comment: ""
        };
        this.error = false;
    }

    deleteFuellingDelivery(fuellingdeliveriesId) {
        fetch(config.BASE_URL + "fuellingdeliveries/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: fuellingdeliveriesId})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.loading = false;


                this.fetchFuellingDeliveries();
            } else {
                console.log(response.status);
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    editFuellingDelivery(editFuellingdelivery, type) {

        fetch(config.BASE_URL + "fuellingdeliveries/", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                fuellingdeliveryType: type,
                date: editFuellingdelivery.date,
                amountliter: editFuellingdelivery.amountliter,
                calorificvalue: editFuellingdelivery.calorificvalue,
                amountkWh: editFuellingdelivery.amountkWh,
                costs: editFuellingdelivery.costs,
                locationId: editFuellingdelivery.locationId,
                proof: editFuellingdelivery.proof,
                comment: editFuellingdelivery.comment,
                id: editFuellingdelivery.id
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchFuellingDeliveries();
            } else {
                console.log(response.status)
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );

    }

//if fuellingdeliveryType === 3 -> ignore - sign atamountliter
    calculateamountkWh() {
        this.editFuellingdelivery.calorificvalue = 9.96;

        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.amountliter) === -1) {
                this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountkWh = (this.editFuellingdelivery.amountliter * (-1)) * this.editFuellingdelivery.calorificvalue * 100) / 100;
            } else {
                this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountliter * this.editFuellingdelivery.calorificvalue * 100) / 100;
            }
        } else {
            this.editFuellingdelivery.amountkWh = Math.round(this.editFuellingdelivery.amountliter * this.editFuellingdelivery.calorificvalue * 100) / 100;
        }
    }

    calculateCosts() {
        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.costs) === -1) {
                this.editFuellingdelivery.costs = this.editFuellingdelivery.costs * (-1);
            } else {
                this.editFuellingdelivery.costs = this.editFuellingdelivery.costs;
            }
        } else {
            this.editFuellingdelivery.costs = this.editFuellingdelivery.costs;
        }
    }

    calculateAmountliter() {
        if (this.editFuellingdelivery.fuellingdeliveryType === 3) {
            if (Math.sign(this.editFuellingdelivery.amountliter) === -1) {
                this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter * (-1);
            } else {
                this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter;
            }
        } else {
            this.editFuellingdelivery.amountliter = this.editFuellingdelivery.amountliter;
        }
    }


    handleInputChange(type, event, data) {

        this.calculateamountkWh();
        this.calculateCosts();
        this.calculateAmountliter();
        if (type === 'fuellingdeliveryType') {
            this.editFuellingdelivery.fuellingdeliveryType = data.value;
            this.calculateamountkWh();
            this.calculateCosts();
            this.calculateAmountliter();
        } else if (type === 'date') {
            this.editFuellingdelivery.date = data.value;
        } else if (type === 'costs') {
            if (data.value === "") {
                this.editFuellingdelivery.costs = 0;
            } else {
                this.editFuellingdelivery.costs = data.value;
            }
            this.calculateCosts();
        } else if (type === 'amountliter') {
            if (data.value === "") {
                this.editFuellingdelivery.amountliter = 0;
            } else {
                this.editFuellingdelivery.amountliter = data.value;
            }
            this.calculateAmountliter();
            this.calculateamountkWh();
        } else if (type === 'proof') {
            this.editFuellingdelivery.proof = data.value;
        } else if (type === 'comment') {
            this.editFuellingdelivery.comment = data.value;
        }
    }

    ////add edit

    getFuellingdeliveryName(type) {
        if (type === 1) {
            return "Tanklieferung";
        } else if (type === 2) {
            return "Tankkartenabrechnungen";
        } else {
            return "FremdTankungen";
        }
    }

    downloadExcelTable() {
        let chosenYear = this.state.currentSelectedValYear;
        let chosenMonth = this.state.currentSelectedValMonth;

        fetch(config.BASE_URL + "fuellingdeliveries/excel/" + chosenMonth + "/" + chosenYear, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty('link')) {
                        window.open(config.BASE_FILE_URL + json.link);
                    }
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    render() {
        const yearOptions = [];
        let yearArray = [];
        for (let i = 0; i < this.fuellingdeliveries.length; i++) {
            let deliveryDate = new Date(this.fuellingdeliveries[i].date * 1000);
            let deliveryYear = deliveryDate.getFullYear();
            if (!yearArray.includes(deliveryYear))
                yearArray.push(deliveryYear);
        }

        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }
        const IdOptions = [
            {key: 4, text: 'Tanklieferung', value: 1},
            {key: 5, text: 'Tankkarte', value: 2},
            {key: 6, text: 'Fremdtankung', value: 3}
        ];
        const calorificvalue = 9.96;

        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let tableElements = [];
        for (let i = 0; i < locations.length; i++) {

            let kwhSum = 0;
            let amountliterSum = 0;
            let costSum = 0;

            let locationFuellings = this.getAllFromFuellingDeliveryLocation(locations[i].id, this.fuellingdeliveries)
            let showingLocationFuellings = [];
            for (let j = 0; j < locationFuellings.length; j++) {

                let fuellingdeliveriesDate = locationFuellings[j].date;
                let fuellingdeliveriesDateYear = new Date(fuellingdeliveriesDate * 1000).getFullYear();
                let fuellingdeliveriesDateMonth = (new Date(fuellingdeliveriesDate * 1000).getMonth());

                if ((this.state.currentSelectedValYear === fuellingdeliveriesDateYear
                    && fuellingdeliveriesDateMonth.toString() === this.state.currentSelectedValMonth)
                    || this.state.currentSelectedValYear === fuellingdeliveriesDateYear && this.state.currentSelectedValMonth === "12") {

                    showingLocationFuellings.push(<FuellingDeliveryRow key={i + "_" + j}
                                                                       fuellingdelivery={locationFuellings[j]}
                                                                       locationId={locations[i].id}
                                                                       deleteFuellingDelivery={this.deleteFuellingDelivery.bind(this)}
                                                                       editFuellingDelivery={this.editFuellingDelivery.bind(this)}
                    />);


                    if (locationFuellings[j].fuellingdeliveryType === 2 || locationFuellings[j].fuellingdeliveryType === 1) {
                        costSum += locationFuellings[j].costs;
                        kwhSum += locationFuellings[j].amountkWh;
                        amountliterSum += locationFuellings[j].amountliter;
                    } else if (locationFuellings[j].fuellingdeliveryType === 3) {
                        costSum = costSum - locationFuellings[j].costs;
                        kwhSum = kwhSum - locationFuellings[j].amountkWh;
                        amountliterSum = amountliterSum - locationFuellings[j].amountliter;
                    }

                }
            }

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingFuellings ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingFuellings = !this.locations[i].showingFuellings}/>
                    </Table.Cell>
                    {this.edit ?
                        <Table.Cell>
                            <Icon name='plus' color="orange"
                                  onClick={() => this.setState({open: true, editLocationId: locations[i].id})}/>
                        </Table.Cell>
                        :
                        null
                    }
                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(amountliterSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(kwhSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(costSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                </Table.Row>
            );

            if (locations[i].showingFuellings) {
                tableElements.push(showingLocationFuellings);
            }
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Diesel</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>

                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={11}>
                                        <Button value="0"
                                                className={this.state.currentSelectedValMonth === "0" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jan</Button>
                                        <Button value="1"
                                                className={this.state.currentSelectedValMonth === "1" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Feb</Button>
                                        <Button value="2"
                                                className={this.state.currentSelectedValMonth === "2" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mär</Button>
                                        <Button value="3"
                                                className={this.state.currentSelectedValMonth === "3" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Apr</Button>
                                        <Button value="4"
                                                className={this.state.currentSelectedValMonth === "4" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mai</Button>
                                        <Button value="5"
                                                className={this.state.currentSelectedValMonth === "5" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jun</Button>
                                        <Button value="6"
                                                className={this.state.currentSelectedValMonth === "6" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jul</Button>
                                        <Button value="7"
                                                className={this.state.currentSelectedValMonth === "7" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Aug</Button>
                                        <Button value="8"
                                                className={this.state.currentSelectedValMonth === "8" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Sep</Button>
                                        <Button value="9"
                                                className={this.state.currentSelectedValMonth === "9" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Okt</Button>
                                        <Button value="10"
                                                className={this.state.currentSelectedValMonth === "10" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Nov</Button>
                                        <Button value="11"
                                                className={this.state.currentSelectedValMonth === "11" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Dez</Button>
                                        <Button value="12"
                                                className={this.state.currentSelectedValMonth === "12" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Gesamt</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            icon
                            labelPosition='right'
                            positive
                            onClick={() => this.downloadExcelTable()}>
                            Exportieren
                            <Icon name={"external alternate"}/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    {this.edit ? <Table.HeaderCell>Hinzufügen</Table.HeaderCell> : null}
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>Art der Tankung</Table.HeaderCell>
                                    <Table.HeaderCell>Datum</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Menge [l]</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Summe Menge [kWh]</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Kosten [netto]</Table.HeaderCell>
                                    <Table.HeaderCell>Bemerkung</Table.HeaderCell>
                                    <Table.HeaderCell>Nachweis</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Modal centered={false} className={"modal-top"} open={this.state.open} size="small">
                        <Modal.Header>{this.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                        <Modal.Content>
                            <Form error={this.error}>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Karte oder Lieferung</label>
                                        <Dropdown selection placeholder="Tanklieferung" direction='right'
                                                  color='black'
                                                  fluid
                                                  options={IdOptions}
                                                  value={this.editFuellingdelivery.fuellingdeliveryType}
                                                  onChange={this.handleInputChange.bind(this, 'fuellingdeliveryType')}/>
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Datum</label>

                                        <DatePicker
                                            selected={this.editFuellingdelivery.date}
                                            onChange={this.handleChange.bind(this)}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Menge [l]</label>
                                        <NumberFormat value={parseFloat(this.editFuellingdelivery.amountliter)}
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                      decimalScale={2}
                                                      onValueChange={(values, e) => {
                                                          this.handleInputChange('amountliter', null, {value: values.floatValue})
                                                      }}
                                                      allowNegative={true}
                                                      suffix=" l"/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Heizwert</label>
                                        {calorificvalue}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>kWh</label>
                                        {this.editFuellingdelivery.amountkWh}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Kosten [€]</label>
                                        <NumberFormat value={parseFloat(this.editFuellingdelivery.costs)}
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                      decimalScale={2}
                                                      onValueChange={(values, e) => {
                                                          this.handleInputChange('costs', null, {value: values.floatValue})
                                                      }}
                                                      allowNegative={true}
                                                      suffix=" €"/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nachweis</label>
                                        <Form.Input value={this.editFuellingdelivery.proof}
                                                    type='proof'
                                                    onChange={this.handleInputChange.bind(this, 'proof')}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bemerkung</label>
                                        <Form.Input value={this.editFuellingdelivery.comment}
                                                    type='comment'
                                                    onChange={this.handleInputChange.bind(this, 'comment')}/>
                                    </Form.Field>
                                </Form.Group>
                                <Message
                                    error
                                    header='Fehler'
                                    content={this.errorMessage}
                                />
                            </Form>

                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                            <Button
                                positive
                                icon='checkmark'
                                onClick={() => this.checkInputValues(this.state.editLocationId)}
                            />
                        </Modal.Actions>
                    </Modal>
                </Grid.Row>

            </Grid>
        );
    }
}
