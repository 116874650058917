import React from "react";
import {observer} from "mobx-react";
import {Button, Menu} from "semantic-ui-react";
import {removeTokenFromStorage} from "../helper/util";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {checkRole} from "../helper/rolehelper";

@observer
export default class NavigationBar extends React.Component {

    setMenuItems(userRolesFromServer) {
        let items = [];
        if (checkRole(userRolesFromServer, "/diesel")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/diesel")}
                    onClick={() => {
                        history.push("/diesel");
                        this.setState({});
                    }}
                    key="item-diesel">
                    Fahrzeugerfassung
                </Menu.Item>);
        }

        if (checkRole(userRolesFromServer, "/fuellingdelivery")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/fuellingdelivery")}
                    //active={window.location.hash === "#/fuellingdelivery"}
                    onClick={() => {
                        history.push("/fuellingdelivery");
                        this.setState({});
                    }}
                    key="item-fuellingdelivery">
                    Diesel
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/heatingoil")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/heatingoil")}
                    //active={window.location.hash === "#/heatingoil"}
                    onClick={() => {
                        history.push("/heatingoil");
                        this.setState({});
                    }}
                    key="item-heatingoil">
                    Heizöl
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/liquidgas")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/liquidgas")}
                    //active={window.location.hash === "#/strom"}
                    onClick={() => {
                        history.push("/liquidgas");
                        this.setState({});
                    }}
                    key="item-liquidgas">
                    Flüssig-Gas
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/productionkeyfigures")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/productionkeyfigures")}
                    //active={window.location.hash === "#/productionkeyfigures"}
                    onClick={() => {
                        history.push("/productionkeyfigures");
                        this.setState({});
                    }}
                    key="item-productionkeyfigures">
                    Produktions-Kennzahlen
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/acetylen")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/acetylen")}
                    onClick={() => {
                        history.push("/acetylen");
                        this.setState({});
                    }}
                    key="item-acetylen">
                    Acetylen-Gas
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/propan")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/propan")}
                    onClick={() => {
                        history.push("/propan");
                        this.setState({});
                    }}
                    key="item-propan">
                    Propan-Gas
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/strom")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/strom")}
                    //active={window.location.hash === "#/strom"}
                    onClick={() => {
                        history.push("/strom");
                        this.setState({});
                    }}
                    key="item-strom">
                    Strom
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/plausibility")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/plausibility")}
                    onClick={() => {
                        history.push("/plausibility");
                        this.setState({});
                    }}
                    key="item-plausibility">
                    Plausibilität
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/keyfigures")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/keyfigures")}
                    onClick={() => {
                        history.push("/keyfigures");
                        this.setState({});
                    }}
                    key="item-keyfigures">
                    Kennzahlen
                </Menu.Item>);
        }

        if (checkRole(userRolesFromServer, "/production")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/production")}
                    onClick={() => {
                        history.push("/production");
                        this.setState({});
                    }}
                    key="item-production">
                    Produktionserfassung
                </Menu.Item>);
        }
        if (checkRole(userRolesFromServer, "/vehicles")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/vehicles")}
                    //active={window.location.hash === "#/vehicle"}
                    onClick={() => {
                        history.push("/vehicles");
                        this.setState({});
                    }}
                    key="item-vehicle">
                    Fuhrpark
                </Menu.Item>);
        }

        if (checkRole(userRolesFromServer, "/location")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/location")}
                    onClick={() => {
                        history.push("/location");
                        this.setState({});
                    }}
                    key="item-location">
                    Standorte
                </Menu.Item>);
        }

        if (checkRole(userRolesFromServer, "/user-management")) {
            items.push(
                <Menu.Item
                    active={window.location.toString().endsWith("/user-management")}
                    onClick={() => {
                        history.push("/user-management");
                        this.setState({});
                    }}
                    key="item-user">
                    Nutzerverwaltung
                </Menu.Item>);
        } else {
            //console.log(userRolesFromServer)
        }

        return items;
    }

    render() {
        const userMenuItems = this.setMenuItems(userStore.userRolesFromServer);

        return (
            <Menu key="menu" inverted vertical fluid style={{"borderRadius": 0}} className="nav-container">
                <Menu.Item header>
                    <h1>Valet u. Ott</h1>
                </Menu.Item>
                {userMenuItems}

                {
                    userStore.userFromServer !== null ?
                        <Button
                            className={"logout-button"}
                            inverted
                            onClick={() => {
                                removeTokenFromStorage();
                                userStore.user = null;
                                this.setState({})
                                history.push("/admin-login");
                                // window.location.reload();
                            }}>
                            Logout
                        </Button>
                        :
                        ""
                }
            </Menu>
        );
    }
}