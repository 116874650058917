import React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";

import dieselStore from "../stores/dieselStore";
import "react-datepicker/dist/react-datepicker.css";
import {observable} from "mobx";
import {observer} from "mobx-react";

const DEFAULT_ERROR = 'Werte dürfen nicht 0 sein.';
const REFERENCE_ERROR = "Der neue KM/BH Stand muss höher sein als der letzte";
const REFERENCE_2_ERROR = "Der neue KM/BH Stand muss niedriger sein als der danach";
const DATE_ERROR = "Das Datum der neuen Tankung muss neuer sein als das letzte";

@observer
export default class ChangeReferenceStandModal extends React.Component {

    @observable amount = 0;
    @observable vehicle = {
        reference: ""
    };
    @observable open = false;
    @observable error = false;
    @observable errorMessage = DEFAULT_ERROR;

    componentDidMount() {
        this.amount = this.props.amount;
        this.vehicle = this.props.vehicle;

    }

    handleInputChange(type, event, data) {

        let floatValues = /[0-9]+\.?[0-9]{0,2}/;
        if (type === 'amount') {
            if (data.value === "") {
                this.amount = 0;
            } else {
                let filteredValue = data.value.match(floatValues)[0];
                if (!filteredValue.endsWith(".")) {
                    filteredValue = parseFloat(filteredValue);
                }
                this.amount = filteredValue;
            }
        }
    }

    closeInput() {
        this.open = false;
        this.error = false;

    }


    checkEdit() {
        //When editing the amount must be higher as the last fuelling (when existing)
        //but smaller as the one after that (when existing)
        //If Month 12 (year overview) then put it in december
        let amountRef = {
            amount: this.amount,
            year: this.props.year,
            month: this.props.month === "12" ? "11" : this.props.month,
            vehicleId: this.vehicle.id
        };

        this.createReferenceAmount(amountRef);
        this.closeInput();
    }

    createReferenceAmount(amountRef) {
        dieselStore.addNewMonthlyAmount(amountRef);
    }


    render() {

        return (
            <Modal centered={false} className={"modal-top"} open={this.open} size="small"
                   trigger={
                       <p onClick={() => this.open = true}
                          className={"clickable-text"}> {this.props.amount} {this.vehicle.reference}</p>
                   }>

                <Modal.Header>Monatliche Summe</Modal.Header>
                <Modal.Content>
                    <Form error={this.error}>

                        <Form.Field>
                            <label>{this.vehicle.reference.toUpperCase()}-Stand</label>
                            <Form.Input fluid value={this.amount}
                                        onChange={this.handleInputChange.bind(this, 'amount')}/>
                        </Form.Field>
                        <Message
                            error
                            header='Fehler'
                            content={this.errorMessage}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                    <Button
                        positive
                        icon='checkmark'
                        onClick={
                            this.checkEdit.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}