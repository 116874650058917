import React from "react";
import {Dropdown, Form, Message, Modal, Table} from "semantic-ui-react";
import userStore from "../stores/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {checkEdit, checkRole} from "../helper/rolehelper";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NumberFormat from "react-number-format";
import StromMonthRow from "./StromMonthRow";

const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const DATE_ERROR = "Datum falsch gesetzt.";
const TYPE_ERROR = "Falsche Angabe : Tonnen oder m³ wurde nicht angegeben";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";

@observer
export default class StromMonth extends React.Component {
    @observable
    stroms = [];
    @observable
    editStroms = {
        stromType: "",
        date: new Date(),
        amount: 0,
        calorificvalue: 1,
        amountkWh: 0,
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    showingMonths = true

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addstromModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;

    @observable edit = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),

            open: false,
            deleteopen: false,
            delete: true,
            edit: false,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/strom")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/strom");
    }

    componentDidMount() {
    }


//functions for edit
    checkInputValues(locationId, currentmonthmonth, id) {
        //Check if vehicle has strom and check if amounts are right
        if (this.editStroms.length !== 0) {

            let currentYear = this.props.year;
            let currentmonth = currentmonthmonth;
            let monthDate = new Date().setMonth(currentmonth);
            let date = new Date(monthDate).setFullYear(currentYear)
            //Check if strom has a valid type (1, 2 or 3)
            let type = (this.editStroms.stromType);
            if (type !== 1 && type !== 2) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amount = this.editStroms.amount;
            let costs = this.editStroms.costs;
            let proof = this.editStroms.proof;
            let comment = this.editStroms.comment;
            let amountkWh = this.editStroms.amountkWh;
            let calorificvalue = this.editStroms.calorificvalue;
            let id = this.editStroms.id;
            if (amountkWh === "" || costs === "") {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }
            if (this.editable) {
                this.setEdit(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh, id);
            } else {
                this.initiateAddStrom(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh);
            }
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddStrom(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh) {
        this.props.addStrom(this.editStroms, locationId, type, date, proof, comment, amountkWh, costs);

        this.closeInput()
    }

    setEdit(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh, id) {
        this.props.editStroms(this.editstrom, locationId, type, date, proof, comment, amountkWh, id, costs);

        this.closeInput()
    }

    handleChange(date) {
        if (this.isEditModal === false) {

            if (date.getMonth !== this.props.month) {
                this.editStroms.date = date;
            } else {
                this.setState({
                    ...this.state,
                    error: true

                });
                this.errorMessage = DATE_ERROR;
                return;
            }
        }
    }

    closeInput() {
        if (this.isEditModal === true) {
            this.isEditModal = true;
            this.addstromModalOpen = false;
            this.deleteModalOpen = false;
            this.editStroms = {
                stromType: this.props.strom.stromType,
                date: new Date(this.props.strom.date * 1000),
                amount: this.props.strom.amount,
                calorificvalue: this.props.strom.calorificvalue,
                amountkWh: this.props.strom.amountkWh,
                costs: this.props.strom.costs,
                proof: this.props.strom.proof,
                locationId: this.props.locationId,
                comment: this.props.strom.comment,
                id: this.props.strom.id
            };
        } else {
            this.addstromModalOpen = false;
            this.isEditModal = true;
            this.deleteModalOpen = false;
            this.editStroms = {
                stromType: "",
                date: new Date(),
                amount: "",
                calorificvalue: 1,
                amountkWh: "",
                costs: "",
                proof: "",
                comment: ""
            };
        }
        this.setState({
            ...this.state,
            open: false
        })
        this.error = false;
    }

    handleInputChange(type, event, data) {

        this.editStroms.calorificvalue = 1;

        if (type === 'stromType') {
            this.editStroms.stromType = data.value;
        } else if (type === 'date') {
            this.editStroms.date = data.value;

        } else if (type === 'amount') {
            if (data.value === "") {
                this.editStroms.amount = 0;
            } else {
                let floatValues = /[0-9]+\.?[0-9]{0,2}/;
                let filteredValue = data.value.match(floatValues)[0];
                if (!filteredValue.endsWith(".")) {
                    filteredValue = parseFloat(filteredValue);
                }
                this.editStroms.amount = filteredValue;
            }
        } else if (type === 'amountkWh') {
            if (data.value === "") {
                this.editStroms.amountkWh = 0;
            } else {
                this.editStroms.amountkWh = data.value;
            }
        } else if (type === 'costs') {
            if (data.value === "") {
                this.editStroms.costs = 0;
            } else
                this.editStroms.costs = data.value;
        } else if (type === 'proof') {
            this.editStroms.proof = data.value;
        } else if (type === 'comment') {
            this.editStroms.comment = data.value;
        }
        this.editStroms.locationId = this.props.locationId;
    }


    render() {
        if (typeof this.props.strom.date === "undefined") {
            this.editStroms.date = new Date();
            this.editable = false;
        }
        let month = "";
        switch (this.props.month) {
            case 0:
                month = "Januar";
                break;
            case 1:
                month = "Februar";
                break;
            case 2:
                month = "März";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "Mai";
                break;
            case 5:
                month = "Juni";
                break;
            case 6:
                month = "Juli";
                break;
            case 7:
                month = "August";
                break;
            case 8:
                month = "September";
                break;
            case 9:
                month = "Oktober";
                break;
            case 10:
                month = "November";
                break;
            case 11:
                month = "Dezember";
                break;
        }

        const IdOptions = [
            {key: 4, text: 'Abrechnung', value: 1},
            {key: 5, text: 'Weiterberechnung', value: 2}
        ];


        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let rows = [];
        let amountMonth = 0;
        let amountCostMonth = 0;

        for (let j = 0; j < this.props.strom.length; j++) {
            if (typeof this.props.strom[j].date === "undefined") {
            } else {
                amountMonth += (this.props.strom[j].amountkWh);
                amountCostMonth += (this.props.strom[j].costs);
            }
        }
        rows.push(<Table.Row key={0}>
                {this.edit ?
                    <Table.Cell>
                        <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                               trigger={
                                   <Icon name='plus' color="orange"
                                         onClick={() => this.setState({
                                             open: true,
                                             editable: false,
                                             isEditModal: false
                                         })}/>
                               }>
                            <Modal.Header>Hinzufügen: {this.props.location.caompanyname} {month} - {this.props.year}</Modal.Header>
                            <Modal.Content>
                                <Form error={this.error}>
                                    <Form.Group widths='equal'>

                                        <Form.Field>
                                            <label>Karte oder Lieferung</label>
                                            <Dropdown selection placeholder="Abrechnung" direction='right'
                                                      color='black'
                                                      fluid
                                                      options={IdOptions}
                                                      value={this.editStroms.stromType}
                                                      onChange={this.handleInputChange.bind(this, 'stromType')}/>
                                        </Form.Field>


                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Menge [kWh]</label>
                                            <NumberFormat value={parseFloat(this.editStroms.amountkWh)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('amountkWh', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          suffix=" kWh"/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Kosten [€]</label>
                                            <NumberFormat value={parseFloat(this.editStroms.costs)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('costs', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          suffix=" €"/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nachweis</label>
                                            <Form.Input value={this.editStroms.proof}
                                                        type='proof'
                                                        onChange={this.handleInputChange.bind(this, 'proof')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bemerkung</label>
                                            <Form.Input value={this.editStroms.comment}
                                                        type='comment'
                                                        onChange={this.handleInputChange.bind(this, 'comment')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={() => this.checkInputValues(this.props.locationId, this.props.month, this.props.strom.id)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    {month}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        minimumFractionDigits: 2
                    }).format(amountMonth.toFixed(2))}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                    }).format(amountCostMonth.toFixed(2))}
                </Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
            </Table.Row>
        );
        for (let k = 0; k < this.props.strom.length; k++) {
            if (typeof this.props.strom[k].date === "undefined") {
            } else {
                rows.push(<StromMonthRow key={"_" + k}
                                         strom={this.props.strom[k]}
                                         locationId={this.props.locationId}
                                         deleteStrom={this.props.deleteStrom}
                                         editStroms={this.props.editStroms}
                                         addStrom={this.props.addStrom}
                                         location={this.props.location}
                                         locationStroms={this.props.locationStroms}
                                         month={this.props.month}
                                         year={this.props.year}
                />)
            }
        }
        if (this.state.openStromstate) {
        }
        return (
            rows

        );
    }
}