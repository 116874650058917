import React from "react";
import {Dropdown, Form, Message, Modal, Table} from "semantic-ui-react";
import userStore from "../stores/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {checkEdit, checkRole} from "../helper/rolehelper";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NumberFormat from "react-number-format";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import ProductionMonthRow from "./ProductionMonthRow";

const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const DATE_ERROR = "Datum falsch gesetzt.";
const TYPE_ERROR = "Falsche Angabe : Tonnen oder m³ wurde nicht angegeben";
const TYPE_ERROR2 = "Falsche Angabe : Art des Produkts wurde nicht angegeben";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";

@observer
export default class ProductionMonth extends React.Component {
    @observable
    productions = [];
    @observable
    editProductions = {
        product: 1,
        productionType: 1,
        factorvalue: 1,
        transported: 0,
        date: new Date(),
        amount: 0,
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    showingMonths = true

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addproductionModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;

    @observable edit = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),

            open: false,
            deleteopen: false,
            delete: true,
            edit: false,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/production")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/production");
    }

    componentDidMount() {
        this.editProductions.product = 1;
        this.editProductions.factorvalue = 1;
        this.setFactorValue();
    }


    isFactorDisabled() {
        return this.editProductions.productionType !== 1 || this.editProductions.product !== 1
    }


//functions for edit
    checkInputValues(locationId, currentmonthmonth, id) {
        if (this.editProductions.length !== 0) {
            let currentYear = this.props.year;
            let currentmonth = currentmonthmonth;
            let monthDate = new Date().setMonth(currentmonth);
            let date = new Date(monthDate).setFullYear(currentYear)

            //Check if a valid type (1 or 2 or 3)
            let product = (this.editProductions.product);

            if (product !== 1 && product !== 2) {

                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }
            //Check if a valid type (1, 2, 3 or 4)
            let type = (this.editProductions.productionType);

            if (type !== 1 && type !== 2 && type !== 3 && type !== 4) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR2;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amount = this.editProductions.amount;
            let proof = this.editProductions.proof;
            let comment = this.editProductions.comment;
            let id = this.editProductions.id;
            let factorvalue = this.editProductions.factorvalue;
            let transported = this.editProductions.transported;

            /*  if (amount === "" || amount === 0 ) {
                  this.error = true;
                  this.errorMessage = DEFAULT_ERROR;
                  console.log("value error");
                  console.log(this.error);
                  return;
              }else*/
            if (factorvalue === 0 && type === 1) {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }

            this.initiateAddProduction(locationId, type, date, amount, proof, comment, product, factorvalue, transported);

        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddProduction(locationId, type, date, amount, proof, comment, product, factorvalue, transported) {
        this.props.addProduction(this.editProductions, locationId, type, date, amount, proof, comment, product, factorvalue, transported);

        this.closeInput()
    }

    handleChange(date) {
        if (this.isEditModal === false) {

            if (date.getMonth !== this.props.month) {
                this.editProductions.date = date;
            } else {
                this.setState({
                    ...this.state,
                    error: true

                });
                this.errorMessage = DATE_ERROR;
                return;
            }

        }
    }

    closeInput() {

        this.addproductionModalOpen = false;
        this.isEditModal = true;
        this.deleteModalOpen = false;
        this.editProductions = {
            product: 1,
            date: new Date(),
            amount: 0,
            proof: "",
            factorvalue: this.editProductions.factorvalue,
            comment: "",
            transported: 0,
            productionType: 1
        };
        this.setState({
            ...this.state,
            open: false
        })
        this.error = false;
    }

    handleInputChange(type, event, data) {
        if (type === 'product') {
            this.editProductions.product = data.value;
            this.setFactorValue();
        } else if (type === 'productionId') {
            this.productionId = data.value;
        } else if (type === 'productionType') {
            this.editProductions.productionType = data.value;
            this.setFactorValue();
        } else if (type === 'factorvalue') {
            this.editProductions.factorvalue = data.value;
        } else if (type === 'transported') {
            this.editProductions.transported = this.editProductions.transported === 1 ? 0 : 1;
        } else if (type === 'date') {
            this.editProductions.date = data.value;
        } else if (type === 'amount') {
            if (data.value === "") {
                this.editProductions.amount = 0;
            } else {
                this.editProductions.amount = data.value;
            }
        } else if (type === 'proof') {
            this.editProductions.proof = data.value;
        } else if (type === 'comment') {
            this.editProductions.comment = data.value;
        }
        this.editProductions.locationId = this.props.locationId;
    }


    getKwhPerMonthAndLocation(year, month, productions) {
        let sum = 0;
        for (let i = 0; i < productions.length; i++) {
            let deliveryDate = new Date(productions[i].date * 1000);
            if (deliveryDate.getFullYear() === year && deliveryDate.getMonth() === month) {
                sum += (productions[i].amount);
            }

        }
        return sum;
    }

    getAllKwHFromProductionsMonth(locationId, year, month, productions,) {
        let sum = 0;
        if (locationId) {
            sum += this.getKwhPerMonthAndLocation(this.state.currentSelectedValYear, month, productions);
        }
        return sum;
    }

    setFactorValue() {
        if (this.editProductions.productionType === 1) {
            this.editProductions.factorvalue = 3;

            if (this.props.preMonthProduction.length === 0) {
                return;
            }

            for (let j = 0; j < this.props.preMonthProduction.length; j++) {
                if (typeof this.props.preMonthProduction[j].date !== "undefined" && typeof this.props.preMonthProduction[j].factorvalue !== "undefined") {
                    if (this.props.preMonthProduction[j].productionType === 1) {
                        this.editProductions.factorvalue = this.props.preMonthProduction[j].factorvalue;
                        break;
                    }

                }
            }


        } else if (this.editProductions.productionType === 2) {
            this.editProductions.factorvalue = 0;
        } else if (this.editProductions.productionType === 3) {
            this.editProductions.factorvalue = 0;
        } else if (this.editProductions.productionType === 4) {
            this.editProductions.factorvalue = 0;
        }
    }

    render() {
        if (typeof this.props.production.date === "undefined") {
            this.editProductions.date = new Date();
            this.editable = false;
        }
        let month = "";
        switch (this.props.month) {
            case 0:
                month = "Januar";
                break;
            case 1:
                month = "Februar";
                break;
            case 2:
                month = "März";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "Mai";
                break;
            case 5:
                month = "Juni";
                break;
            case 6:
                month = "Juli";
                break;
            case 7:
                month = "August";
                break;
            case 8:
                month = "September";
                break;
            case 9:
                month = "Oktober";
                break;
            case 10:
                month = "November";
                break;
            case 11:
                month = "Dezember";
                break;
        }


        const IdOptions = [
            {key: 4, text: 'Tonnen (Splitt/Kies)', value: 1},
            {key: 5, text: 'm³ (Beton)', value: 2}
        ];
        const TonnenOptions = [
            {key: 4, text: 'Splitt', value: 1},
            {key: 5, text: 'Kies', value: 2}
        ];
        const BetonOptions = [
            {key: 4, text: 'gepumpt', value: 3},
            {key: 5, text: 'produziert', value: 4}
        ];


        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let rows = [];
        let amountMonth = 0;
        //let defaultSplittfactorvalue = this.editProductions.factorvalue;

        for (let j = 0; j < this.props.production.length; j++) {
            if (typeof this.props.production[j].date === "undefined") {
            } else {
                amountMonth += (this.props.production[j].amount);
            }
        }

        rows.push(<Table.Row key={0}>
                {this.edit ?
                    <Table.Cell>
                        <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                               trigger={
                                   <Icon name='plus' color="orange"
                                         onClick={() => this.setState({
                                             open: true,
                                             editable: false,
                                             isEditModal: false
                                         })}/>
                               }>
                            <Modal.Header>Hinzufügen</Modal.Header>
                            <Modal.Content>
                                <Form error={this.error}>
                                    <Form.Group widths='equal'>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Menge</label>
                                            <NumberFormat value={parseFloat(this.editProductions.amount)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('amount', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Tonnen oder m³</label>
                                            <Dropdown selection placeholder="Tonnen" direction='right'
                                                      color='black'
                                                      fluid
                                                      options={IdOptions}
                                                      value={this.editProductions.product}
                                                      onChange={this.handleInputChange.bind(this, 'product')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Faktor</label>
                                            <NumberFormat value={parseFloat(this.editProductions.factorvalue)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('factorvalue', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          disabled={this.isFactorDisabled()}/>
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        {this.editProductions.product === 1 ?
                                            <Form.Field>
                                                <label>Kies/Splitt</label>
                                                <Dropdown selection placeholder="Splitt" direction='right'
                                                          color='black'
                                                          fluid
                                                          options={TonnenOptions}
                                                          value={this.editProductions.productionType}
                                                          onChange={this.handleInputChange.bind(this, 'productionType')}/>
                                            </Form.Field>
                                            :
                                            <Form.Field>
                                                <label>gepumpt/produziert</label>
                                                <Dropdown selection placeholder="gepumpt" direction='right'
                                                          color='black'
                                                          fluid
                                                          options={BetonOptions}
                                                          value={this.editProductions.productionType}
                                                          onChange={this.handleInputChange.bind(this, 'productionType')}/>
                                            </Form.Field>}
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nachweis</label>
                                            <Form.Input value={this.editProductions.proof}
                                                        type='proof'
                                                        onChange={this.handleInputChange.bind(this, 'proof')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bemerkung</label>
                                            <Form.Input value={this.editProductions.comment}
                                                        type='comment'
                                                        onChange={this.handleInputChange.bind(this, 'comment')}/>
                                        </Form.Field>

                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Transportiert</label>
                                            <Checkbox defaultChecked={this.editProductions.transported === 1}
                                                      value={this.editProductions.transported}
                                                      onChange={this.handleInputChange.bind(this, 'transported')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={() => this.checkInputValues(this.props.locationId, this.props.month, this.props.production.id)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    {month}
                </Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell>
                    {amountMonth}
                </Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>

            </Table.Row>
        );
        for (let k = 0; k < this.props.production.length; k++) {
            if (typeof this.props.production[k].date === "undefined") {
            } else {

                rows.push(<ProductionMonthRow key={"_" + k}
                                              production={this.props.production[k]}
                                              defaultSplittfactorvalueflag={this.isFactorDisabled() ? 0 : 1}
                                              defaultSplittfactorvalue={this.editProductions.factorvalue}
                                              locationId={this.props.locationId}
                                              deleteProduction={this.props.deleteProduction}
                                              editProductions={this.props.editProductions}
                                              addProduction={this.props.addProduction}
                                              locationProductions={this.props.locationProductions}
                                              month={this.props.month}
                                              year={this.props.year}
                />)
            }
        }
        if (this.state.openFuellingsstate) {
            //rows.push(productionMonthRows)
        }
        return (
            rows

        );
    }
}