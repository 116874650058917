import React from "react";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {
    Accordion,
    Button,
    Checkbox,
    Dimmer,
    Form,
    Grid, Icon,
    Loader,
    Message,
    Modal,
    Table,
    TableHeader
} from "semantic-ui-react";
import {getFullUserRole, isEdit} from "../helper/rolehelper";
import userStore from "../stores/userStore";

const defaultErrorMessage = 'Alle Felder müssen befüllt werden und die Passwörter übereinstimmen.';
const dublicateErrorMessage = 'Login und Email müssen einzigartig sein.';

const rolePages = [
    {role: "Diesel", name: "Fahrzeugerfassung"},
    {role: "FuellingDelivery", name: "Tanklieferungen"},
    {role: "Plausibility", name: "Plausibilität"},
    {role: "KeyFigures", name: "Kennzahlen"},
    {role: "HeatingOil", name: "Heizöl"},
    {role: "LiquidGas", name: "Flüssig-Gas"},
    {role: "Strom", name: "Strom"},
    {role: "ProductionKeyFigures", name: "Produktions-Kennzahlen"},
    {role: "Acetylen", name: "Acetylen-Gas"},
    {role: "Propan", name: "Propan-Gas"},
    {role: "Production", name: "Produktionserfassung"}
];

@observer
export default class UserPage extends React.Component {


    @observable
    users = [];

    @observable
    addUserModalOpen = false;

    @observable
    loadingNewUser = false;

    @observable
    errorMessage = defaultErrorMessage;

    @observable
    errorNewUser = false;

    @observable
    user = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordRepeat: "",
        admin: false,
        roles: [],
        rolesEdit: false
    };

    @observable
    locations = [];

    @observable
    isEditModal = false;

    @observable
    deleteModalOpen = false;

    @observable
    deleteUserId = null;

    @observable
    tempfirstName = "";

    @observable
    activeIndex = 0;

    constructor(props) {
        super(props);
        let userRole = getFullUserRole(userStore.userRolesFromServer, "User");
        this.edit = isEdit(userRole);
    }

    componentWillMount() {
        fetch(config.BASE_URL + "users", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.users = json;
                });

            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
        this.getLocations()
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json;
                    this.setRoles()

                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    setRoles() {
        let roles = [];
        let locations = [];

        for (let i = 0; i < this.locations.length; i++) {
            locations.push({
                location: this.locations[i].caompanyname,
                id: this.locations[i].id,
                set: false
            })
        }

        for (let i = 0; i < rolePages.length; i++) {
            roles.push({
                page: rolePages[i].name,
                role: rolePages[i].role,
                edit: false,
                locations: locations
            })
        }

        this.user.roles = roles;
    }

    setEdit() {

        if (this.isEditModal) {
            this.editUser();
        } else {
            this.addUser();
        }
        this.closeInput()

    }

    openAddModal() {
        this.addUserModalOpen = true;
    }

    closeInput() {
        this.addUserModalOpen = false;
        this.isEditModal = false;
        this.deleteModalOpen = false;
        this.user = {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            passwordRepeat: "",
            admin: false,
            roles: [],
            id: 0
        };
        this.setRoles();
        this.loadingNewUser = false;
        this.errorMessage = defaultErrorMessage;
    }

    handleInputChange(type, event, data) {
        if (type === 'firstName') {
            this.user.firstName = data.value;
        } else if (type === 'lastName') {
            this.user.lastName = data.value;
        } else if (type === 'email') {
            this.user.email = data.value;
        } else if (type === 'username') {
            this.user.username = data.value;
        } else if (type === 'password') {
            this.user.password = data.value;
        } else if (type === 'passwordRepeat') {
            this.user.passwordRepeat = data.value;
        } else if (type === 'admin') {
            this.user.admin = !this.user.admin;
        } else if (type === 'rolesEdit') {
            this.user.rolesEdit = !this.user.rolesEdit;
        }
    }

    isUnique() {
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].username === this.user.username ||
                this.users[i].email === this.user.email) {
                this.errorMessage = dublicateErrorMessage;
                return false;
            }
        }
        return true;
    }

    addUser() {
        //Check first if everything is ok
        if (this.user.password !== this.user.passwordRepeat ||
            this.user.password.length === 0 ||
            this.user.firstName.length === 0 ||
            this.user.lastName.length === 0 ||
            this.user.email.length === 0 ||
            !this.isUnique()
        ) {
            this.errorNewUser = true;
            return;
        }

        this.loadingNewUser = true;

        this.getActiveRoles(this.user.roles)
        fetch(config.BASE_URL + "users", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                username: this.user.username,
                email: this.user.email,
                password: this.user.password,
                admin: this.user.admin,
                roles: this.user.admin ? [] : this.getActiveRoles(this.user.roles)
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.closeInput();
                this.componentWillMount();

            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );

    }

    editUser() {
        fetch(config.BASE_URL + "users/", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                username: this.user.username,
                email: this.user.email,
                password: this.user.password,
                admin: this.user.admin,
                roles: this.user.admin ? [] : this.getActiveRoles(this.user.roles),
                id: this.user.id
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentWillMount();
            } else {
                console.log(response.status)
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    deleteUser() {
        fetch(config.BASE_URL + "users/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: this.deleteUserId})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentWillMount();
                this.deleteUserId = null;
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
        this.closeInput();
    }

    openEditUserModal(user) {
        this.isEditModal = true;
        this.setEditRoles(user.roles);
        this.user.firstName = user.firstName;
        this.user.lastName = user.lastName;
        this.user.email = user.email;
        /* Get roles of the user as an object array
         * Looks like:
         * [role: <STRING>, edit: <BOOL>, locationsIds: <STRING ARRAY>}
         */
        let userRoles = this.getUserRoles(user.roles);
        //Get all available roles and locations
        let tmpRoles = this.user.roles;
        //Iterate through all roles to set the user roles
        for (let i = 0; i < tmpRoles.length; i++) {
            for(let j = 0; j < userRoles.length; j++){
                if(tmpRoles[i].role === userRoles[j].role){
                    //if has the same role
                    //set edit
                    tmpRoles[i].edit = userRoles[j].edit;
                    //iterate through locations
                    for(let k = 0; k < tmpRoles[i].locations.length; k++){
                        if(userRoles[j].locationsIds.includes(tmpRoles[i].locations[k].id.toString())){
                            tmpRoles[i].locations[k].set = true;
                        }else{
                            tmpRoles[i].locations[k].set = false;
                        }
                    }
                }
            }
        }
        this.user.roles = tmpRoles;
        let canEdit = false;
        if (user.roles.length > 0) {
            canEdit = user.roles.includes("edit");
        }

        this.user.rolesEdit = canEdit;
        this.user.username = user.username;
        this.user.admin = user.admin;
        this.user.id = user.id;
    }

    setEditRoles(userRoles) {
        let userRolesArray = userRoles.split(",");

        let roles = [];
        let locations = [];

        for (let i = 0; i < this.locations.length; i++) {
            locations.push({
                location: this.locations[i].caompanyname,
                id: this.locations[i].id,
                set: false
            })
        }

        for (let i = 0; i < rolePages.length; i++) {
            let settedLocation = this.setRoleLocations(locations, userRolesArray, rolePages[i].role);
            let settedEdit = this.setLocationRoleEdit(locations, userRolesArray, rolePages[i].role);
            roles.push({
                page: rolePages[i].name,
                role: rolePages[i].role,
                edit: settedEdit,
                locations: settedLocation
            })
        }

        this.user.roles = roles;
    }

    setLocationRoleEdit(locations, roles, pageName) {
        for (let i = 0; i < locations.length; i++) {
            for (let j = 0; j < roles.length; j++) {
                if (roles[j].includes(pageName)) {
                    return roles[j].includes("edit");
                }
            }
        }
    }

    setRoleLocations(locations, roles, pageName) {
        let roleLocations = [];
        for (let i = 0; i < locations.length; i++) {
            let set = false;
            for (let j = 0; j < roles.length; j++) {
                if (roles[j].includes(pageName) && roles[j].includes(locations[i].id)) {
                    set = true;
                    break;
                }
            }

            roleLocations.push({
                location: locations[i].location,
                id: locations[i].id,
                set: set
            })

        }
        return roleLocations;
    }

    getUserRoles(rolesString) {
        let roleObjects = [];

        let roles = rolesString.split(",");
        for (let i = 0; i < roles.length; i++) {
            let role = roles[i];
            let parts = role.split("_");
            //check if for this role a object already exists
            let exists = false;
            for(let j = 0; j < roleObjects.length; j++){
                if(roleObjects[j].role === parts[0]){
                    exists = true;
                    roleObjects[j].locationsIds.push(parts[2]);
                    break;
                }
            }
            if(!exists){
                let locationsIds = [];
                locationsIds.push(parts[2]);
                roleObjects.push({
                    role: parts[0],
                    edit: parts[1] === "edit",
                    locationsIds: locationsIds
                })
            }
        }

        return roleObjects;
    }

    openDeleteUserModal(userId) {
        this.deleteUserId = userId;
        this.deleteModalOpen = true;
    }

    getActiveRoles(roles) {
        let activeRoles = [];

        for (let i = 0; i < roles.length; i++) {
            for (let j = 0; j < roles[i].locations.length; j++) {
                if (roles[i].locations[j].set) {
                    let role = roles[i].role + "_" +
                        (roles[i].edit ? "edit" : "see") + "_" +
                        roles[i].locations[j].id;
                    activeRoles.push(role);
                }
            }
        }
        return activeRoles;
    }

    setUserLocationRole(index, roleIndex) {
        this.user.roles[roleIndex].locations[index].set = !this.user.roles[roleIndex].locations[index].set;
    }

    setRolePageEdit(index) {
        this.user.roles[index].edit = !this.user.roles[index].edit;
    }

    render() {
        let tableElements = this.users.map((user, index) =>
            <Table.Row key={index}>
                <Table.Cell>
                    {user.firstName}
                </Table.Cell>
                <Table.Cell>
                    {user.lastName}
                </Table.Cell>
                <Table.Cell>
                    {user.email}
                </Table.Cell>
                <Table.Cell>
                    {user.username}
                </Table.Cell>
                <Table.Cell>
                    {user.roles === "admin" ?
                        <Icon name={"check"}/>
                        :
                        null
                    }

                </Table.Cell>
                <Table.Cell>
                    <Button className="ui basic icon negative button"
                            onClick={this.openDeleteUserModal.bind(this, user.id)}>
                        <i aria-hidden="true"
                           className="remove icon"/>
                    </Button>
                    <Button className="ui basic icon positive button"
                            onClick={this.openEditUserModal.bind(this, user)}>
                        <i aria-hidden="true"
                           className="edit icon"/>
                    </Button>
                </Table.Cell>
            </Table.Row>
        );


        let roleAccordion = [];

        for (let i = 0; i < this.user.roles.length; i++) {
            let role = this.user.roles[i];

            let title = <Accordion.Title key={"H" + i} active={this.activeIndex === i}
                                         onClick={() => this.activeIndex = i}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            {role.page}
                        </Grid.Column>
                        <Grid.Column width={5} className={"text-end"}>
                            <Checkbox checked={role.edit}
                                      onChange={this.setRolePageEdit.bind(this, i)}
                                      label={"Bearbeiten"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Title>;
            roleAccordion.push(title);

            let locationChoser = this.user.roles[i].locations.map((location, index) =>
                <Grid.Row key={index} columns={2}>
                    <Grid.Column width={1}>
                        <Checkbox checked={location.set}
                                  onChange={this.setUserLocationRole.bind(this, index, i)}
                                  label={location.location}
                        />
                    </Grid.Column>
                </Grid.Row>);

            let content = <Accordion.Content key={"C" + i} active={this.activeIndex === i}>
                {locationChoser}
            </Accordion.Content>
            roleAccordion.push(content)

        }


        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Nutzerverwaltung</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button circular color='blue' icon='add' floated={"left"}
                                onClick={this.openAddModal.bind(this)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell>Vorname</Table.HeaderCell>
                                    <Table.HeaderCell>Nachname</Table.HeaderCell>
                                    <Table.HeaderCell>E-Mail</Table.HeaderCell>
                                    <Table.HeaderCell>Login</Table.HeaderCell>
                                    <Table.HeaderCell>Admin</Table.HeaderCell>
                                    <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Modal open={this.addUserModalOpen || this.isEditModal} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>{this.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                            <Modal.Content scrolling>
                                <Dimmer active={this.loadingNewUser} inverted>
                                    <Loader>Loading</Loader>
                                </Dimmer>
                                <Form error={this.errorNewUser}>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Vorname</label>
                                            <Form.Input fluid value={this.user.firstName}
                                                        onChange={this.handleInputChange.bind(this, 'firstName')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Nachname</label>
                                            <Form.Input fluid value={this.user.lastName}
                                                        onChange={this.handleInputChange.bind(this, 'lastName')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Login</label>
                                            <Form.Input fluid value={this.user.username}
                                                        onChange={this.handleInputChange.bind(this, 'username')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>E-Mail</label>
                                            <Form.Input fluid value={this.user.email}
                                                        type='email'
                                                        onChange={this.handleInputChange.bind(this, 'email')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    {this.isEditModal ? <div></div> :
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>Passwort</label>
                                                <Form.Input fluid value={this.user.password}
                                                            type='password'
                                                            onChange={this.handleInputChange.bind(this, 'password')}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Passwort wiederholen</label>
                                                <Form.Input fluid value={this.user.passwordRepeat}
                                                            type='password'
                                                            onChange={this.handleInputChange.bind(this, 'passwordRepeat')}/>
                                            </Form.Field>
                                        </Form.Group>}
                                    <Form.Field>
                                        <label>Rechte</label>
                                        <Accordion styled>
                                            {roleAccordion}
                                        </Accordion>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Admin</label>
                                        <Checkbox checked={this.user.admin}
                                                  onChange={this.handleInputChange.bind(this, 'admin')}/>
                                    </Form.Field>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.setEdit.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                    <Grid.Column>
                        <Modal open={this.deleteModalOpen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>Löschen</Modal.Header>
                            <Modal.Content>
                                Sicher löschen?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.deleteUser.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}