import React from "react";
import {Table} from "semantic-ui-react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {checkEdit} from "../helper/rolehelper";
import userStore from "../stores/userStore";

@observer
export default class DieselFuellingRow extends React.Component {

    @observable edit = false;

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        let user = userStore.userRolesFromServer;
        this.edit = checkEdit(user, "/diesel");
    }

    getClassNameForAVG(avgConsumption) {
        if (avgConsumption >= this.props.expect_consumption * 1.5) {
            return "high-average"
        } else if (avgConsumption >= this.props.expect_consumption * 1.3) {
            return "mid-average"
        }
        return "";
    }

    render() {

        let date = new Date(this.props.fuelling.date * 1000);

        const dieselfactor = 9.96;
        let kwh = dieselfactor * this.props.fuelling.amountltr;
        let kwhprecision = kwh.toFixed(2);

        let avgConsumption = this.props.fuelling.amountltr / this.props.referenceDiff;


        let locationName;
        //if (this.props.variableVehicleVisible) {
        for (let i = 0; i < this.props.locations.length; i++) {
            if (this.props.locations[i].id === this.props.fuelling.location) {
                locationName = this.props.locations[i].caompanyname
            }
        }
        //}

        return (
            <Table.Row className="color-sub-row">
                <Table.Cell/>
                {this.edit ?
                    <Table.Cell>
                        {this.props.editModal}
                    </Table.Cell>
                    :
                    null}
                {this.edit ?
                    <Table.Cell>
                        {this.props.deleteModal}
                    </Table.Cell>
                    :
                    null}
                <Table.Cell/>
                <Table.Cell>
                    {date.toLocaleDateString()}
                </Table.Cell>
                {this.props.variableVehicleVisible && this.props.vehicle.variable ?
                    <Table.Cell>
                        {locationName}
                    </Table.Cell>
                    :
                    <Table.Cell>
                        {locationName}
                    </Table.Cell>}
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.props.fuelling.amountreference)}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(kwhprecision)}
                </Table.Cell>
                <Table.Cell>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.props.fuelling.amountltr)}
                </Table.Cell>
                <Table.Cell className={this.getClassNameForAVG(avgConsumption)}>
                    {new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(avgConsumption.toFixed(2))} L/{this.props.reference}
                </Table.Cell>
            </Table.Row>
        );
    }

}