import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    removeTokenFromStorage,
    getTokenFromLocalStorage
} from "../helper/util";
import history from "../helper/browserHistory"

class DieselStore {
    @observable dieselfuellings = [];
    @observable loading = false;
    @observable error = false;
    @observable fetching = false;
    @observable locations = [];


    @action fetchDieselfuellings() {
        this.fetching = true;
        //console.log("FETCHING");
        return fetch(config.BASE_URL + "vehicles/withFuelling", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    //console.log(json);
                    this.fetching = false;
                    this.dieselfuellings = json;
                });

            } else {
                this.fetching = false;
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );

    }

    @action fetchLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                        this.locations = json
                });
            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    @action registerNewDieselfuellings(dieselfuellings) {
        if (dieselfuellings === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "dieselfuellings", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify(dieselfuellings)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.loading = false;
                            this.fetchDieselfuellings();
                        });

                    } else {
                        //TODO: Alert?
                        console.log("TODO ALERT");
                        this.loading = false;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }

    }

    @action editDieselfuellings(dieselfuelling) {
        if (dieselfuelling === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "dieselfuellings", {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify(dieselfuelling)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.loading = false;
                            this.fetchDieselfuellings();
                        });

                    } else {
                        //TODO: Alert?
                        console.log("TODO ALERT");
                        this.loading = false;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }
    }

    @action deleteDieselFuellings(fuelling) {
        fetch(config.BASE_URL + "dieselfuellings/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(fuelling)
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.loading = false;
                this.fetchDieselfuellings();
            } else {
                console.log(response.status);
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }


    @action addNewMonthlyAmount(amount) {
        if (amount === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "vehicles/monthly_amount", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify(amount)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.loading = false;
                            this.fetchDieselfuellings();
                        });

                    } else {
                        //TODO: Alert?
                        console.log("TODO ALERT");
                        this.loading = false;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }
    }
}

const store = new DieselStore();

export default store;