import React from "react";
import {Dropdown, Form, Message, Modal, Table} from "semantic-ui-react";
import userStore from "../stores/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {checkEdit, checkRole} from "../helper/rolehelper";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NumberFormat from "react-number-format";

const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";
const DATE_ERROR = "Datum falsch gesetzt.";
const TYPE_ERROR = "Falsche Angabe : Tonnen oder m³ wurde nicht angegeben";
const TYPE_ERROR2 = "Falsche Angabe : Art des Produkts wurde nicht angegeben";
const LOCATION_ERROR = "Falsche Angabe : Ort wurde nicht angegeben oder Wert ist falsch";

@observer
export default class StromMonthRow extends React.Component {

    @observable
    stroms = [];
    @observable
    editStrom = {
        stromType: "",
        date: new Date(),
        amount: 0,
        calorificvalue: 1,
        amountkWh: 0,
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };
    @observable
    error = false;

    @observable
    productionId;

    @observable
    isEditModal = false;

    @observable
    addproductionModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable edit = true;
    @observable adminedit = false;


    constructor(props) {

        super(props);

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/strom")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/strom");

        this.state = {
            open: false,
            deleteopen: false,
            delete: true,
            edit: true
        };

        this.editable = false;
        if (typeof this.props.strom.date === "undefined") {
            this.editStrom.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }
    }

    componentDidMount() {

        if (typeof this.props.strom.date === "undefined") {
            this.editStrom.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }
    }


    //functions for edit
    checkInputValues(locationId, currentmonthmonth, id) {
        //Check if vehicle has strom and check if amounts are right
        if (this.editStrom.length !== 0) {

            let currentYear = this.props.year;
            let currentmonth = currentmonthmonth;
            let monthDate = new Date().setMonth(currentmonth);
            let date = new Date(monthDate).setFullYear(currentYear)
            //Check if strom has a valid type (1, 2 or 3)
            let type = (this.editStrom.stromType);
            if (type !== 1 && type !== 2) {
                this.error = true;
                console.log(this.error);
                this.errorMessage = TYPE_ERROR;
                return;
            }

            //Check if location-Id has a valid type (not null, empty or undefined (valid = 1 - 7))

            if (locationId === null || locationId === '' || locationId === undefined) {
                this.error = true;
                this.errorMessage = LOCATION_ERROR;
                return;
            }

            let amount = this.editStrom.amount;
            let costs = this.editStrom.costs;
            let proof = this.editStrom.proof;
            let comment = this.editStrom.comment;
            let amountkWh = this.editStrom.amountkWh;
            let calorificvalue = this.editStrom.calorificvalue;
            let id = this.editStrom.id;
            if (amount === "" || costs === "") {
                this.error = true;
                this.errorMessage = DEFAULT_ERROR;
                console.log("value error");
                console.log(this.error);
                return;
            }
            if (this.editable) {
                this.setEdit(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh, id);
            } else {
                this.initiateAddStrom(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh);
            }
        } else {
            this.error = true;
            console.log(this.error);
        }
    }

    initiateAddStrom(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh) {
        this.props.addStrom(this.editStrom, locationId, type, date, proof, comment, amountkWh, costs);

        this.closeInput()
    }

    setEdit(locationId, type, date, amount, costs, proof, comment, calorificvalue, amountkWh, id) {
        this.props.editStroms(this.editstrom, locationId, type, date, proof, comment, amountkWh, id, costs);

        this.closeInput()
    }


    openEditStromModal() {
        this.editStrom.stromType = this.props.strom.stromType;
        this.editStrom.date = new Date(this.props.strom.date * 1000);
        this.editStrom.amount = this.props.strom.amount;
        this.editStrom.calorificvalue = this.props.strom.calorificvalue;
        this.editStrom.amountkWh = this.props.strom.amountkWh;
        this.editStrom.costs = this.props.strom.costs;
        this.editStrom.proof = this.props.strom.proof;
        this.editStrom.comment = this.props.strom.comment;
        this.editStrom.locationId = this.props.locationId;
        this.editStrom.id = this.props.strom.id;
    }

    handleChange(date) {
        if (this.isEditModal === false) {
            if (date.getMonth !== this.props.month) {
                this.editStroms.date = date;
            } else {
                this.setState({
                    ...this.state,
                    error: true

                });
                this.errorMessage = DATE_ERROR;
                return;
            }
        }
    }

    closeInput() {
        if (this.isEditModal === true) {
            this.isEditModal = true;
            this.addstromModalOpen = false;
            this.deleteModalOpen = false;
            this.state.deleteopen = false;
            this.editstrom = {
                stromType: this.props.strom.stromType,
                date: new Date(this.props.strom.date * 1000),
                amount: this.props.strom.amount,
                calorificvalue: this.props.strom.calorificvalue,
                amountkWh: this.props.strom.amountkWh,
                costs: this.props.strom.costs,
                proof: this.props.strom.proof,
                locationId: this.props.locationId,
                comment: this.props.strom.comment,
                id: this.props.strom.id
            };
        } else {
            this.addstromModalOpen = false;
            this.isEditModal = true;
            this.deleteModalOpen = false;
            this.state.deleteopen = false;
            this.editStrom = {
                stromType: "",
                date: new Date(),
                amount: "",
                calorificvalue: 1,
                amountkWh: "",
                costs: "",
                proof: "",
                comment: ""
            };
        }
        this.setState({
            ...this.state,
            open: false
        })
        this.error = false;
    }

    openDeleteStromModal(stromId) {
        this.deleteModalOpen = true;
        console.log(stromId);
        this.props.deleteStrom(stromId);
        this.closeInput();
    }

    handleInputChange(type, event, data) {

        this.editStrom.calorificvalue = 1;

        if (type === 'stromType') {
            this.editStrom.stromType = data.value;
        } else if (type === 'date') {
            this.editStrom.date = data.value;

        } else if (type === 'amount') {
            if (data.value === "") {
                this.editStrom.amount = 0;
            } else {
                let floatValues = /[0-9]+\.?[0-9]{0,2}/;
                let filteredValue = data.value.match(floatValues)[0];
                if (!filteredValue.endsWith(".")) {
                    filteredValue = parseFloat(filteredValue);
                }
                this.editStrom.amount = filteredValue;
            }
        } else if (type === 'amountkWh') {
            if (data.value === "") {
                this.editStrom.amountkWh = 0;
            } else {
                this.editStrom.amountkWh = data.value;
            }
        } else if (type === 'costs') {
            if (data.value === "") {
                this.editStrom.costs = 0;
            } else
                this.editStrom.costs = data.value;
        } else if (type === 'proof') {
            this.editStrom.proof = data.value;
        } else if (type === 'comment') {
            this.editStrom.comment = data.value;
        }
        this.editStrom.locationId = this.props.locationId;
    }

    //functions for edit


    render() {
        if (typeof this.props.strom.date === "undefined") {
            this.editStrom.date = new Date();
            this.editable = false;
        } else {
            this.editable = true;
        }
        let month = "";
        switch (this.props.month) {
            case 0:
                month = "Januar";
                break;
            case 1:
                month = "Februar";
                break;
            case 2:
                month = "März";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "Mai";
                break;
            case 5:
                month = "Juni";
                break;
            case 6:
                month = "Juli";
                break;
            case 7:
                month = "August";
                break;
            case 8:
                month = "September";
                break;
            case 9:
                month = "Oktober";
                break;
            case 10:
                month = "November";
                break;
            case 11:
                month = "Dezember";
                break;
        }

        const IdOptions = [
            {key: 4, text: 'Abrechnung', value: 1},
            {key: 5, text: 'Weiterberechnung', value: 2}
        ];


        return (

            <Table.Row className="color-sub-row">

                {this.edit ?
                    <Table.Cell>
                        <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                               trigger={
                                   <Icon name='edit' color="green"
                                         onClick={() => {
                                             this.openEditStromModal();
                                             this.setState({open: true, editable: true, isEditModal: true});
                                         }}/>
                               }>
                            <Modal.Header>Bearbeiten: {this.props.location.caompanyname} {month} - {this.props.year}</Modal.Header>
                            <Modal.Content>

                                <Form error={this.error}>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Karte oder Lieferung</label>
                                            <Dropdown selection placeholder="Abrechnung" direction='right'
                                                      color='black'
                                                      fluid
                                                      options={IdOptions}
                                                      value={this.editStrom.stromType}
                                                      onChange={this.handleInputChange.bind(this, 'stromType')}/>
                                        </Form.Field>


                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Menge [kWh]</label>
                                            <NumberFormat value={parseFloat(this.editStrom.amountkWh)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('amountkWh', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          suffix=" kWh"/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Kosten [€]</label>
                                            <NumberFormat value={parseFloat(this.editStrom.costs)}
                                                          thousandSeparator="."
                                                          decimalSeparator=","
                                                          decimalScale={2}
                                                          onValueChange={(values, e) => {
                                                              this.handleInputChange('costs', null, {value: values.floatValue})
                                                          }}
                                                          allowNegative={false}
                                                          suffix=" €"/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Nachweis</label>
                                            <Form.Input value={this.editStrom.proof}
                                                        type='proof'
                                                        onChange={this.handleInputChange.bind(this, 'proof')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bemerkung</label>
                                            <Form.Input value={this.editStrom.comment}
                                                        type='comment'
                                                        onChange={this.handleInputChange.bind(this, 'comment')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Message
                                        error
                                        header='Fehler'
                                        content={this.errorMessage}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={() => this.checkInputValues(this.props.locationId, this.props.month, this.props.strom.id)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                {this.adminedit ?
                    <Table.Cell>
                        <Modal open={this.state.deleteopen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}
                               trigger={this.delete ?
                                   <Icon name='delete' color="red"
                                         onClick={() => this.setState({deleteopen: true, delete: true})}/>
                                   :
                                   <Icon name='delete' color="red"
                                         onClick={() => this.setState({deleteopen: true, delete: true})}/>
                               }>
                            <Modal.Header>Löschen</Modal.Header>
                            <Modal.Content>
                                Sicher löschen?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.openDeleteStromModal.bind(this, this.props.strom.id)}

                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Cell>
                    :
                    null
                }
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                <Table.Cell>
                    {
                        this.props.strom.amountkWh != null ?
                            new Intl.NumberFormat('de-DE', {
                                style: 'decimal',
                                minimumFractionDigits: 2
                            }).format(this.props.strom.amountkWh.toFixed(2))
                            :
                            ""
                    }
                </Table.Cell>
                <Table.Cell>
                    {
                        this.props.strom.costs != null ?
                            new Intl.NumberFormat('de-DE', {
                                style: 'currency',
                                currency: 'EUR'
                            }).format(this.props.strom.costs.toFixed(2))
                            :
                            ""
                    }
                </Table.Cell>
                <Table.Cell>
                    {this.props.strom.comment}
                </Table.Cell>
                <Table.Cell>
                    {this.props.strom.proof}
                </Table.Cell>
            </Table.Row>
        );
    }
}