import React from "react";
import config from "../../config/main.config";
import {Button, Form, Grid, Modal, Table} from "semantic-ui-react";
import {getTokenFromLocalStorage} from "../helper/util";
import TableHeader from "semantic-ui-react/dist/commonjs/collections/Table/TableHeader";
import {observer} from "mobx-react";
import {observable} from "mobx";
import userStore from "../stores/userStore";

@observer
export default class LocationPage extends React.Component {

    @observable privilegedUser = false;

    @observable edit = false;

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            editValCaompanyName: "",
            editValLocation: "",
            editValPostcode: "",
            editValStreetName: "",
            editValStreetNumber: "",
            tempEditIndex: 0,
            addModalOpen: false,
            isEditModal: false,
            deleteModalOpen: false
        };

        let user = userStore.userRolesFromServer;
        this.privilegedUser = user.toString().includes("admin");
        this.edit = user.toString().includes("admin");
    }

    componentDidMount() {
        this.getLocations();
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({
                        locations: json
                    });

                });

            } else {
                console.log(response.status)
                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    openEdit() {
        this.setState({
            ...this.state,
            addModalOpen: true
        });
    }

    handleInputChange(type, event, data) {
        let {
            editValCaompanyName,
            editValLocation,
            editValPostcode,
            editValStreetName,
            editValStreetNumber
        } = this.state;

        if (type === 'postcode') {
            //only numbers
            editValPostcode = data.value.replace(/[^\d]/g, '');
        }
        if (type === 'caompanyname') {
            editValCaompanyName = data.value;
        }
        if (type === 'location') {
            editValLocation = data.value;
        }
        if (type === 'streetname') {
            editValStreetName = data.value;
        }
        if (type === 'streetnumber') {
            editValStreetNumber = data.value.replace(/[^\d]/g, '');
        }

        this.setState({
            ...this.state,
            editValPostcode: editValPostcode,
            editValCaompanyName: editValCaompanyName,
            editValLocation: editValLocation,
            editValStreetName: editValStreetName,
            editValStreetNumber: editValStreetNumber
        })
    }

    closeInput() {
        this.setState({
            ...this.state,
            editValPostcode: "",
            editValCaompanyName: "",
            editValLocation: "",
            editValStreetName: "",
            editValStreetNumber: "",
            addModalOpen: false,
            isEditModal: false,
            deleteModalOpen: false
        })
    }

    setEdit() {
        let {
            editValCaompanyName,
            editValLocation,
            editValPostcode,
            editValStreetName,
            editValStreetNumber,
            isEditModal,
            tempEditIndex,
            locations
        } = this.state;


        let location = {
            caompanyname: editValCaompanyName,
            location: editValLocation,
            postcode: editValPostcode,
            streetname: editValStreetName,
            streetnumber: editValStreetNumber
        };

        if (isEditModal) {
            location.id = locations[tempEditIndex].id;
            this.editVehicle(location);
            this.closeInput()

        } else {
            this.createLocation(location);
            this.closeInput()
        }
    }


    createLocation(location) {
        fetch(config.BASE_URL + "location", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(location)
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    editVehicle(location) {
        fetch(config.BASE_URL + "location", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(location)
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    deleteLocation() {
        let {locations, tempEditIndex} = this.state;

        fetch(config.BASE_URL + "location", {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: locations[tempEditIndex].id})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.componentDidMount();
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
        this.closeInput();
    }

    openEditLocationModal(location, index) {
        this.setState({
            ...this.state,
            editValCaompanyName: location.caompanyname,
            editValLocation: location.location,
            editValPostcode: location.postcode,
            editValStreetName: location.streetname,
            editValStreetNumber: location.streetnumber,
            tempEditIndex: index,
            addModalOpen: true,
            isEditModal: true,
            deleteModalOpen: false
        })
    }

    openDeleteLocationModal(index) {
        this.setState({
            ...this.state,
            tempEditIndex: index,
            deleteModalOpen: true
        })
    }


    show = dimmer => () => this.setState({dimmer, open: true});

    render() {

        const {addModalOpen} = this.state;
        let locations = this.state.locations;


        let tableElements = locations.map((location, index) => (
            <Table.Row key={index}>
                <Table.Cell>
                    {location.caompanyname}
                </Table.Cell>
                <Table.Cell>
                    {location.location}
                </Table.Cell>
                <Table.Cell>
                    {location.postcode}
                </Table.Cell>
                <Table.Cell>
                    {location.streetname}
                </Table.Cell>
                <Table.Cell>
                    {location.streetnumber}
                </Table.Cell>
                <Table.Cell>
                    <Button className="ui basic icon negative button"
                            onClick={this.openDeleteLocationModal.bind(this, index)}>
                        <i aria-hidden="true"
                           className="remove icon"/>
                    </Button>
                    <Button className="ui basic icon positive button"
                            onClick={this.openEditLocationModal.bind(this, location, index)}>
                        <i aria-hidden="true"
                           className="edit icon"/>
                    </Button>
                </Table.Cell>
            </Table.Row>

        ));

        return (
            <Grid with={16}>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Standorte</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button circular color='blue' icon='add' floated={"left"}
                                onClick={this.openEdit.bind(this)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'}>Firmenname</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Ort</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>PLZ</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Straße</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>Nr</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Modal open={addModalOpen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>{this.state.isEditModal ? "Bearbeiten" : "Hinzufügen"}</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Firmenname</label>
                                            <Form.Input fluid value={this.state.editValCaompanyName}
                                                        onChange={this.handleInputChange.bind(this, 'caompanyname')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Ort</label>
                                            <Form.Input fluid value={this.state.editValLocation}
                                                        onChange={this.handleInputChange.bind(this, 'location')}/>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field width={3}>
                                            <label>PLZ</label>
                                            <Form.Input fluid value={this.state.editValPostcode}
                                                        onChange={this.handleInputChange.bind(this, 'postcode')}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Straße</label>
                                            <Form.Input fluid value={this.state.editValStreetName}
                                                        onChange={this.handleInputChange.bind(this, 'streetname')}/>
                                        </Form.Field>
                                        <Form.Field width={2}>
                                            <label>Nr</label>
                                            <Form.Input fluid value={this.state.editValStreetNumber}
                                                        onChange={this.handleInputChange.bind(this, 'streetnumber')}/>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.setEdit.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                    <Grid.Column>
                        <Modal open={this.state.deleteModalOpen} className={"modal-top"}
                               onClose={this.closeInput.bind(this)} centered={false}>
                            <Modal.Header>Löschen</Modal.Header>
                            <Modal.Content>
                                Sicher löschen?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                                <Button
                                    positive
                                    icon='checkmark'
                                    onClick={this.deleteLocation.bind(this)}
                                />
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        );
    }
}
