import React from "react";
import {Button, Dropdown, Form, Grid, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";

import {checkEdit, checkRole} from "../helper/rolehelper";
import userStore from "../stores/userStore";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import StromMonth from "../components/StromMonth";


const DEFAULT_ERROR = "Werte dürfen nicht 0, oder leer sein.";


@observer
export default class StromPage extends React.Component {

    @observable
    stroms = [];
    @observable
    editStrom = {
        stromType: "",
        date: new Date(),
        amountkWh: 0,
        costs: 0,
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    error = false;

    @observable
    isEditModal = false;

    @observable
    currentLocationId;

    @observable
    locations = [];

    @observable
    addstromModalOpen = false;

    @observable
    deleteModalOpen = false;

    @observable
    errorMessage = DEFAULT_ERROR;

    @observable privilegedUser = false;

    @observable delete = false;
    @observable edit = false;

    constructor(props) {

        super(props);

        this.state = {
            tmpEditValdate: new Date(),
            currentSelectedValYear: new Date().getFullYear(),

            open: false,
            deleteopen: false,
            delete: true,
            edit: false,
            add: true

        };

        let user = userStore.userRolesFromServer;
        if (checkRole(user, "/strom")) {
            this.privilegedUser = true;
        } else {
            this.privilegedUser = user.toString().includes("admin");
        }
        this.edit = checkEdit(user, "/strom");
    }

    componentDidMount() {
        this.editStrom.stromType = 1;
        this.fetchStroms();
        this.getLocations();
    }

    fetchStroms() {
        fetch(config.BASE_URL + "stroms", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.stroms = json
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    addStrom(editStrom, locationId, type, date, proof, comment, amountkWh, costs) {
        fetch(config.BASE_URL + "stroms", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                stromType: type,
                date: date,
                amountkWh: amountkWh,
                proof: proof,
                costs: costs,
                comment: comment,
                locationId: locationId
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchStroms();
            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status);
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }


    handleDateChange(type, event, data) {
        let {currentSelectedValYear} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }


        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
        }, () => {
        });

    }

    getAllFromStromLocation(location, stroms) {
        let stromLocal = [];
        for (let j = 0; j < this.stroms.length; j++) {
            if (this.stroms[j].locationId === location) {
                stromLocal.push(stroms[j]);
            }
        }
        return stromLocal;
    }


    deleteStrom(stromId) {
        fetch(config.BASE_URL + "stroms/", {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: stromId})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.loading = false;
                this.fetchStroms();
            } else {
                console.log(response.status);
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    editStroms(editStrom, locationId, type, date, proof, comment, amountkWh, id, costs) {
        fetch(config.BASE_URL + "stroms/", {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                stromType: type,
                date: date,
                amountkWh: amountkWh,
                locationId: locationId,
                costs: costs,
                proof: proof,
                comment: comment,
                id: id
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchStroms();
            } else {
                console.log(response.status)
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);
            }
        );

    }

    getMonthYearStromFromLocation(month, year, allStroms) {
        let monthStrom = []
        for (let i = 0; i < allStroms.length; i++) {

            let curDate = new Date(allStroms[i].date * 1000);
            if (curDate.getMonth() === month && curDate.getFullYear() === year) {
                monthStrom.push(allStroms[i]);
                //return allStroms[i];
            }
        }
        return monthStrom;
    }

    downloadExcelTable() {
        let {currentSelectedValYear} = this.state;

        fetch(config.BASE_URL + "stroms/excel/" + currentSelectedValYear, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty('link')) {
                        window.open(config.BASE_FILE_URL + json.link);
                    }
                });
            } else {
                console.log("error");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    render() {
        const yearOptions = [];
        let addYearOption = new Date().getFullYear();
        let i = 0;
        while (addYearOption >= 2016) {
            yearOptions.push({
                key: i,
                text: addYearOption.toString(),
                value: addYearOption
            });
            i++;
            addYearOption--;
        }

        const IdOptions = [
            {key: 4, text: 'Abrechnung', value: 1},
            {key: 5, text: 'Weiterberechnung', value: 2}
        ];
        const calorificvalue = 9.96;

        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let tableElements = [];
        for (let i = 0; i < locations.length; i++) {

            let kwhSum = 0;
            let costSum = 0;
            let locationStroms = this.getAllFromStromLocation(locations[i].id, this.stroms)
            for (let j = 0; j < locationStroms.length; j++) {

                let stromDate = locationStroms[j].date;
                let stromDateYear = new Date(stromDate * 1000).getFullYear();

                if ((this.state.currentSelectedValYear === stromDateYear)) {

                    kwhSum += locationStroms[j].amountkWh;
                    if (locationStroms[j].stromType === 1) {
                        costSum += locationStroms[j].costs;
                    } else if (locationStroms[j].stromType === 2) {
                        costSum = costSum - locationStroms[j].costs;
                    }
                }
            }

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingMonths ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingMonths = !this.locations[i].showingMonths}/>
                    </Table.Cell>

                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'decimal',
                            minimumFractionDigits: 2
                        }).format(kwhSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(costSum.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                </Table.Row>
            );
            let locationMonthlyFigures = [];

            for (let j = 0; j < 12; j++) {
                //month starts by 0
                let monthStrom = this.getMonthYearStromFromLocation(j, this.state.currentSelectedValYear, locationStroms);

                locationMonthlyFigures.push(<StromMonth key={i + "_" + j}
                                                        strom={monthStrom}
                                                        locationId={locations[i].id}
                                                        location={locations[i]}
                                                        deleteStrom={this.deleteStrom.bind(this)}
                                                        editStroms={this.editStroms.bind(this)}
                                                        addStrom={this.addStrom.bind(this)}
                                                        locationStroms={locationStroms}
                                                        month={j}
                                                        year={this.state.currentSelectedValYear}
                />);
            }

            if (locations[i].showingMonths) {
                tableElements.push(locationMonthlyFigures);
            }

            for (let i = 0; i < this.stroms.length; i++) {
                let stromDate = this.stroms[i].date;
                let stromDateYear = new Date(stromDate * 1000).getFullYear();
                let temp = 0;
                if ((this.state.currentSelectedValYear === stromDateYear)) {
                    for (let j = 0; j < this.locations.length; j++) {
                        if (this.stroms[i].locationId === j) {
                            temp += this.stroms[i].amountkWh;
                        }
                    }
                }
            }
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Strom</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            icon
                            labelPosition='right'
                            positive
                            onClick={() => this.downloadExcelTable()}>
                            Exportieren
                            <Icon name={"external alternate"}/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>Monat</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Menge [kWh]</Table.HeaderCell>
                                    <Table.HeaderCell>Summe Kosten [netto]</Table.HeaderCell>
                                    <Table.HeaderCell>Bemerkung</Table.HeaderCell>
                                    <Table.HeaderCell>Nachweis</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
