import React from "react";
import {Button, Form, Message, Modal} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import DatePicker from "react-datepicker/es";

import dieselStore from "../stores/dieselStore";
import "react-datepicker/dist/react-datepicker.css";

const DEFAULT_ERROR = 'Werte dürfen nicht 0 sein.';

export default class DeleteFuellingModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tmpEditValDesignation: "",
            tmpEditValAmountltr: 0,
            tmpEditValReference: "",
            tmpEditValAmountreference: 0,
            tmpVehicleId: 0,
            tmpEditValdate: new Date(),
            tempEditIndex: 0,
            addModalOpen: false,
            open: false,
            error: false,
            errorMessage: DEFAULT_ERROR,
            delete:false
        }
    }

    componentDidMount() {
        if (this.props.hasOwnProperty("fuellingIndex")) {
            let fuelling = this.props.vehicle.fuellings[this.props.fuellingIndex];

            this.setState({
                ...this.state,
                delete: true,
                tempEditIndex: this.props.fuellingIndex,
                tmpEditValDesignation: fuelling.designation,
                tmpEditValAmountltr: fuelling.amountltr,
                tmpEditValAmountreference: fuelling.amountreference,
                tmpEditValdate: new Date(fuelling.date * 1000),
            })
        }
    }



    closeInput() {
        if (!this.state.delete) {
            this.setState({
                ...this.state,
                tmpEditValAmountltr: 0,
                tmpEditValReference: "",
                tmpEditValAmountreference: 0,
                open: false,
                error: false
            })
        } else {
            this.setState({
                ...this.state,
                open: false,
                error: false
            })
        }
    }


    deleteDieselFuelling(){
        let fuelling = {
            id: this.props.vehicle.fuellings[this.props.fuellingIndex].id,
        };
        dieselStore.deleteDieselFuellings(fuelling);
        this.closeInput();
    }



    render() {

        return (
            <Modal open={this.state.open} className={"modal-top"}
                   onClose={this.closeInput.bind(this)} centered={false}
                   trigger={this.state.delete ?
                       <Icon name='delete' color="red" onClick={() => this.setState({open: true})}/>
                       :
                       <Icon name='plus' color="orange" onClick={() => this.setState({open: true})}/>
                   }>
                <Modal.Header>Löschen</Modal.Header>
                <Modal.Content>
                    Sicher löschen?
                </Modal.Content>
                <Modal.Actions>
                    <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                    <Button
                        positive
                        icon='checkmark'
                        onClick={this.deleteDieselFuelling.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}