import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getRolesFromLocalStorage,
    getTokenFromLocalStorage,
    removeTokenFromStorage,
    setUserLocalStorage
} from "../helper/util";
import history from "../helper/browserHistory"
import {containsStart} from "../helper/rolehelper";

class UserStore {
    @observable user = null;
    @observable loading = false;
    @observable error = false;

    @computed get userFromServer() {
        if (this.user === null) {
            if (getTokenFromLocalStorage() === null) {
                return null;
            }
            return getTokenFromLocalStorage();
        }
        return this.user;
    }

    @computed get userRolesFromServer() {
        if (this.user === null) {
            if (getRolesFromLocalStorage() === null) {
                return null;
            }

            let roles = getRolesFromLocalStorage();
            if (roles !== undefined)
                return roles.split(",");
            return [];
        }

        if (this.user.roles !== undefined) {
            return this.user.roles.split(",");
        }
        return [];
    }

    @computed get canEdit() {
        //TODO: deprecated
        return false;
    }

    @computed get isAdmin() {
        let roles = [];
        if (this.user === null) {
            if (getRolesFromLocalStorage() === null) {
                return null;
            }
            roles = getRolesFromLocalStorage().split(",");
        } else {
            if (this.user.roles !== undefined) {
                roles = this.user.roles.split(",");
            }
        }

        return roles[0] === "admin";
    }

    @action authenticateUser(userToAuthenticate) {
        if (userToAuthenticate === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "users/authenticate", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify(userToAuthenticate)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {

                            setUserLocalStorage(json);
                            this.user = json;
                            let userRoles = this.user.roles.split(",");
                            if (containsStart(userRoles, "Diesel")) {
                                //console.log("DieselTest");
                                history.push("/diesel")
                            } else if (containsStart(userRoles, "Strom")) {
                                //console.log("StromTest");
                                history.push("/")
                            } else if (containsStart(userRoles, "admin")) {
                                //console.log("ExampleTest");
                                history.push("/user-management")
                            }

                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }

    }
}

const store = new UserStore();

export default store;