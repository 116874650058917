import React from "react";
import {Dropdown, Form, Grid, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";

import KeyFigureRow from "../components/KeyFigureRow";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import FuellingDeliveryRow from "../components/FuellingDeliveryRow";
import userStore from "../stores/userStore";

@observer
export default class KeyFiguresPage extends React.Component {

    @observable
    fuellingdeliveries = [];

    @observable
    dieselfuellings = [];

    @observable
    locations = [];

    @observable
    vehicles = [];

    @observable
    fuellingdelivery = {
        fuellingdeliveryType: "",
        date: new Date(),
        amountliter: 0,
        calorificvalue: "",
        amountkWh: "",
        costs: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    dieselfuelling = {
        tmpEditValDesignation: "",
        tmpEditValAmountltr: 0,
        tmpEditValReference: "",
        tmpEditValAmountreference: 0,
        tmpVehicleId: 0,
        tempEditIndex: 0
    };

    @observable
    locations = [];

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedValYear: new Date().getFullYear(),
            openVehiclesstate: false
        };
    }

    toggleVehicles() {
        let {openVehiclesstate} = this.state;
        openVehiclesstate = !openVehiclesstate;

        this.setState({
            ...this.state,
            openVehiclesstate: openVehiclesstate
        });
    }

    componentDidMount() {
        fetch(config.BASE_URL + "fuellingdeliveries", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fuellingdeliveries = json;
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
        this.getLocations();
        this.getDieselFuellings();
        this.getVehicles();
    }

    getDieselFuellings() {
        fetch(config.BASE_URL + "dieselfuellings", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.dieselfuellings = json;
                });

            } else {
                console.log(response.status)
                console.log(error);
            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status);
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    getVehicles() {
        fetch(config.BASE_URL + "vehicles/withFuelling", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.vehicles = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;

            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth
        }, () => {
        });

    }

    getAllKwHFromDieselFuellings(vehiclesId, year, dieselFuellings) {
        let sum = 0;
        for (let i = 0; i < dieselFuellings.length; i++) {
            let deliveryDate = new Date(dieselFuellings[i].date * 1000);
            for (let j = 0; j < this.vehicles.length; j++) {
                if (dieselFuellings[i].vehicleId === this.vehicles[j].id) {
                    if (this.vehicles[j].id === vehiclesId && deliveryDate.getFullYear() === year) {
                        const calorificvalue = 9.96;
                        sum += (dieselFuellings[i].amountltr * calorificvalue);
                    }
                }
            }
        }
        return sum;
    }

    getKwhPerMonthAndLocation(vehiclesId, year, month, dieselFuellings) {
        let sum = 0;
        for (let i = 0; i < dieselFuellings.length; i++) {
            let deliveryDate = new Date(dieselFuellings[i].date * 1000);
            for (let j = 0; j < this.vehicles.length; j++) {
                if (dieselFuellings[i].vehicleId === this.vehicles[j].id) {
                    if (this.vehicles[j].id === vehiclesId && deliveryDate.getFullYear() === year
                    && deliveryDate.getMonth() === month) {
                        const calorificvalue = 9.96;
                        sum += (dieselFuellings[i].amountltr * calorificvalue);
                    }
                }
            }
        }
        return sum;
    }

    getAllKwHFromDieselFuellingsMonth(locationId, year, month, dieselFuellings, vehicles) {
        let sum = 0;

        for(let j = 0; j < vehicles.length; j++) {
            if (locationId === vehicles[j].locationId) {
                sum += this.getKwhPerMonthAndLocation(vehicles[j].id, this.state.currentSelectedValYear, month, dieselFuellings);
            }
        }
        return sum;
    }

    getAllKwHFromDieselFuellingsPreYear(locationId, year, month, dieselFuellings, vehicles) {
        let sum = 0;
        let preyear = year-1;
        for(let j = 0; j < vehicles.length; j++) {
            if (locationId === vehicles[j].locationId) {
                sum += this.getKwhPerMonthAndLocation(vehicles[j].id, preyear, month, dieselFuellings);
            }
        }
        return sum;
    }

    checkIfDifferenceIsNaN(sumKwHDieselFuelling, sumKwHDieselFuellingFromPreviousYear) {
        let difference;
        if (sumKwHDieselFuelling !== 0) {
            difference = (((sumKwHDieselFuelling - sumKwHDieselFuellingFromPreviousYear) / sumKwHDieselFuelling) * 100).toFixed(2);
            if (isNaN(difference) === true) {
                difference = "/";
            }
        } else {
            difference = "/";
        }
        return difference;
    }

    addPercentageSignFunction(deviationPercentage) {
        let finaldeviationPercentage;
        if (deviationPercentage !== "/") {
            finaldeviationPercentage = deviationPercentage + '%';
        } else {
            finaldeviationPercentage = "/";
        }

        return finaldeviationPercentage;
    }

    render() {

        let dieselfuellings = this.dieselfuellings;
        const yearOptions = [];
        let yearArray = [];
        for (let i = 0; i < this.dieselfuellings.length; i++) {
            let deliveryDate = new Date(dieselfuellings[i].date * 1000);
            let deliveryYear = deliveryDate.getFullYear();
            if (!yearArray.includes(deliveryYear))
                yearArray.push(deliveryYear);
        }

        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }

        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let vehicles = this.vehicles;
        let tableElements = [];



        for (let i = 0; i < locations.length; i++) {


            let sumKwHDieselFuelling = 0;
            let sumKwHDieselFuellingFromPreviousYear = 0;
            let differenceKwHToPreviousYear = 0;
            let differenceKwHToPreviousYearPercentage = 0;
            for(let j = 0; j < vehicles.length; j++){
                if(locations[i].id === vehicles[j].locationId){
                    sumKwHDieselFuelling += this.getAllKwHFromDieselFuellings(vehicles[j].id, this.state.currentSelectedValYear, this.dieselfuellings);
                    sumKwHDieselFuellingFromPreviousYear += this.getAllKwHFromDieselFuellings(vehicles[j].id, (this.state.currentSelectedValYear - 1), this.dieselfuellings);
                }
            }
            differenceKwHToPreviousYear = this.checkIfDifferenceIsNaN(sumKwHDieselFuelling, sumKwHDieselFuellingFromPreviousYear);
            differenceKwHToPreviousYearPercentage = this.addPercentageSignFunction(differenceKwHToPreviousYear);

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingMonths ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingMonths = !this.locations[i].showingMonths}/>
                    </Table.Cell>
                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {  style: 'decimal'}).format(sumKwHDieselFuelling.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {new Intl.NumberFormat('de-DE', {  style: 'decimal'}).format(sumKwHDieselFuellingFromPreviousYear.toFixed(2))}
                    </Table.Cell>
                    <Table.Cell>
                        {differenceKwHToPreviousYearPercentage}
                    </Table.Cell>

                </Table.Row>
            );
            let locationMonthlyFigures = [];
            for (let j = 0; j < 12; j++) {
                locationMonthlyFigures.push(<KeyFigureRow key={i + "_" + j}
                                                         kSum={this.getAllKwHFromDieselFuellingsMonth(locations[i].id, this.state.currentSelectedValYear, j, this.dieselfuellings, vehicles)}
                                                          prekSum={this.getAllKwHFromDieselFuellingsPreYear(locations[i].id, this.state.currentSelectedValYear, j, this.dieselfuellings, vehicles)}
                                                         month={j}
                />);
            }

            if (locations[i].showingMonths) {
                tableElements.push(locationMonthlyFigures);
            }
        }
        for (let i = 0; i < this.fuellingdeliveries.length; i++) {
            let fuellingdeliveriesDate = this.fuellingdeliveries[i].date;
            let fuellingdeliveriesDateYear = new Date(fuellingdeliveriesDate * 1000).getFullYear();
            let fuellingdeliveriesDateMonth = (new Date(fuellingdeliveriesDate * 1000).getMonth());
            let temp = 0;
            if ((this.state.currentSelectedValYear === fuellingdeliveriesDateYear
                && fuellingdeliveriesDateMonth.toString() === this.state.currentSelectedValMonth)) {
                for (let j = 0; j < this.locations.length; j++) {
                    if (this.fuellingdeliveries[i].locationId === j) {
                        temp += this.fuellingdeliveries[i].amountkWh;
                    }
                }
            }

        }

        return (

            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Kennzahlen</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled={true}>
                            <TableHeader>
                                <Table.Row>

                                    <Table.HeaderCell/>
                                    <Table.HeaderCell textAlign={'center'} colSpan={4}>Ort</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'} colSpan={3}>KwH-Entwicklung </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Einsatzort</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Summe KwH</Table.HeaderCell>
                                    <Table.HeaderCell>Summe KwH Vorjahr</Table.HeaderCell>
                                    <Table.HeaderCell>Entwicklung zum Vorjahr %</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
