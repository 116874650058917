import React from "react";
import {Button, Checkbox, Dropdown, Form, Grid, Icon, Input, Segment, Table} from "semantic-ui-react";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {getLocationIdsOfUserRole, isUserRoleEdit} from "../helper/rolehelper";
import userStore from "../stores/userStore"
import TableHeader from "semantic-ui-react/dist/commonjs/collections/Table/TableHeader";
import DieselSumRow from "../components/DieselSumRow";
import dieselStore from "../stores/dieselStore";

@observer
export default class DieselPage extends React.Component {

    @observable edit = false;
    @observable summe = "";
    @observable search = "";

    @observable
    sortType = 0;
    /*
     * 0 => ascending
     * 1 => descending
     */
    @observable
    sortDirection = 0;

    constructor(props) {
        super(props);
        this.state = {
            reference: [],
            location: [],
            tmpEditValCostcentre: "",
            tmpEditValDesignation: "",
            tmpEditValType: 1,
            tmpEditValLocation: "",
            tmpEditValReference: "",
            tmpEditValActive: 1,
            tempEditIndex: 0,
            addModalOpen: false,
            deleteModalOpen: false,
            currentSelectedValYear: new Date().getFullYear(),
            currentSelectedValMonth: new Date().getMonth().toString(),
            variableVehicleVisible: 0,
            userRole: "",
            adminRole: "",
        };
        this.state.userRole = getLocationIdsOfUserRole(userStore.userRolesFromServer, "Diesel");
        this.state.adminRole = userStore.userRolesFromServer;
        this.edit = isUserRoleEdit(userStore.userRolesFromServer, "Diesel");
    }

    componentDidMount() {
        dieselStore.fetchDieselfuellings();
        dieselStore.fetchLocations();
    }

    setVariableVehicleVisible() {
        this.setState(
            {
                ...this.state,
                variableVehicleVisible: this.state.variableVehicleVisible === 0 ? 1 : 0
            }
        );
    }


    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth, variableVehicleVisible} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;
            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth,
            variableVehicleVisible: variableVehicleVisible
        }, () => {
        });

    }

    sortElements(type) {
        if (type !== this.sortType) {
            this.sortDirection = 0;
        } else {
            this.sortDirection = this.sortDirection === 0 ? 1 : 0;
        }
        this.sortType = type;
    }

    render() {
        const yearOptions = [];
        let yearArray = [];
        for (let j = 0; j < dieselStore.dieselfuellings.length; j++) {
            let vehicleFuellings = dieselStore.dieselfuellings[j].fuellings;
            for (let i = 0; i < vehicleFuellings.length; i++) {
                let deliveryDate = new Date(vehicleFuellings[i].date * 1000);
                let deliveryYear = deliveryDate.getFullYear();
                if (!yearArray.includes(deliveryYear))
                    yearArray.push(deliveryYear);
            }
        }

        const locationOptions = [];
        for (let i = 0; i < dieselStore.locations.length; i++) {
            locationOptions.push({
                key: i,
                text: dieselStore.locations[i].caompanyname,
                value: dieselStore.locations[i].caompanyname,
                id: dieselStore.locations[i].id
            })
        }


        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }

        let tableElements = [];

        let sortedFuellings = [];

        switch (this.sortType) {
            case 0:
                sortedFuellings = dieselStore.dieselfuellings.sort((a, b) => (a.designation.toString().toLocaleLowerCase() > b.designation.toString().toLocaleLowerCase()) ? 1 : -1);
                if (this.sortDirection === 1) {
                    sortedFuellings = sortedFuellings.reverse();
                }
                break;
            case 1:
                sortedFuellings = dieselStore.dieselfuellings.sort((a, b) => (a.type.toString().toLocaleLowerCase() > b.type.toString().toLocaleLowerCase()) ? 1 : -1);
                if (this.sortDirection === 1) {
                    sortedFuellings = sortedFuellings.reverse();
                }
                break;
            case 2:
                sortedFuellings = dieselStore.dieselfuellings.sort((a, b) => (a.location.toString().toLocaleLowerCase() > b.location.toString().toLocaleLowerCase()) ? 1 : -1);
                if (this.sortDirection === 1) {
                    sortedFuellings = sortedFuellings.reverse();
                }
                break;
        }

        for (let i = 0; i < sortedFuellings.length; i++) {
            let vehicle = sortedFuellings[i];
            if (this.search.length === 0 ||
                vehicle.designation.toLowerCase().includes(this.search.toLowerCase()) ||
                vehicle.type.includes(this.search) ||
                vehicle.location.toString().toLowerCase().includes(this.search.toLowerCase()) ||
                vehicle.costcentre.toString().toLowerCase().includes(this.search.toLowerCase())) {
                if (this.state.variableVehicleVisible === 0) {
                    //Check if a user can see it
                    if (this.state.adminRole.includes("admin".toString()) || this.state.userRole.includes(vehicle.locationId.toString())) {
                        tableElements.push(<DieselSumRow vehicle={vehicle} key={i}
                                                         year={this.state.currentSelectedValYear}
                                                         month={this.state.currentSelectedValMonth}
                                                         variableVehicleVisible={this.state.variableVehicleVisible}
                                                         locations={dieselStore.locations}/>);
                    }
                } else {
                    if (vehicle.variable === 1) {
                        tableElements.push(<DieselSumRow vehicle={vehicle} key={i}
                                                         year={this.state.currentSelectedValYear}
                                                         month={this.state.currentSelectedValMonth}
                                                         variableVehicleVisible={this.state.variableVehicleVisible}
                                                         locations={dieselStore.locations}/>);
                    }
                }
            }
        }
        return (
            <Grid width={16}>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Fahrzeugerfassung</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>

                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={11}>
                                        <Button value="0"
                                                className={this.state.currentSelectedValMonth === "0" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jan</Button>
                                        <Button value="1"
                                                className={this.state.currentSelectedValMonth === "1" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Feb</Button>
                                        <Button value="2"
                                                className={this.state.currentSelectedValMonth === "2" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mär</Button>
                                        <Button value="3"
                                                className={this.state.currentSelectedValMonth === "3" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Apr</Button>
                                        <Button value="4"
                                                className={this.state.currentSelectedValMonth === "4" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Mai</Button>
                                        <Button value="5"
                                                className={this.state.currentSelectedValMonth === "5" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jun</Button>
                                        <Button value="6"
                                                className={this.state.currentSelectedValMonth === "6" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Jul</Button>
                                        <Button value="7"
                                                className={this.state.currentSelectedValMonth === "7" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Aug</Button>
                                        <Button value="8"
                                                className={this.state.currentSelectedValMonth === "8" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Sep</Button>
                                        <Button value="9"
                                                className={this.state.currentSelectedValMonth === "9" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Okt</Button>
                                        <Button value="10"
                                                className={this.state.currentSelectedValMonth === "10" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Nov</Button>
                                        <Button value="11"
                                                className={this.state.currentSelectedValMonth === "11" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Dez</Button>
                                        <Button value="12"
                                                className={this.state.currentSelectedValMonth === "12" ? "active-button" : "blue-button"}
                                                onClick={this.handleDateChange.bind(this, 'month')}>Gesamt</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Input icon='search' placeholder='Suche...'
                               value={this.search} onChange={(event, data) => this.search = data.value}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Checkbox
                            label={"Fahrzeuge mit variablen Standorten anzeigen"}
                            value={this.state.variableVehicleVisible}
                            onChange={this.setVariableVehicleVisible.bind(this)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled={true} sortable fixed>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    {this.edit? <Table.HeaderCell/> : null}
                                    <Table.HeaderCell>Neu</Table.HeaderCell>
                                    <Table.HeaderCell>Kontostelle</Table.HeaderCell>
                                    <Table.HeaderCell
                                        onClick={() => this.sortElements(0)}>Bezeichnung {this.getSortIcon(0)}</Table.HeaderCell>
                                    <Table.HeaderCell
                                        onClick={() => this.sortElements(1)}>Typ {this.getSortIcon(1)}</Table.HeaderCell>
                                    <Table.HeaderCell
                                        onClick={() => this.sortElements(2)}>Einsatzort {this.getSortIcon(2)}</Table.HeaderCell>
                                    <Table.HeaderCell>Bh/km Stand</Table.HeaderCell>
                                    <Table.HeaderCell>kWh</Table.HeaderCell>
                                    <Table.HeaderCell>Liter</Table.HeaderCell>
                                    <Table.HeaderCell>Ø Verbrauch</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        );
    }

    getSortIcon(sortType) {
        if (this.sortType === sortType) {
            return this.sortDirection === 0 ? <Icon name={"caret up"}/> : <Icon name={"caret down"}/>;
        }
        return "";
    }
}
