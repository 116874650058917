export function containsStart(stringArray, start) {
    for (let i = 0; i < stringArray.length; i++) {
        if (stringArray[i].startsWith(start)) {
            return true;
        }
    }
    return false;
}

export function checkEdit(roleArray, pagePathname) {
    if (roleArray == null) {
        return false;
    }

    if (roleArray[0] === "admin") {
        return true;
    }
    switch (pagePathname) {
        case "/diesel":
            return containsStart(roleArray, "Diesel_edit");
        case "/acetylen":
            return containsStart(roleArray, "Acetylen_edit");
        case "/propan":
            return containsStart(roleArray, "Propan_edit");
        case "/strom":
            return containsStart(roleArray, "Strom_edit");
        case "/liquidgas":
            return containsStart(roleArray, "LiquidGas_edit");
        case "/example":
            return containsStart(roleArray, "Example_edit");
        case "/vehicles":
            return containsStart(roleArray, "Vehicle_edit");
        case "/fuellingdelivery":
            return containsStart(roleArray, "FuellingDelivery_edit");
        case "/heatingoil":
            return containsStart(roleArray, "HeatingOil_edit");
        case "/plausibility":
            return containsStart(roleArray, "Plausibility_edit");
        case "/keyfigures":
            return containsStart(roleArray, "KeyFigures_edit");
        case "/productionkeyfigures":
            return containsStart(roleArray, "ProductionKeyFigures_edit");
        case "/production":
            return containsStart(roleArray, "Production_edit");
        default:
            return false;
    }
}

export function checkRole(roleArray, pagePathname) {
    if (roleArray == null) {
        return false;
    }

    if (roleArray[0] === "admin") {
        return true;
    }
    switch (pagePathname) {
        case "/diesel":
            return containsStart(roleArray, "Diesel");
        case "/acetylen":
            return containsStart(roleArray, "Acetylen");
        case "/propan":
            return containsStart(roleArray, "Propan");
        case "/strom":
            return containsStart(roleArray, "Strom");
        case "/liquidgas":
            return containsStart(roleArray, "LiquidGas");
        case "/example":
            return containsStart(roleArray, "Example");
        case "/vehicles":
            return containsStart(roleArray, "Vehicle");
        case "/fuellingdelivery":
            return containsStart(roleArray, "FuellingDelivery");
        case "/heatingoil":
            return containsStart(roleArray, "HeatingOil");
        case "/plausibility":
            return containsStart(roleArray, "Plausibility");
        case "/keyfigures":
            return containsStart(roleArray, "KeyFigures");
        case "/productionkeyfigures":
            return containsStart(roleArray, "ProductionKeyFigures");
        case "/production":
            return containsStart(roleArray, "Production");
        default:
            return false;
    }
}

export function getFullUserRole(userRoles, roleStart) {
    for (let i = 0; i < userRoles.length; i++) {
        if (userRoles[i].startsWith(roleStart)) {
            return userRoles[i];
        }
    }
    return "";
}

export function getLocationIdsOfUserRole(userRoles, roleStart) {
    let locationIds = [];
    for (let i = 0; i < userRoles.length; i++) {
        if (userRoles[i].startsWith(roleStart)) {
            let parts = userRoles[i].split("_");
            locationIds.push(parts[2]);
        }
    }
    return locationIds;
}

export function isUserRoleEdit(userRoles, roleStart) {
    for (let i = 0; i < userRoles.length; i++) {
        if (userRoles[i].startsWith(roleStart)) {
            return userRoles[i].includes("_edit");
        }
    }
    return false;
}

export function isEdit(userRole) {
    return userRole.endsWith("_edit");
}

