import React from "react";
import {Icon, Table} from "semantic-ui-react";

export default class ProductionKeyFigureRow extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let month = "";
        switch (this.props.month) {
            case 0:
                month = "Januar";
                break;
            case 1:
                month = "Februar";
                break;
            case 2:
                month = "März";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "Mai";
                break;
            case 5:
                month = "Juni";
                break;
            case 6:
                month = "Juli";
                break;
            case 7:
                month = "August";
                break;
            case 8:
                month = "September";
                break;
            case 9:
                month = "Oktober";
                break;
            case 10:
                month = "November";
                break;
            case 11:
                month = "Dezember";
                break;

        }
        return (
            <Table.Row className="color-sub-row">
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell>
                    {month}
                </Table.Cell>
                <Table.Cell>
                    {(this.props.relationSum)}
                </Table.Cell>
                <Table.Cell>
                    {(this.props.relationPreSum)}
                </Table.Cell>
                <Table.Cell/>
            </Table.Row>

        );
    }
}