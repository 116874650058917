import React from "react"
import {Route, Router, Switch} from "react-router-dom";
import {observer} from "mobx-react";
import {Grid} from "semantic-ui-react";

import NavigationBar from "../components/NavigationBar"
import Login from "./Login";
import userStore from "../stores/userStore"

import history from "../helper/browserHistory"
import Redirect from "react-router/es/Redirect";
import DieselPage from "./DieselPage";
import EmptyPage from "./EmptyPage";
import StromPage from "./StromPage";
import VehiclePage from "./VehiclePage";
import {checkRole} from "../helper/rolehelper";
import UserPage from "./UserPage";
import FuellingDeliveryPage from "./FuellingDeliveryPage";
import PlausibilityPage from "./PlausibilityPage";
import KeyFiguresPage from "./KeyFiguresPage";
import LiquidGasPage from "./LiquidGasPage";
import HeatingOilPage from "./HeatingOilPage";
import Production from "./ProductionPage";
import ProductionKeyFiguresPage from "./ProductionKeyFiguresPage";
import LocationPage from "./LocationPage";
import AcetylenPage from "./AcetylenPage";
import PropanPage from "./PropanPage";

// Require scss files
require('../../stylesheets/_all.scss');

@observer
export default class Layout extends React.Component {

    render() {

        const PrivateRoute = ({component: Component, ...rest}) => (
            <Route {...rest} render={(props) => (
                checkRole(userStore.userRolesFromServer, props.location.pathname)
                    ? <Component {...props} />
                    : <Redirect to='/admin-login'/>

            )}/>
        );

        const EmptyRoute = ({component: Component, ...rest}) => (
            <Route {...rest} render={(props) => (
                checkRole(userStore.userRolesFromServer, props.location.pathname)
                    ? <Component {...props} />
                    : <Redirect to='/admin-login'/>

            )}/>

        );

        return (
            <Router history={history}>
                <Grid centered columns={2} className="app-container">
                    <Grid.Row style={{"paddingBottom": "0px"}}>
                        <Grid.Column width={3} className={"background-inverted"}>
                            <NavigationBar/>
                        </Grid.Column>

                        <Grid.Column style={{"paddingRight": "32px", "paddingTop": "32px"}} width={13}>
                            <Switch>
                                <Route exact path="/admin-login" component={Login}/>
                                <PrivateRoute exact path="/diesel" component={DieselPage}/>
                                <PrivateRoute exact path="/strom" component={StromPage}/>
                                <PrivateRoute exact path="/vehicles" component={VehiclePage}/>
                                <PrivateRoute exact path="/user-management" component={UserPage}/>
                                <PrivateRoute exact path="/fuellingdelivery" component={FuellingDeliveryPage}/>
                                <PrivateRoute exact path="/plausibility" component={PlausibilityPage}/>
                                <PrivateRoute exact path="/keyfigures" component={KeyFiguresPage}/>
                                <PrivateRoute exact path="/productionkeyfigures" component={ProductionKeyFiguresPage}/>
                                <PrivateRoute exact path="/heatingoil" component={HeatingOilPage}/>
                                <PrivateRoute exact path="/propan" component={PropanPage}/>
                                <PrivateRoute exact path="/acetylen" component={AcetylenPage}/>
                                <PrivateRoute exact path="/liquidgas" component={LiquidGasPage}/>
                                <PrivateRoute exact path="/production" component={Production}/>
                                <PrivateRoute exact path="/location" component={LocationPage}/>
                                <EmptyRoute exact path="/" component={EmptyPage}/>
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Router>
        );
    }
}


/*

    <Route {...rest} render={props => {

        // check if route is restricted by role
        if (rolehelper.userRoles == "Diesel") {
            // role not authorised so redirect to home page
            return <Redirect to={{ '/diesel'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />

 */