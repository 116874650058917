import React from "react";
import {Dropdown, Form, Grid, Segment, Table, TableHeader} from "semantic-ui-react";
import {observable} from "mobx";
import config from "../../config/main.config";

import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import ProductionKeyFigureRow from "../components/ProductionKeyFigureRow";

@observer
export default class ProductionKeyFiguresPage extends React.Component {

    @observable
    stroms = [];
    @observable
    editStrom = {
        stromType: "",
        date: new Date(),
        amountkWh: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    productions = [];
    @observable
    editProductions = {
        productionType: "",
        date: new Date(),
        amount: "",
        proof: "",
        locationId: "",
        id: 0,
        comment: ""
    };

    @observable
    locations = [];

    @observable
    vehicles = [];


    @observable
    locations = [];

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedValYear: new Date().getFullYear(),
            openVehiclesstate: false
        };
    }

    toggleVehicles() {
        let {openVehiclesstate} = this.state;
        openVehiclesstate = !openVehiclesstate;

        this.setState({
            ...this.state,
            openVehiclesstate: openVehiclesstate
        });
    }

    componentWillMount() {
        fetch(config.BASE_URL + "fuellingdeliveries", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fuellingdeliveries = json;
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
        this.getLocations();
        this.fetchStroms();
        this.fetchProductions();
        this.getVehicles();
    }

    fetchStroms() {
        fetch(config.BASE_URL + "stroms", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.stroms = json
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    fetchProductions() {
        fetch(config.BASE_URL + "productions", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.productions = json
                });

            } else {
                console.log(error);
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    getLocations() {
        fetch(config.BASE_URL + "location", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.locations = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status);
                console.log(error);

            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    getVehicles() {
        fetch(config.BASE_URL + "vehicles/withFuelling", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.vehicles = json.map(function (el) {
                        let o = Object.assign({}, el);
                        o.showingMonths = false;
                        return o;
                    })
                });

            } else {
                console.log(response.status)

                //TODO: Alert?

            }
        }).catch(
            error => {
                console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    handleDateChange(type, event, data) {
        let {currentSelectedValYear, currentSelectedValMonth} = this.state;

        if (type === 'year') {

            if (data.value === "") {
                currentSelectedValYear = new Date().getFullYear();
            } else {
                currentSelectedValYear = data.value;
            }
        }
        if (type === 'month') {
            if (data.value === "") {
                currentSelectedValMonth = new Date().getMonth();
            } else {
                currentSelectedValMonth = data.value;

            }
        }

        this.setState({
            ...this.state,
            currentSelectedValYear: currentSelectedValYear,
            currentSelectedValMonth: currentSelectedValMonth
        }, () => {
        });

    }

    getAllFromStromLocation(location, stroms) {
        let stromLocal = [];
        for (let j = 0; j < this.stroms.length; j++) {
            if (this.stroms[j].locationId === location) {
                stromLocal.push(stroms[j]);
            }
        }
        return stromLocal;
    }

    getAllFromProductionLocation(location, productions) {
        let productionLocal = [];
        for (let j = 0; j < this.productions.length; j++) {
            if (this.productions[j].locationId === location) {
                productionLocal.push(productions[j]);
            }
        }
        return productionLocal;

    }


    checkIfDifferenceIsNaN(currentRelationshipcalCulate, preYearRelationshipcalCulate) {
        let difference;
        if (currentRelationshipcalCulate !== 0) {
            difference = (((currentRelationshipcalCulate - preYearRelationshipcalCulate) / currentRelationshipcalCulate) * 100).toFixed(2);
            if (isNaN(difference) === true) {
                difference = "/";
            }
        } else {
            difference = "/";
        }
        return difference;
    }

    addPercentageSignFunction(differenceRelationshipToPreviousYear) {
        let finaldeviationPercentage;
        if (differenceRelationshipToPreviousYear !== "/") {
            finaldeviationPercentage = differenceRelationshipToPreviousYear + '%';
        } else {
            finaldeviationPercentage = "/";
        }

        return finaldeviationPercentage;
    }

    calculateCurrentRelationship(kwhSum, productionamountSum) {
        let currentRelationshipcalCulate
        if (productionamountSum !== 0) {
            currentRelationshipcalCulate = (kwhSum / productionamountSum).toFixed(2);
            if (isNaN(currentRelationshipcalCulate) === true) {
                currentRelationshipcalCulate = "/";
            }
        } else {
            currentRelationshipcalCulate = "/";
        }

        return currentRelationshipcalCulate;
    }

    getMonthYearStromFromLocation(month, year, allStroms) {
        for (let i = 0; i < allStroms.length; i++) {
            let curDate = new Date(allStroms[i].date * 1000);
            if (curDate.getMonth() === month && curDate.getFullYear() === year) {
                return allStroms[i];
            }
        }


        return {
            stromType: "",
            amountkWh: "",
            proof: "",
            costs: "",
            locationId: "",
            id: 0,
            comment: ""
        };
    }

    getMonthYearProductionFromLocation(month, year, allProductions) {
        for (let i = 0; i < allProductions.length; i++) {
            let curDate = new Date(allProductions[i].date * 1000);
            if (curDate.getMonth() === month && curDate.getFullYear() === year) {
                return allProductions[i];
            }
        }


        return {
            stromType: "",
            amountkWh: "",
            proof: "",
            costs: "",
            locationId: "",
            id: 0,
            comment: ""
        };
    }

    render() {

        const yearOptions = [];
        let yearArray = [];
        for (let i = 0; i < this.stroms.length; i++) {
            let deliveryDate = new Date(this.stroms[i].date * 1000);
            let deliveryYear = deliveryDate.getFullYear();
            if (!yearArray.includes(deliveryYear))
                yearArray.push(deliveryYear);
        }

        for (let i = 0; i < yearArray.length; i++) {
            yearOptions.push({
                key: i,
                text: yearArray[i].toString(),
                value: yearArray[i]
            })
        }
        let locations = this.locations;
        const locationOptions = [];
        for (let i = 0; i < locations.length; i++) {
            locationOptions.push({
                key: i,
                text: locations[i].caompanyname,
                value: locations[i].id,
                id: locations[i].id
            })
        }

        let vehicles = this.vehicles;
        let tableElements = [];


        for (let i = 0; i < locations.length; i++) {

            let kwhSum = 0;
            let kwhSumpreYear = 0;
            let locationStroms = this.getAllFromStromLocation(locations[i].id, this.stroms)
            for (let j = 0; j < locationStroms.length; j++) {

                let stromDate = locationStroms[j].date;
                let stromDateYear = new Date(stromDate * 1000).getFullYear();

                if ((this.state.currentSelectedValYear === stromDateYear)) {

                    kwhSum += locationStroms[j].amountkWh;
                } else if ((this.state.currentSelectedValYear - 1 === stromDateYear)) {

                    kwhSumpreYear += locationStroms[j].amountkWh;
                }
            }

            let productionamountSum = 0;
            let productionamountSumpreYear = 0;

            let locationProductions = this.getAllFromProductionLocation(locations[i].id, this.productions)
            for (let j = 0; j < locationProductions.length; j++) {

                let productionDate = locationProductions[j].date;
                let productionDateYear = new Date(productionDate * 1000).getFullYear();


                if ((this.state.currentSelectedValYear === productionDateYear)) {
                    if (locationProductions[j].productionType === 1 || locationProductions[j].productionType === 2 || locationProductions[j].productionType === 3 || locationProductions[j].productionType === 4) {

                        productionamountSum += locationProductions[j].amount;
                    } else {
                        console.log("error");
                    }

                }
                if (this.state.currentSelectedValYear === productionDateYear + 1) {
                    if (locationProductions[j].productionType === 1 || locationProductions[j].productionType === 2 || locationProductions[j].productionType === 3 || locationProductions[j].productionType === 4) {

                        productionamountSumpreYear += locationProductions[j].amount;
                    } else {
                        console.log("error");
                    }
                }
            }
            let currentRelationshipcalCulate = this.calculateCurrentRelationship(kwhSum, productionamountSum);
            let preYearRelationshipcalCulate = this.calculateCurrentRelationship(kwhSumpreYear, productionamountSumpreYear);
            let differenceRelationshipToPreviousYear = this.checkIfDifferenceIsNaN(currentRelationshipcalCulate, preYearRelationshipcalCulate);
            let differenceKwHToPreviousYearPercentage = this.addPercentageSignFunction(differenceRelationshipToPreviousYear);

            tableElements.push(
                <Table.Row key={i}>
                    <Table.Cell>
                        <Icon name={this.locations[i].showingMonths ? 'triangle up' : 'triangle down'}
                              onClick={() => this.locations[i].showingMonths = !this.locations[i].showingMonths}/>
                    </Table.Cell>
                    <Table.Cell>
                        {locations[i].caompanyname}
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell/>
                    <Table.Cell>
                        {(currentRelationshipcalCulate)}
                    </Table.Cell>
                    <Table.Cell>
                        {preYearRelationshipcalCulate}
                    </Table.Cell>
                    <Table.Cell>
                        {differenceKwHToPreviousYearPercentage}
                    </Table.Cell>

                </Table.Row>
            );
            let locationMonthlyFigures = [];
            for (let j = 0; j < 12; j++) {
                let monthStrom = this.getMonthYearStromFromLocation(j, this.state.currentSelectedValYear, locationStroms);
                let monthProductionLocation = this.getMonthYearProductionFromLocation(j, this.state.currentSelectedValYear, locationProductions);
                let currentMonthRelationshipcalCulate = this.calculateCurrentRelationship(monthStrom.amountkWh, monthProductionLocation.amount);
                let monthPreYearStrom = this.getMonthYearStromFromLocation(j, this.state.currentSelectedValYear - 1, locationStroms);
                let monthPreYearLocation = this.getMonthYearProductionFromLocation(j, this.state.currentSelectedValYear - 1, locationProductions);
                let preYearMonthRelationshipcalCulate = this.calculateCurrentRelationship(monthPreYearStrom.amountkWh, monthPreYearLocation.amount);

                locationMonthlyFigures.push(<ProductionKeyFigureRow key={i + "_" + j}
                                                                    relationSum={currentMonthRelationshipcalCulate}
                                                                    relationPreSum={preYearMonthRelationshipcalCulate}
                                                                    month={j}
                />);
            }
            if (locations[i].showingMonths) {
                tableElements.push(locationMonthlyFigures);
            }
        }
        for (let i = 0; i < this.stroms.length; i++) {
            let stromDate = this.stroms[i].date;
            let stromDateYear = new Date(stromDate * 1000).getFullYear();
            let temp = 0;
            if ((this.state.currentSelectedValYear === stromDateYear)) {
                for (let j = 0; j < this.locations.length; j++) {
                    if (this.stroms[i].locationId === j) {
                        temp += this.stroms[i].amountkWh;
                    }
                }
            }
        }

        for (let i = 0; i < this.productions.length; i++) {
            let productionDate = this.productions[i].date;
            let productionDateYear = new Date(productionDate * 1000).getFullYear();
            let temp = 0;
            if ((this.state.currentSelectedValYear === productionDateYear)) {
                for (let j = 0; j < this.locations.length; j++) {
                    if (this.productions[i].locationId === j) {
                        temp += this.productions[i].amount;
                    }
                }
            }
        }

        return (

            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Produktions-Kennzahlen</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form>
                                            <Form.Field>
                                                <label>Jahr</label>
                                                <Dropdown selection placeholder="2019" direction='right' color='black'
                                                          options={yearOptions}
                                                          value={this.state.currentSelectedValYear}
                                                          onChange={this.handleDateChange.bind(this, 'year')}/>
                                            </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Table celled={true}>
                            <TableHeader>
                                <Table.Row>

                                    <Table.HeaderCell/>
                                    <Table.HeaderCell textAlign={'center'} colSpan={4}>Ort</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}
                                                      colSpan={3}>KwH-Entwicklung </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Einsatzort</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>KwH-Summe/Tonnen-Summe </Table.HeaderCell>
                                    <Table.HeaderCell>KwH-Summe/Tonnen-Summe Vorjahr</Table.HeaderCell>
                                    <Table.HeaderCell>Entwicklung zum Vorjahr %</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                {tableElements}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
