import React from "react";
import {Button, Dropdown, Form, Message, Modal} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import DatePicker from "react-datepicker/es";

import dieselStore from "../stores/dieselStore";
import "react-datepicker/dist/react-datepicker.css";
import {getDateWithoutTime} from "../helper/util";
import PropTypes from "prop-types";

const DEFAULT_ERROR = 'Werte dürfen nicht 0 sein.';
const REFERENCE_ERROR = "Der neue KM/BH Stand muss höher sein als der Letzte";
const LOCATION_ERROR = "Ort wurde nicht definiert";
const REFERENCE_2_ERROR = "Der neue KM/BH Stand muss niedriger sein als der danach";
const REFERENCE_INITIAL_ERROR = "Der neue KM/BH Stand muss niedriger sein als der Initialwert";
const DATE_ERROR = "Das Datum der neuen Tankung wurde bereits verwendet!";

export default class AddEditDieselFuellingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tmpEditValType: 1,
            tmpEditValDesignation: "",
            tmpEditValAmountltr: 0,
            tmpEditValReference: "",
            tmpEditValAmountreference: 0,
            tmpVehicleId: 0,
            tmpEditValdate: new Date(),
            tempEditIndex: 0,
            addModalOpen: false,
            open: false,
            error: false,
            errorMessage: DEFAULT_ERROR,
            edit: false,
            locations: [],
            tmpEditValLocation: 0,
        }
    }

    componentDidMount() {
        if (this.props.hasOwnProperty("fuellingIndex")) {
            let fuelling = this.props.vehicle.fuellings[this.props.fuellingIndex];
            this.setState({
                ...this.state,
                edit: true,
                tmpEditValType: 1,
                tempEditIndex: this.props.fuellingIndex,
                tmpEditValDesignation: fuelling.designation,
                tmpEditValAmountltr: fuelling.amountltr,
                tmpEditValAmountreference: fuelling.amountreference,
                tmpEditValLocation: fuelling.location,
                tmpEditValdate: new Date(fuelling.date * 1000),
                locations: this.props.locations.toJS(),
            });
        }


    }

    handleInputChange(type, event, data) {
        let {tmpEditValAmountltr, tmpEditValAmountreference, tmpEditValReference, tmpEditValLocation} = this.state;

        let floatValues = /[0-9]+\.?[0-9]{0,2}/;
        if (type === 'amountltr') {
            if (data.value === "") {
                tmpEditValAmountltr = 0;
            } else {
                let filteredValue = data.value.match(floatValues)[0];
                if (!filteredValue.endsWith(".")) {
                    filteredValue = parseFloat(filteredValue);
                }
                tmpEditValAmountltr = filteredValue;
            }
        }
        if (type === 'amountreference') {

            if (data.value === "") {
                tmpEditValAmountreference = 0;
            } else {
                let filteredValue = data.value.match(floatValues)[0];
                if (!filteredValue.endsWith(".")) {
                    filteredValue = parseFloat(filteredValue);
                }
                tmpEditValAmountreference = filteredValue;
            }
        }
        if (type === 'reference') {

            if (data.value === "") {
                tmpEditValReference = 0;
            } else {
                tmpEditValReference = data.value;
            }
        }
        if (type === 'location') {

            if (data.value === "") {
                tmpEditValLocation = 0;
            } else {
                tmpEditValLocation = data.value;
            }
        }


        this.setState({
            ...this.state,
            tmpEditValAmountltr: tmpEditValAmountltr,
            tmpEditValReference: tmpEditValReference,
            tmpEditValAmountreference: tmpEditValAmountreference,
            tmpEditValLocation: tmpEditValLocation
        })
    }

    closeInput() {
        if (!this.state.edit) {
            this.setState({
                ...this.state,
                tmpEditValType: 1,
                tmpEditValAmountltr: 0,
                tmpEditValReference: "",
                tmpEditValAmountreference: 0,
                tmpEditValLocation: "",
                open: false,
                error: false
            })
        } else {
            let fuelling = this.props.vehicle.fuellings[this.props.fuellingIndex];
            this.setState({
                ...this.state,
                tmpEditValType: 1,
                tempEditIndex: this.props.fuellingIndex,
                tmpEditValDesignation: fuelling.designation,
                tmpEditValAmountltr: fuelling.amountltr,
                tmpEditValAmountreference: fuelling.amountreference,
                tmpEditValLocation: fuelling.location,
                tmpEditValdate: new Date(fuelling.date * 1000),
                locations: this.props.locations.toJS(),
                tmpVehicleId: 0,
                addModalOpen: false,
                errorMessage: DEFAULT_ERROR,
                open: false,
                error: false
            })
        }
    }

    checkAdd() {
        let {
            tmpEditValAmountltr,
            tmpEditValAmountreference,
            tmpEditValdate,
            tmpEditValLocation,
            locations
        } = this.state;
        let vehicle = this.props.vehicle;


        let allowed = false;
        //Check if vehicle has fuelling and check if amounts are right
        if (this.props.vehicle.fuellings.length === 0) {
            if (tmpEditValAmountreference <= this.props.vehicle.reference_stand) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: REFERENCE_INITIAL_ERROR
                });
                return;
            } else {
                allowed = true;
            }
        } else if (getDateWithoutTime(tmpEditValdate) < getDateWithoutTime(new Date(this.props.vehicle.fuellings[this.props.vehicle.fuellings.length - 1].date * 1000))) {
            if (this.props.vehicle.fuellings[this.props.vehicle.fuellings.length - 1].amountreference <= tmpEditValAmountreference) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: REFERENCE_ERROR
                });
                return;
            } else {
                allowed = true;
            }
        } else if (getDateWithoutTime(tmpEditValdate) > getDateWithoutTime(new Date(this.props.vehicle.fuellings[0].date * 1000))) {
            if (this.props.vehicle.fuellings[0].amountreference >= tmpEditValAmountreference) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: REFERENCE_ERROR
                });
                return;
            } else {
                allowed = true;
            }
        } else {
            for (let i = 1; i < this.props.vehicle.fuellings.length; i++) {
                let fuellingBeforeDate = new Date(this.props.vehicle.fuellings[i - 1].date * 1000);
                let isNewerThanBefore = false;

                //current fuelling date
                let currentFuellingDate = new Date(this.props.vehicle.fuellings[i].date * 1000);
                let isOlderThanCurrent = false;

                if (getDateWithoutTime(currentFuellingDate) < getDateWithoutTime(tmpEditValdate)) {
                    isNewerThanBefore = true;
                }

                if (getDateWithoutTime(fuellingBeforeDate) > getDateWithoutTime(tmpEditValdate)) {
                    isOlderThanCurrent = true;
                }

                if (isNewerThanBefore && isOlderThanCurrent) {
                    if (this.props.vehicle.fuellings[i - 1].amountreference <= tmpEditValAmountreference || this.props.vehicle.fuellings[i].amountreference >= tmpEditValAmountreference) {
                        this.setState({
                            ...this.state,
                            error: true,
                            errorMessage: REFERENCE_ERROR
                        });
                        allowed = false;
                        return;
                    } else {
                        allowed = true;
                        break;
                    }
                }
                if (tmpEditValLocation ===  "" || tmpEditValLocation === null) {
                    this.setState({
                        ...this.state,
                        error: true,
                        errorMessage: LOCATION_ERROR
                    });
                    return;
                }
            }
        }

        if (!allowed) {
            console.log(allowed);
            this.setState({
                ...this.state,
                error: true,
                errorMessage: DATE_ERROR
            });
            return;
        }
        locations = this.props.locations;
        let locId = 1;
        for (let i = 0; i < locations.length; i++) {
            if (tmpEditValLocation === locations[i].caompanyname) {
                locId = locations[i].id;
                break;
            }
        }

        let fuelling = {
            amountltr: tmpEditValAmountltr,
            amountreference: tmpEditValAmountreference,
            vehicleId: vehicle.id,
            date: tmpEditValdate,
            location: locId

        };

        if (fuelling.amountltr !== 0 && fuelling.amountreference !== 0) {
            this.createFuelling(fuelling);
            this.setState({
                ...this.state,
                tmpEditValType: 1,
                tmpEditValDesignation: "",
                tmpEditValAmountltr: 0,
                tmpEditValReference: 0,
                tmpEditValLocation: "",
                tmpEditValAmountreference: 0,
                tmpEditValdate: new Date(),
                tempEditIndex: 0,
                open: false,
                errorMessage: DEFAULT_ERROR,
                error: false
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: DEFAULT_ERROR
            })
        }
    }

    checkEdit() {
        //When editing the amount, amount must be higher as the last fuelling (when existing)
        //but smaller as the one after that (when existing)
        let fuellingBefore = null;
        let fuellingAfter = null;
        let bFuellingDate= null;
        let aFuellingDate = null;
        let {tmpEditValAmountltr, tmpEditValAmountreference, tmpEditValdate, tmpEditValLocation, locations} = this.state;

        if (this.props.vehicle.fuellings[this.props.fuellingIndex + 1]) {
            fuellingBefore = this.props.vehicle.fuellings[this.props.fuellingIndex + 1];
             bFuellingDate = new Date(this.props.vehicle.fuellings[this.props.fuellingIndex + 1].date * 1000);
            if (getDateWithoutTime(tmpEditValdate) <= getDateWithoutTime(bFuellingDate)) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: DATE_ERROR
                });
                return;
            }
        }
        if (this.props.vehicle.fuellings[this.props.fuellingIndex - 1]) {
            fuellingAfter = this.props.vehicle.fuellings[this.props.fuellingIndex - 1]
             aFuellingDate = new Date(this.props.vehicle.fuellings[this.props.fuellingIndex - 1].date * 1000);
            if (getDateWithoutTime(tmpEditValdate) >= getDateWithoutTime(aFuellingDate)) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: DATE_ERROR
                });
                return;
            }
        }
/*

        for (let i = 0; i < this.props.vehicle.fuellings.length; i++) {
            let vFuellingDate = new Date(this.props.vehicle.fuellings[this.props.fuellingIndex].date * 1000);
            if (vFuellingDate.getTime() <= tmpEditValdate.getTime()) {
                //if there is currently no fuelling before or
                // if the current before date is older as the current but before the edit fuelling
                if (fuellingBefore == null || new Date(fuellingBefore.date * 1000).getTime() < vFuellingDate.getTime()) {
                    fuellingBefore = this.props.vehicle.fuellings[i + 1 +1 +1];
                }
            }

            if (vFuellingDate.getTime() >= tmpEditValdate.getTime()) {
                //if there is currently no fuelling after or
                // if the current before date is newer as the current but before the edit fuelling
                if (fuellingAfter == null || new Date(fuellingAfter.date * 1000).getTime() > vFuellingDate.getTime()) {
                    fuellingAfter = this.props.vehicle.fuellings[i];

                }
            }
        }
        */
        //If there is no fuelling before the reference amount must be higher as the initial amount
        if (fuellingBefore == null) {
            if (tmpEditValAmountreference <= this.props.vehicle.reference_stand) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: REFERENCE_ERROR
                });

                return;
            }
        } else {
            if (tmpEditValAmountreference <= fuellingBefore.amountreference) {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMessage: REFERENCE_ERROR
                });
                return;
            }
        }

        //if there is a fuelling after, the current fuelling must be below that
        if (fuellingAfter != null && tmpEditValAmountreference >= fuellingAfter.amountreference) {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: REFERENCE_2_ERROR
            });
            return;
        }
        if (tmpEditValLocation ===  "" || tmpEditValLocation === null) {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: LOCATION_ERROR
            });
            return;
        }


        let locId = 1;
        for (let i = 0; i < locations.length; i++) {
            if (tmpEditValLocation === locations[i].caompanyname) {
                locId = locations[i].id;
                break;
            }
        }
        let fuelling = {
            amountltr: tmpEditValAmountltr,
            amountreference: tmpEditValAmountreference,
            vehicleId: this.props.vehicle.id,
            id: this.props.vehicle.fuellings[this.props.fuellingIndex].id,
            location: locId,
            date: tmpEditValdate
        };

        if (fuelling.amountltr !== 0 && fuelling.amountreference !== 0) {
            this.editFuelling(fuelling);
            this.setState({
                ...this.state,
                open: false,
                errorMessage: DEFAULT_ERROR,
                error: false
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: DEFAULT_ERROR
            })
        }

    }

    createFuelling(fuelling) {
        dieselStore.registerNewDieselfuellings(fuelling);
    }

    editFuelling(fuelling) {
        dieselStore.editDieselfuellings(fuelling);
    }

    handleChange(date) {
        if (date === null) {
            this.setState({
                ...this.state,
                error: true
            })
        } else {
            this.setState({
                ...this.state,
                tmpEditValdate: date
            })
        }
    }

    render() {
        const locationOptions = [];
        for (let i = 0; i < this.props.locations.length; i++) {
            locationOptions.push({
                key: i,
                text: this.props.locations[i].caompanyname,
                value: this.props.locations[i].caompanyname,
                id: this.props.locations[i].id
            })
        }
        return (
            <Modal centered={false} className={"modal-top"} open={this.state.open} size="small"
                   trigger={this.state.edit ?
                       <Icon name='edit' color="green" onClick={() => this.setState({open: true})}/>
                       :
                       <Icon name='plus' color="orange" onClick={() => this.setState({open: true})}/>
                   }>

                <Modal.Header>{this.state.edit ? "Tankung bearbeiten" : "Neue Tankung"}</Modal.Header>
                <Modal.Content>
                    <Form error={this.state.error}>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{this.props.vehicle.reference.toUpperCase()}-Stand</label>
                                <Form.Input fluid value={this.state.tmpEditValAmountreference}
                                            onChange={this.handleInputChange.bind(this, 'amountreference')}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Liter</label>
                                <Form.Input fluid value={this.state.tmpEditValAmountltr}
                                            onChange={this.handleInputChange.bind(this, 'amountltr')}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Datum</label>
                            <DatePicker
                                selected={this.state.tmpEditValdate}
                                onChange={this.handleChange.bind(this)}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>
                        {this.props.vehicle.variable ?
                            <Form.Field>
                                <label>Ort</label>
                                <Dropdown selection placeholder="Ort" direction='right' color='black'
                                          options={locationOptions}
                                          value={this.state.tmpEditValLocation}
                                          onChange={this.handleInputChange.bind(this, 'location')}/>
                            </Form.Field>
                            :
                            null
                        }
                        <Message
                            error
                            header='Fehler'
                            content={this.state.errorMessage}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative icon='delete' onClick={this.closeInput.bind(this)}/>
                    <Button
                        positive
                        icon='checkmark'
                        onClick={this.state.edit ?
                            this.checkEdit.bind(this)
                            :
                            this.checkAdd.bind(this)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}